import { UserName, mapUserNameToString } from './user-name'

type UserDisplayNameProps = UserName & {
  short?: boolean
}

export default function UserDisplayName(props: UserDisplayNameProps) {
  const { short, ...userName } = props

  return <>{mapUserNameToString(userName, short)}</>
}
