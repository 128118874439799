import { LoaderFunction, Params, useLoaderData } from 'react-router-dom'
import { gql } from 'graphql-generated'
import { gqlRequest } from 'api'
import { Browse_EntriesByTagsGroupQuery } from 'graphql-generated/graphql'
import { getEntryFilter, getEntrySorting } from '../common'

const ENTRIES_BY_TAGS_GROUP_QUERY = gql(`
  query Browse_EntriesByTagsGroup($groupId: Int!, $orderBy: polls_entity_order_by!, $filter: polls_entity_bool_exp!) {
    ...EntriesByTagsGroup_Tags
    polls_entity(
      order_by: [$orderBy]
      where: {
        _and: [
          { tags: { tag: { _and: [{ tag_group: { id: { _eq: $groupId } } }, { level: { _eq: 2 } }] } } }
          { archived: { _eq: false } }
          { progress: { published: { _gt: 0 } } }
          { region: { geom: { _is_null: false } } }
          {
            base_answer_sets: {
              _and: [
                { region: { geom: { _is_null: false } } }
                { answer_sets: { published: { _eq: true } } }
              ]
            }
          }
          $filter
        ]
      }
    ) {
      id
      ...EntriesByTagsGroup_TagEntry
      tags(
        where: {
          tag: {
            _and: [
              { level: { _eq: 2 } }
              { tag_group: { id: { _eq: $groupId } } }
            ]
          }
        }
      ) {
        tag {
          id
          name
          level
        }
      }
      name {
        name
      }
    }
  }
`)

function getGroupId(params: Params) {
  const groupId = Number(params.groupId)

  if (Number.isFinite(groupId)) {
    return groupId
  }

  throw new Error('GroupId is invalid')
}

export const entriesByTagsGroupLoader: LoaderFunction = async ({
  params,
  request,
}) => {
  const url = new URL(request.url)

  const groupId = getGroupId(params)
  const orderBy = getEntrySorting(url)
  const filter = getEntryFilter(url)

  return gqlRequest(ENTRIES_BY_TAGS_GROUP_QUERY, {
    groupId,
    orderBy,
    filter,
  })
}

export function useEntriesByTagsGroup(): Browse_EntriesByTagsGroupQuery {
  return useLoaderData() as Browse_EntriesByTagsGroupQuery
}
