import { Link } from 'react-router-dom'

import { GetText } from 'lib/gettext'
import { CONTACT_EMAIL } from 'config'

import FOOTER_LOGO from './footer-logo.svg'

export default function Footer() {
  return (
    <footer className="bg-black/5 text-gray-drh shadow-[inset_0_1px_0_0] shadow-black/10">
      <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-4 py-7 px-8 text-center md:grid-cols-3 md:text-left lg:grid-cols-4 lg:gap-x-4">
        <Link className="justify-self-center md:justify-self-start" to="/">
          <img src={FOOTER_LOGO} alt="The Database of Religious History" />
        </Link>

        <div className="md:col-span-2">
          <GetText
            components={{
              link: <Link className="link" to="/landing/terms-of-use" />,
            }}
          >
            You are free to use the data for non-profit purposes as long as it
            is properly cited and not redistributed. Please see our [link]Terms
            of Use[/link].
          </GetText>
        </div>

        <div className="md:col-span-2 md:col-start-2 lg:col-span-1 lg:col-start-auto">
          <GetText
            components={{
              contactEmail: (
                <a className="link" href={`mailto:${CONTACT_EMAIL}`}>
                  {CONTACT_EMAIL}
                </a>
              ),
            }}
          >
            For any questions contact [contactEmail/]
          </GetText>
        </div>

        <div className="md:col-span-2 md:col-start-2">
          <GetText components={{ year: <>{new Date().getFullYear()}</> }}>
            © [year/] Database of Religious History. The University of British
            Columbia.
          </GetText>
        </div>
      </div>
    </footer>
  )
}
