import { defer } from 'react-router-dom'
import axios from 'axios'

import { API_URL } from 'config'

type ResponseData = {
  user: {
    pk: number
    username: string
    first_name: string
    last_name: string
    profile: {
      institution: string
      department: string
      interests: string[]
    }
  }
}[]

type Expert = {
  id: number
  username: string
  firstName: string
  lastName: string
  department: string | undefined
  institution: string | undefined
  interests: ReadonlyArray<string>
}

type Experts = Array<Expert>

function makeExpertsFromData(data: ResponseData): Experts {
  return data.map(
    ({
      user: {
        pk,
        username,
        first_name,
        last_name,
        profile: { department, institution, interests },
      },
    }) => ({
      id: pk,
      username,
      firstName: first_name,
      lastName: last_name,
      department: department || undefined,
      institution: institution || undefined,
      interests: interests || [],
    })
  )
}

export type AlphabeticalIndex = Map<string, Experts>

function makeAlphabeticalIndexFromExperts(experts: Experts): AlphabeticalIndex {
  return experts
    .filter(({ firstName, lastName }) => firstName.length && lastName.length)
    .sort((a, b) => {
      const nameA = a.lastName.toLowerCase()
      const nameB = b.lastName.toLowerCase()

      if (nameA < nameB) {
        return -1
      }

      if (nameA > nameB) {
        return 1
      }

      return 0
    })
    .reduce((acc, expert) => {
      const letter = Array.from(expert.lastName.toLowerCase())[0]

      acc.set(letter, [...(acc.get(letter) || []), expert])

      return acc
    }, new Map() as AlphabeticalIndex)
}

function makeAlphabeticalIndexFromData(data: ResponseData): AlphabeticalIndex {
  const experts = makeExpertsFromData(data)

  return makeAlphabeticalIndexFromExperts(experts)
}

async function getAlphabeticalIndex() {
  const { data } = await axios.get<ResponseData>(
    API_URL + '/api/accounts/expert?approved=True'
  )

  return makeAlphabeticalIndexFromData(data)
}

export default function expertsLoader() {
  return defer({ index: getAlphabeticalIndex() })
}
