import classNames from 'classnames'

import { gettext_noop } from 'lib/gettext'

export const POLL_TYPES = [
  'religious_group',
  'religious_place',
  'religious_text',
  'external',
] as const

export type PollType = (typeof POLL_TYPES)[number]

export const POLL_TYPE_LABELS: { [type in PollType]: string } = {
  religious_group: gettext_noop('Religious Group'),
  religious_place: gettext_noop('Religious Place'),
  religious_text: gettext_noop('Religious Text'),
  external: gettext_noop('External'),
}

export const POLL_TYPE_DESCRIPTIONS: {
  [type in PollType]?: string
} = {
  religious_group: gettext_noop(
    'A collection of people who share common practices, beliefs, or institutions, locatable in a given space and time, as identified by an expert. A religious group does not need to be formally recognized and can represent a community of a wide variety of sizes. The group can be defined by either emic (indigenous) or etic (scholarly attributed) names and categories.'
  ),
  religious_place: gettext_noop(
    'A physical place relevant to religious behaviors, ritual activities, or beliefs. A religious place can be identified as affiliated with a single religious group or multiple religious groups. The significance of a religious place can also change in significance and use over time. Examples include temples, shrines, ritual sites, graves, features of the natural world (such as mountains, streams, or rivers) and monuments.'
  ),
  religious_text: gettext_noop(
    'A typically written, but also possibly inscribed or visual object that includes depiction of elements of religious beliefs, behavior, traditions, or other matters. A religious text can be created, transmitted, recovered, or excavated. A religious text might be used by a small group or a very large group of individuals, including by a multiplicity of religious groups. The methods of creating and interpreting a religious text, along with the ascribed significance and use can change over time.'
  ),
  external: undefined,
} as const

type PollTypeIconProps = {
  type: PollType
  className?: string
}

export const PollTypeIcon = ({ type, className }: PollTypeIconProps) => {
  const iconClassName = classNames('h-5 aspect-square', className)

  switch (type) {
    case 'religious_group':
      return <GroupIcon className={iconClassName} />
    case 'religious_place':
      return <PlaceIcon className={iconClassName} />
    case 'religious_text':
      return <TextIcon className={iconClassName} />
    case 'external':
      return <ExternalIcon className={iconClassName} />
  }
}

const GroupIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.992 15.938A2.25 2.25 0 0 1 1.5 15.75v-1.387C.586 13.69 0 12.658 0 11.5c0-2.025 1.79-3.667 4-3.667.747 0 1.446.188 2.044.514a5.44 5.44 0 0 0-.695.768A3.282 3.282 0 0 0 4 8.833c-1.74 0-3 1.274-3 2.667 0 .806.406 1.552 1.092 2.057l.408.3v1.893a1.25 1.25 0 1 0 2.5 0v-1.071c.265.467.601.89.992 1.259ZM15 14.679c-.265.467-.601.89-.992 1.259a2.25 2.25 0 0 0 4.492-.188v-1.387c.915-.672 1.5-1.705 1.5-2.863 0-2.025-1.79-3.667-4-3.667-.747 0-1.446.188-2.044.514.256.234.49.491.695.768A3.286 3.286 0 0 1 16 8.833c1.74 0 3 1.274 3 2.667 0 .806-.406 1.552-1.092 2.057l-.408.3v1.893a1.25 1.25 0 0 1-2.5 0v-1.071Z" />
    <path
      fillRule="evenodd"
      d="m12 14.998.385-.3c.8-.624 1.282-1.536 1.282-2.531 0-1.773-1.57-3.334-3.667-3.334-2.096 0-3.667 1.561-3.667 3.334 0 .995.482 1.907 1.282 2.53l.385.3V16a2 2 0 1 0 4 0v-1.002Zm1 .488V16a3 3 0 1 1-6 0v-.514c-1.019-.795-1.667-1.987-1.667-3.32 0-2.393 2.09-4.333 4.667-4.333s4.667 1.94 4.667 4.334c0 1.332-.648 2.524-1.667 3.319ZM10 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.75 6a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Zm12.5-1a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
    />
  </svg>
)

const PlaceIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M.5 5c0-2.23 1.888-4 4.167-4h10.666c2.28 0 4.167 1.77 4.167 4 0 1.079-.366 2.083-1.07 2.82-.702.735-1.709 1.173-2.93 1.18v7c0 .057-.01.112-.027.164A2.4 2.4 0 0 1 17 18.4a.6.6 0 0 1-.6.6H3.6a.6.6 0 0 1-.6-.6 2.4 2.4 0 0 1 1.527-2.236A.5.5 0 0 1 4.5 16V9c-1.221-.007-2.228-.445-2.93-1.18C.866 7.083.5 6.08.5 5Zm5 11H7V9.5a.5.5 0 0 1 1 0V16h1.5V8.25a.5.5 0 0 1 1 0V16H12V9.5a.5.5 0 0 1 1 0V16h1.5V9c0-.048.007-.094.02-.137a3.567 3.567 0 0 1-1.384-.774A3.228 3.228 0 0 1 12.34 7H7.66c-.18.413-.46.785-.796 1.09a3.58 3.58 0 0 1-1.383.773A.5.5 0 0 1 5.5 9v7Zm7 1H5.4a1.4 1.4 0 0 0-1.342 1h11.884a1.4 1.4 0 0 0-1.342-1h-2.1Zm-.386-11.052V6H7.886v-.052c0-.681-.228-1.267-.608-1.718h5.446a2.625 2.625 0 0 0-.609 1.718ZM5 4.23c-.016 0-.032 0-.047-.002-.926.02-1.336.61-1.336 1.079 0 .185.089.389.252.552a.787.787 0 0 0 .533.249c.287 0 .49-.163.598-.38a.5.5 0 1 1 .897.441 1.648 1.648 0 0 1-1.495.939c-.481 0-.924-.226-1.24-.542-.316-.316-.545-.763-.545-1.26 0-1.104.95-2.079 2.383-2.079l.135.003h9.73L15 3.227c1.433 0 2.383.975 2.383 2.08 0 .496-.229.943-.545 1.26-.316.315-.759.54-1.24.54-.733 0-1.25-.441-1.495-.938A.5.5 0 1 1 15 5.728c.107.217.31.38.598.38a.787.787 0 0 0 .533-.249.806.806 0 0 0 .252-.552c0-.47-.41-1.06-1.336-1.08A.505.505 0 0 1 15 4.23h-.116c-1.042.049-1.77.756-1.77 1.718 0 .49.25 1 .693 1.4A2.51 2.51 0 0 0 15.469 8c.989 0 1.736-.345 2.238-.87.505-.53.793-1.276.793-2.13 0-1.635-1.396-3-3.167-3H4.667C2.896 2 1.5 3.365 1.5 5c0 .854.288 1.6.793 2.13.502.525 1.25.87 2.238.87a2.51 2.51 0 0 0 1.662-.653c.442-.4.693-.91.693-1.399 0-.962-.728-1.67-1.77-1.718H5Z"
    />
  </svg>
)

const TextIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="m3.673.172-.202.98A1.225 1.225 0 0 0 2 2.351l-.001 15.99a.66.66 0 0 0 .872.623l1.282-.44.325.945-1.282.44A1.658 1.658 0 0 1 1 18.343V2.35A2.225 2.225 0 0 1 3.673.17Zm3.501.445c-.419.096-.853.1-1.274.014L3.673.17l-.202.98 2.227.46c.561.115 1.14.109 1.7-.02l1.945-.445c.235-.054.479-.065.718-.032l3.942.542c.62.085 1.251.024 1.843-.18l1.282-.44a.658.658 0 0 1 .872.623v15.99a1.225 1.225 0 0 1-1.47 1.2l-2.228-.458a3.997 3.997 0 0 0-1.7.018l-1.945.446a1.997 1.997 0 0 1-.718.032l-3.942-.542a3.998 3.998 0 0 0-1.843.18l.325.945a2.999 2.999 0 0 1 1.382-.134l3.942.541a3 3 0 0 0 1.078-.047l1.945-.446c.419-.096.854-.1 1.274-.014l2.227.46A2.224 2.224 0 0 0 19 17.65V1.66A1.66 1.66 0 0 0 16.803.09l-1.282.44a2.999 2.999 0 0 1-1.382.135L10.197.124A2.998 2.998 0 0 0 9.119.17L7.174.617Z"
    />
    <path d="m5.86 12.225 1.415 1.414s.703-.88 1.898-2.253c.046 1.187.077 2.492.077 3.864h1c0-1.458.034-2.84.085-4.087.917.845 1.92 1.798 2.95 2.83l.708-.707a100.624 100.624 0 0 1-2.83-2.95 98.543 98.543 0 0 1 4.087-.086v-1c-1.459 0-2.841-.035-4.087-.085a98.077 98.077 0 0 1 2.83-2.95l-.708-.708c-.97.97-1.914 1.872-2.786 2.679.125-1.816.25-2.936.25-2.936h-2s.113 1 .231 2.642C7.902 6.647 7.275 5.86 7.275 5.86L5.861 7.275s.786.628 2.031 1.705a73.466 73.466 0 0 1-2.642-.23v2s1-.112 2.642-.23c-1.245 1.077-2.031 1.705-2.031 1.705Z" />
  </svg>
)

const ExternalIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M5.5 19h9a3 3 0 0 0 3-3V5.075L13.503 1H5.5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3Zm8.423-19H5.5a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h9a4 4 0 0 0 4-4V4.667L13.923 0Z"
    />
    <path d="M10.5 7.5h-3a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-3h-1v3a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h3v-1Zm2 0h2v3h1v-4h-4v1h1Z" />
    <path d="M15.354 6.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0Z" />
    <circle cx="6.167" cy="3.997" r=".667" />
    <path d="M7.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H8a.5.5 0 0 1-.5-.5Z" />
  </svg>
)
