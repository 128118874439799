import EntryDetailsItem from './entry-details-item'
import EntryYear from './entry-year'

import periodIcon from './assets/period.svg'

type Props = {
  from: number | null | undefined
  to: number | null | undefined
}

export default function EntryPeriodBox({ from, to }: Props) {
  return (
    <EntryDetailsItem icon={periodIcon}>
      <EntryYear value={from} />
      &nbsp;-&nbsp;
      <EntryYear value={to} />
    </EntryDetailsItem>
  )
}
