import { gqlRequest } from 'api'
import { gql } from 'graphql-generated'
import { Browse_ExpertsWithEntriesQuery } from 'graphql-generated/graphql'
import { useLoaderData } from 'react-router-dom'

const EXPERTS_WITH_ENTRIES_QUERY = gql(`
  query Browse_ExpertsWithEntries {
    accounts_expert(
      order_by: { user: { last_name: asc } }
      where: {
        _and: [
          { entry_summary: { related_entries_count: { _gt: 0 } } }
          {
            _or: [
              {
                user: {
                  entries: {
                    _and: [
                      { archived: { _eq: false } }
                      { progress: { published: { _gt: 0 } } }
                      { region: { geom: { _is_null: false } } }
                      {
                        base_answer_sets: {
                          _and: [
                            { region: { geom: { _is_null: false } } }
                            { answer_sets: { published: { _eq: true } } }
                          ]
                        }
                      }
                    ]
                  }
                }
              }
              {
                entries_sourced: {
                  _and: [
                    { archived: { _eq: false } }
                    { progress: { published: { _gt: 0 } } }
                    { region: { geom: { _is_null: false } } }
                    {
                      base_answer_sets: {
                        _and: [
                          { region: { geom: { _is_null: false } } }
                          { answer_sets: { published: { _eq: true } } }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ) {
      entries_sourced_aggregate(
        where: {
          _and: [
            { archived: { _eq: false } }
            { progress: { published: { _gt: 0 } } }
            { region: { geom: { _is_null: false } } }
            {
              base_answer_sets: {
                _and: [
                  { region: { geom: { _is_null: false } } }
                  { answer_sets: { published: { _eq: true } } }
                ]
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      user {
        entries_aggregate(
          where: {
            _and: [
              { archived: { _eq: false } }
              { entry_source: { _neq: 2 } }
              { progress: { published: { _gt: 0 } } }
              { region: { geom: { _is_null: false } } }
              {
                base_answer_sets: {
                  _and: [
                    { region: { geom: { _is_null: false } } }
                    { answer_sets: { published: { _eq: true } } }
                  ]
                }
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        username
        last_name
        ...EntriesByAuthors_Author
      }
    }
  }
`)

type AuthorGroups = {
  [key: string]: Browse_ExpertsWithEntriesQuery['accounts_expert']
}

export async function entriesByAuthorLoader(): Promise<Browse_ExpertsWithEntriesQuery> {
  return gqlRequest(EXPERTS_WITH_ENTRIES_QUERY)
}

export function useEntriesByAuthor(): AuthorGroups {
  const data = useLoaderData() as Browse_ExpertsWithEntriesQuery

  return data.accounts_expert.reduce<AuthorGroups>((acc, author) => {
    const entryCount =
      author.entries_sourced_aggregate.aggregate?.count ||
      0 + (author.user.entries_aggregate.aggregate?.count || 0)

    if (entryCount > 0) {
      const lastName = author.user.last_name
      const name = lastName.length > 0 ? lastName : author.user.username
      const letter = name[0].toLowerCase()

      return {
        ...acc,
        [letter]: [...(acc[letter] || []), author],
      }
    }

    return acc
  }, {})
}
