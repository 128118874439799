import { Params, useLoaderData } from 'react-router-dom'
import { StatusCodes } from 'http-status-codes'

import * as yup from 'yup'

import { gql } from 'graphql-generated'
import { gqlRequest } from 'api'
import { getToken, userIdClaim } from 'auth'

export type EntryLoaderData = {
  entryId: number
  pollId: number
  nextEntryId?: number
  previousEntryId?: number
  userLastLogin?: Date
}

const ENTRY_DATA_QUERY = gql(`
  query PollIdQuery($entryId: Int!) {
    entry: polls_entity_by_pk(id: $entryId) {
      next {
        id
      }
      poll {
        id
      }
      previous {
        id
      }
    }
  }
`)

const USER_LAST_LOGIN_QUERY = gql(`
  query UserLastLoginQuery($userId: Int!) {
    user: auth_user_by_pk(id: $userId) {
      last_login
    }
  }
`)

async function loadEntryData(
  entryId: number
): Promise<{ pollId: number; nextEntryId?: number; previousEntryId?: number }> {
  const { entry } = await gqlRequest(ENTRY_DATA_QUERY, {
    entryId,
  })

  if (entry) {
    return {
      pollId: entry.poll.id,
      nextEntryId: entry.next?.id,
      previousEntryId: entry.previous?.id,
    }
  }

  throw new Response('', {
    status: StatusCodes.NOT_FOUND,
  })
}

async function loadUserLastLogin(userId: number): Promise<Date | undefined> {
  const { user } = await gqlRequest(
    USER_LAST_LOGIN_QUERY,
    {
      userId,
    },
    { 'x-hasura-role': 'owner' }
  )

  if (user) {
    const { last_login: lastLogin } = user

    return lastLogin ? new Date(lastLogin) : undefined
  }

  throw new Response('', { status: StatusCodes.NOT_FOUND })
}

async function getUserLastLogin(): Promise<Date | undefined> {
  const token = getToken()

  return token ? loadUserLastLogin(userIdClaim(token)) : undefined
}

export const schema = yup
  .object()
  .shape({
    entryId: yup.number().required(),
    pollId: yup.number().required(),
    nextEntryId: yup.number(),
    userLastLogin: yup.date().notRequired().nonNullable(),
  })
  .required()

type EntryLoaderOptions = { params: Params<string> }

/**
 * The legacy browse entry component requires pollId and entryId props. However, the URL includes entryId only. The pollId param is missing.
 *
 * Also the legacy browse entry component depends on the user last login date. Current auth implementation does not include this user property
 * and it is required to obtain it explicitly.
 *
 * This loader fetches pollId for the specific entryId as well as user last login date (if user is logged in).
 */
export default async function entryLoader(
  options: EntryLoaderOptions
): Promise<EntryLoaderData> {
  const entryId = Number(options.params['entryId'])

  if (isNaN(entryId)) {
    throw new Error('Invalid Entry ID')
  }

  const [{ pollId, nextEntryId, previousEntryId }, userLastLogin] =
    await Promise.all([loadEntryData(entryId), getUserLastLogin()])

  return { entryId, pollId, nextEntryId, previousEntryId, userLastLogin }
}

export function useEntryLoaderData(): EntryLoaderData {
  return schema.validateSync(useLoaderData())
}
