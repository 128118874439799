import * as yup from 'yup'
import { format } from 'date-fns'

import client from './axios'

const ReportDataSchema = yup
  .object()
  .shape({
    doi: yup.number().required(),
    entries: yup.number().required(),
    experts: yup.number().required(),
    honorariums: yup.number().required(),
  })
  .required()

const ReportGroupSchema = yup.object().shape({
  name: yup.string().required(),
  data: ReportDataSchema,
})

const WeeklyReportSchema = yup
  .object()
  .shape({
    total: ReportDataSchema,
    weeks: yup.array().of(ReportGroupSchema).required(),
  })
  .required()

const MonthlyReportSchema = yup.object().shape({
  total: ReportDataSchema,
  months: yup.array().of(ReportGroupSchema).required(),
})

type WeeklyReport = yup.InferType<typeof WeeklyReportSchema>
type MonthlyReport = yup.InferType<typeof MonthlyReportSchema>

type Filter = {
  groupBy: 'months' | 'weeks'
  dateFrom: Date
  dateTo: Date
  polls: ReadonlyArray<number>
}

function formatQueryDate(date: Date): string {
  return format(date, 'yyyy-MM-dd')
}

function getSearchParams(filter: Filter): URLSearchParams {
  const params = new URLSearchParams()

  params.append('show', filter.groupBy)
  params.append('date_from', formatQueryDate(filter.dateFrom))
  params.append('date_to', formatQueryDate(filter.dateTo))

  filter.polls.forEach((poll) => params.append('polls', String(poll)))

  return params
}

export async function getReport(
  filter: Filter,
  signal: AbortSignal
): Promise<WeeklyReport | MonthlyReport> {
  const params = getSearchParams(filter)

  const data = await client
    .get('/polls/statistics/', { params, signal })
    .then(({ data }) => data)

  const schema =
    filter.groupBy === 'weeks' ? WeeklyReportSchema : MonthlyReportSchema

  return schema.validateSync(data)
}
