import { EntryTooltip } from 'apps/browse/common'

import { PollTypeIcon, POLL_TYPE_DESCRIPTIONS } from 'common/poll-types'

type EntryPollTypeBoxProps = {
  type: string
}

export default function EntryPollTypeBox(props: EntryPollTypeBoxProps) {
  switch (props.type) {
    case 'religious_group':
    case 'religious_place':
    case 'religious_text':
    case 'external':
      return (
        <span className="relative mr-2 shrink-0 cursor-help text-[1px]">
          <EntryTooltip tooltipText={POLL_TYPE_DESCRIPTIONS[props.type]}>
            <PollTypeIcon className="fill-primary" type={props.type} />
          </EntryTooltip>
        </span>
      )
    default:
      throw new Error(`Unknown poll type: ${props.type}`)
  }
}
