import { format as _format } from 'date-fns'
import { date } from 'yup'

type TimestampProps = { date: Date; format?: string }

export function formatDate(date: Date, format?: string): string {
  return _format(date, format || 'MMM d, Y')
}

export default function Timestamp(props: TimestampProps) {
  return <>{formatDate(props.date, props.format)}</>
}

export function validateTimestamp(timestamp: any): Date {
  return date().required().validateSync(timestamp)
}
