import { useLoaderData } from 'react-router-dom'
import * as yup from 'yup'

import { gql } from 'graphql-generated'
import { gqlRequest } from 'api'

import { EntryTagGroup, entryTagGroupSchema } from './entry-tag-group'

const ENTRY_TAG_GROUP_QUERY = gql(`
  query EntryTagGroup {
    polls_entrytaggroup(
      where: {
        tags: {
          _and: [
            { level: { _eq: 2 } }
            {
              entries: {
                entry: {
                  _and: [
                    { progress: { published: { _gt: 0 } } }
                    { region: { geom: { _is_null: false } } }
                    {
                      base_answer_sets: {
                        _and: [
                          { region: { geom: { _is_null: false } } }
                          { answer_sets: { published: { _eq: true } } }
                        ]
                      }
                    }
                  ]
                }
              }
            }
          ]
        }
      }
      order_by: { order: asc }
    ) {
      id
      name
    }
  }
`)

export default async function entryTagGroupLoader(): Promise<
  ReadonlyArray<EntryTagGroup>
> {
  const { polls_entrytaggroup: groups } = await gqlRequest(
    ENTRY_TAG_GROUP_QUERY
  )

  return groups
}

const entryTagGroupDataSchema = yup.array(entryTagGroupSchema).required()

export function useEntryTagGroupLoaderData(): ReadonlyArray<EntryTagGroup> {
  return entryTagGroupDataSchema.validateSync(useLoaderData())
}
