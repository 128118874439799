import { ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  onClick?: () => void
  type?: 'reset' | 'button'
  disabled?: boolean
  small?: boolean
  color?: 'red'
  children: ReactNode
}

export default function CancelButton({
  className,
  onClick,
  type = 'button',
  disabled = false,
  small,
  color,
  children,
}: Props) {
  return (
    <button
      className={classNames(
        'whitespace-nowrap py-4 px-5 underline decoration-transparent transition-colors',
        'disabled:cursor-default disabled:text-gray-drh',
        { 'text-primary hover:decoration-primary': color === undefined },
        { 'text-red-600 hover:decoration-red-600': color === 'red' },
        { '!py-1': small },
        className
      )}
      onClick={onClick === undefined ? undefined : () => onClick()}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
