import { PropsWithChildren } from 'react'

export default function EntryProperty({
  children,
  inline = false,
}: PropsWithChildren<{ inline?: boolean }>) {
  const Tag = inline ? 'span' : 'p'

  return (
    <Tag className={inline ? 'text-black-drh' : 'text-gray-drh'}>
      {children}
    </Tag>
  )
}
