import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { useGetText } from 'lib/gettext'
import { AuthorizedFragment, Spinner, TabNav, Tab } from 'common'
import { canManagePolls } from 'auth'

export default function PollManagerLayout() {
  const { gettext } = useGetText()

  return (
    <div className="flex flex-grow flex-col py-8 px-4 sm:px-8">
      <h1 className="mr-6 mb-4 text-3xl font-bold">
        {gettext('Poll Manager')}
      </h1>

      <AuthorizedFragment authorize={canManagePolls}>
        <TabNav>
          <Tab type="router-link" to="/poll-manager/polls">
            {gettext('Polls')}
          </Tab>

          <Tab type="router-link" to="/poll-manager/drafts">
            {gettext('Drafts')}
          </Tab>
        </TabNav>
      </AuthorizedFragment>

      <Suspense fallback={<Spinner className="m-auto" />}>
        <Outlet />
      </Suspense>
    </div>
  )
}
