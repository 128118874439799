import { GraphQLClient } from 'graphql-request'

import { getAccessTokenId } from 'auth'
import { GRAPHQL_URL } from 'config'

const client = new GraphQLClient(GRAPHQL_URL, {
  requestMiddleware: async (request) => {
    const token = await getAccessTokenId()

    if (token) {
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    }

    return request
  },
})

export default client.request.bind(client)
