import GoogleMap from 'google-map-react'
import { gql, FragmentType, useFragment } from 'graphql-generated'
import { useCallback } from 'react'

import { GOOGLE_MAPS_API_KEY } from 'config'

const REGION_FRAGMENT = gql(`
  fragment EntrySearch_Entry_Region on Entry {
    region {
      geometry {
        type
        coordinates
      }
    }
  }
`)

type EntryRegionProps = {
  entryRef: FragmentType<typeof REGION_FRAGMENT>
}

export default function EntryRegion(props: EntryRegionProps) {
  const { geometry } = useFragment(REGION_FRAGMENT, props.entryRef).region || {}

  const onMapApiLoaded = useCallback(
    ({ map }: { map: google.maps.Map }) => {
      map.data.addGeoJson({
        type: 'Feature',
        geometry,
      })

      map.data.setStyle({
        strokeColor: '#3c8cb5',
        fillColor: '#3c8cb5',
        fillOpacity: 0.3,
        strokeWeight: 3,
      })

      // Zoom to region
      const bounds = new window.google.maps.LatLngBounds()

      map.data.forEach((feature) => {
        const geometry = feature.getGeometry()

        if (geometry) {
          geometry.forEachLatLng((a) => bounds.extend(a))
        }
      })

      map.fitBounds(bounds)
    },
    [geometry]
  )

  if (geometry) {
    return (
      <div className="h-36 w-full">
        <GoogleMap
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_API_KEY,
            libraries: ['drawing', 'visualization'],
          }}
          defaultZoom={2}
          defaultCenter={{ lat: 0, lng: 0 }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={onMapApiLoaded}
          draggable={false}
          options={{ fullscreenControl: false, keyboardShortcuts: false }}
        />
      </div>
    )
  }

  return null
}
