import { ReactNode } from 'react'
import { ValuesType } from 'utility-types'

import { useGetText } from 'lib/gettext'

import { InlineList } from 'apps/browse/common'
import EntryProperty from './entry-property'

type Props<T> = {
  names:
    | ReadonlyArray<(T & { id: string | number }) | null | undefined>
    | null
    | undefined
  children: (name: T & { id: string | number }) => ReactNode
}

export default function EntryAlternativeNamesBox<T>({
  names,
  children,
}: Props<T>) {
  const { gettext } = useGetText()

  const nonEmptyNames = (
    names ? names.filter((name) => name !== null) : []
  ) as ReadonlyArray<NonNullable<ValuesType<NonNullable<typeof names>>>>

  if (nonEmptyNames.length > 0) {
    return (
      <EntryProperty>
        {gettext('Also known as')}{' '}
        <InlineList items={nonEmptyNames} keyField="id">
          {(item) => children(item)}
        </InlineList>
      </EntryProperty>
    )
  }

  return null
}
