import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import { PageNotFoundError, ForbiddenError } from 'common'

// There is no way to import ErrorResponse class from react-router.
type RouteError = {
  data: unknown
  status: number
  statusText: string
}

function GenericRouteError({ error }: { error: RouteError }) {
  return (
    <>
      <h3>Unhandled error response</h3>
      <h1 className="mb-3 text-2xl">
        {error.status} {error.statusText}
      </h1>
      {error.data && (
        <pre className="w-full overflow-x-scroll font-mono text-xs">
          {JSON.stringify(error.data)}
        </pre>
      )}
    </>
  )
}

function RouteErrorResponse({ error }: { error: RouteError }) {
  switch (error.status) {
    case 404:
      return <PageNotFoundError />
    case 403:
      return <ForbiddenError />
    default:
      return <GenericRouteError error={error} />
  }
}
export default function RouterErrorFallback() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return <RouteErrorResponse error={error} />
  }

  throw error
}
