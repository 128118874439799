import { MEDIA_URL } from 'config'

import { useGetText } from 'lib/gettext'

import EntryDetailsItem from './entry-details-item'
import EntryTooltip from './entry-tooltip'

import dataSourceIcon from './assets/data-source.svg'

type Props = {
  icon?: string | null
  tooltip?: string | null
  externalUrl: string | null | undefined
  children: string | undefined
  absoluteIconPath?: boolean
}

export default function EntryDataSource({
  externalUrl,
  icon,
  tooltip,
  children,
  absoluteIconPath = false,
}: Props) {
  const { gettext } = useGetText()

  if (!externalUrl) {
    return null
  }

  const iconPath = icon?.trim()
  const tooltipText = tooltip?.trim()

  const sourceIcon =
    iconPath && iconPath !== ''
      ? absoluteIconPath
        ? iconPath
        : MEDIA_URL + '/media/' + iconPath
      : dataSourceIcon

  const title = children

  const ExternalLink = () => (
    <a href={externalUrl} className="link" target="_blank" rel="noreferrer">
      {title}
    </a>
  )

  return (
    <EntryDetailsItem icon={sourceIcon}>
      <EntryTooltip tooltipText={tooltipText}>
        {gettext('Data source')}
      </EntryTooltip>
      {': '}
      <ExternalLink />
    </EntryDetailsItem>
  )
}
