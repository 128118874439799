import classNames from 'classnames'

import { useGetText } from 'lib/gettext'

import {
  useAuthenticatedUser,
  canAccessAdminConsole,
  canAccessPollManager,
  canManageTags,
  useLogOut,
  canAccessEditorConsole,
  canAccessWagtailConsole,
} from 'auth'

import {
  DropdownTrigger,
  DropdownTriggerCaret,
  DropdownItem,
  DropdownItemProps,
  DropdownItemDivider,
  DropdownMenu,
  Dropdown,
  AuthorizedFragment,
  UserDisplayName,
} from 'common'

import { useHeaderContext } from './context'

function Item(props: DropdownItemProps) {
  const { children, ...rest } = props

  return (
    <DropdownItem className="capitalize" {...rest}>
      {children}
    </DropdownItem>
  )
}

export default function UserDropdown() {
  const { gettext } = useGetText()
  const { isWrapped } = useHeaderContext()
  const logout = useLogOut()
  const user = useAuthenticatedUser()

  return (
    <Dropdown
      trigger={
        <DropdownTrigger
          className={classNames(
            'py-2 capitalize text-white !decoration-transparent mix-blend-screen transition md:py-0',
            {
              'hover:text-primary': isWrapped,
              'hover:text-inherit hover:shadow-[inset_0_-1em] hover:shadow-primary':
                !isWrapped,
            }
          )}
        >
          <UserDisplayName {...user} />{' '}
          <DropdownTriggerCaret
            className={classNames('fill-white', {
              'group-hover:!fill-primary': isWrapped,
            })}
          />
        </DropdownTrigger>
      }
    >
      <DropdownMenu
        className={classNames('right-0', isWrapped ? 'mt-1' : '-mt-4')}
      >
        <Item type="router-link" to="/settings">
          {gettext('My settings')}
        </Item>

        <Item type="router-link" to="/dashboard">
          {gettext('My dashboard')}
        </Item>

        <Item type="router-link" to="/media-library">
          {gettext('My media library')}
        </Item>

        <Item type="router-link" to="/getting-started">
          {gettext('Getting started')}
        </Item>

        <AuthorizedFragment authorize={canAccessEditorConsole}>
          <Item type="router-link" to="/editor-console">
            {gettext('Editor console')}
          </Item>
        </AuthorizedFragment>

        <AuthorizedFragment authorize={canAccessPollManager}>
          <Item type="router-link" to="/poll-manager">
            {gettext('Poll manager')}
          </Item>
        </AuthorizedFragment>

        <AuthorizedFragment authorize={canManageTags}>
          <Item type="router-link" to="/tag-manager">
            {gettext('Tag manager')}
          </Item>
        </AuthorizedFragment>

        <DropdownItemDivider />

        <Item type="router-link" to="/contribute">
          {gettext('Contribute')}
        </Item>

        <AuthorizedFragment authorize={canAccessWagtailConsole}>
          <Item type="link" href="/cms">
            {gettext('Manage Content')}
          </Item>
        </AuthorizedFragment>

        <DropdownItemDivider />

        <AuthorizedFragment authorize={canAccessAdminConsole}>
          <Item type="link" href="/admin/">
            {gettext('Administration')}
          </Item>
        </AuthorizedFragment>

        <Item type="button" onClick={logout}>
          {gettext('Log out')}
        </Item>
      </DropdownMenu>
    </Dropdown>
  )
}
