import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import { toModifier, calcPercent } from 'legacy/libs/utils'
import HoverOver from 'legacy/libs/components/hover-over'
import createReactClass from 'create-react-class'
import { GetText } from 'lib/gettext'
import AdditionalQuestions from '../additional-questions'

import s from './progress.module.scss'

const COLORS = ['purple', 'blue', 'green', 'yellow', 'pink']

export default createReactClass({
  displayName: 'Progress',

  propTypes: {
    answered: PropTypes.number,
    total: PropTypes.number,
    additionalAnswered: PropTypes.number,
    additionalTotal: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.object),

    children: PropTypes.node,
    full: PropTypes.bool,
    colorTheme: PropTypes.oneOf(['light', 'dark']),
    answersLabel: PropTypes.string,
    className: PropTypes.string,
  },

  renderLine(widthFn) {
    const { categories } = this.props

    return (
      <div className={s.runner__inner}>
        {_.map(categories, (category, index) => {
          const width = _.floor(widthFn(category.progress), 2)

          if (!width) {
            return null
          }

          return (
            <div
              key={index}
              className={classNames(s.item, s[toModifier(COLORS[index])])}
              style={{ width: `${width}%` }}
            >
              <HoverOver tip={category.name}>
                <span className={s.color} />
              </HoverOver>
            </div>
          )
        })}
        <div className={classNames(s.item, s._empty)} />
      </div>
    )
  },

  renderLineByCategory(category, index) {
    const categoryProgress = category.progress
    const { answered, total, additionalAnswered, additionalTotal } =
      categoryProgress
    const progressPercent = calcPercent(answered, total, 0)
    const additionalProgressPercent = calcPercent(
      additionalAnswered,
      additionalTotal,
      0
    )

    return (
      <div
        key={index}
        className={classNames(
          s.individual,
          s[toModifier(COLORS[index % COLORS.length])]
        )}
      >
        <div className={s.individual__title}>
          {_.head(category.name.split(' '))}:
        </div>
        <div className={s.individual__progress}>
          <div className={s.individual__common}>
            <span style={{ width: `${progressPercent}%` }} />
          </div>
          {additionalProgressPercent ? (
            <div className={s.individual__additional}>
              <span style={{ width: `${additionalProgressPercent}%` }} />
            </div>
          ) : null}
        </div>
        <div className={s.individual__text}>
          <span>
            {answered}/{total}
          </span>
          <span> </span>
          {additionalProgressPercent ? (
            <AdditionalQuestions>
              {additionalAnswered}/{additionalTotal}
            </AdditionalQuestions>
          ) : null}
        </div>
      </div>
    )
  },

  renderLinesByCategories() {
    return (
      <div className={s.individuals}>
        {_.map(this.props.categories, this.renderLineByCategory)}
      </div>
    )
  },

  render() {
    const {
      className,
      full,
      answered,
      total,
      additionalAnswered,
      additionalTotal,
      colorTheme,
    } = this.props
    const progressPercent = calcPercent(answered, total, 0)
    const additionalProgressPercent = calcPercent(
      additionalAnswered,
      additionalTotal,
      0
    )

    return (
      <div
        className={classNames(
          s.progress,
          {
            [s._full]: full,
            [s._light]: colorTheme === 'light',
          },
          className
        )}
      >
        <div className={s.runners}>
          <div className={s.runner}>
            {this.renderLine(
              (progress) => (progress.answered / answered) * progressPercent
            )}
          </div>

          {additionalProgressPercent ? (
            <div className={classNames(s.runner, s._small)}>
              {this.renderLine((progress) =>
                additionalAnswered
                  ? (progress.additionalAnswered / additionalAnswered) *
                    additionalProgressPercent
                  : 0
              )}
            </div>
          ) : null}
        </div>
        {full ? this.renderLinesByCategories() : null}
        <span>
          <GetText>Total:</GetText> {answered}/{total}
        </span>{' '}
        {/* For light color theme text's color should be inverted to dark */}
        {additionalProgressPercent ? (
          <AdditionalQuestions
            colorTheme={colorTheme === 'light' ? 'dark' : null}
          >
            {additionalAnswered}/{additionalTotal}
          </AdditionalQuestions>
        ) : null}
        {this.props.children}
      </div>
    )
  },
})
