import { gql } from 'graphql-generated'

import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

import { Navigate, Outlet } from 'react-router-dom'
import { Subtract } from 'utility-types'
import { useLazyQuery } from '@apollo/client'

import {
  expertIdClaim,
  managingEditorIdClaim,
  regionalEditorIdClaim,
  rolesClaim,
  suClaim,
  Token,
  userIdClaim,
  useWatchToken,
  getToken,
  removeToken,
} from './token'

type AnonymousUser = { type: 'anonymous' }

export const ANONYMOUS_USER: AnonymousUser = { type: 'anonymous' }

export enum Role {
  TRANSLATOR = 'TRANSLATOR',
  REGIONAL_EDITOR = 'REGIONAL_EDITOR',
  MANAGING_EDITOR = 'MANAGING_EDITOR',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  SUPERUSER = 'SUPERUSER',
  STAFF = 'STAFF',
}

type TranslatorClaims = null
type RegionalEditorClaims = { editorId: number }
type ManagingEditorClaims = { editorId: number }
type AccountManagerClaims = null
type StaffClaims = null
type SuperuserClaims = null

type UserRoles = {
  [Role.TRANSLATOR]?: TranslatorClaims
  [Role.REGIONAL_EDITOR]?: RegionalEditorClaims
  [Role.MANAGING_EDITOR]?: ManagingEditorClaims
  [Role.ACCOUNT_MANAGER]?: AccountManagerClaims
  [Role.STAFF]?: StaffClaims
  [Role.SUPERUSER]?: SuperuserClaims
}

export type AuthenticatedUser = {
  type: 'authenticated'
  userId: number
  expertId: number
  su: boolean
  roles: UserRoles
  profileId: number
  email: string
  username: string
  firstName: string
  lastName: string
  language: string
  approved: boolean
  acceptedTermsOfUse: boolean
  acceptedAttachmentConditions: boolean
}

export type CurrentUser = AnonymousUser | AuthenticatedUser

export function isAnonymous(
  currentUser: CurrentUser
): currentUser is AnonymousUser {
  return currentUser.type === 'anonymous'
}

export function isAuthenticated(
  currentUser: CurrentUser
): currentUser is AuthenticatedUser {
  return currentUser.type === 'authenticated'
}

type UserRole<T extends Role> = AuthenticatedUser & {
  roles: { [key in T]: Required<UserRoles>[T] }
}

export type Translator = UserRole<Role.TRANSLATOR>
export type RegionalEditor = UserRole<Role.REGIONAL_EDITOR>
export type ManagingEditor = UserRole<Role.MANAGING_EDITOR>
type AccountManager = UserRole<Role.ACCOUNT_MANAGER>
type Staff = UserRole<Role.STAFF>
type Superuser = UserRole<Role.SUPERUSER>

function roleMatcher(user: AuthenticatedUser): (role: Role) => boolean {
  return (role) => role in user.roles
}

export function hasRole(user: AuthenticatedUser, role: Role): boolean {
  return roleMatcher(user)(role)
}

export function hasAnyRole(
  user: AuthenticatedUser,
  roles: ReadonlyArray<Role>
): boolean {
  return roles.some(roleMatcher(user))
}

export function isTranslator(user: AuthenticatedUser): user is Translator {
  return hasRole(user, Role.TRANSLATOR)
}

export function isRegionalEditor(
  user: AuthenticatedUser
): user is RegionalEditor {
  return hasRole(user, Role.REGIONAL_EDITOR)
}

export function isManagingEditor(
  user: AuthenticatedUser
): user is ManagingEditor {
  return hasRole(user, Role.MANAGING_EDITOR)
}

export function isEditor(
  user: AuthenticatedUser
): user is RegionalEditor | ManagingEditor {
  return hasAnyRole(user, [Role.REGIONAL_EDITOR, Role.MANAGING_EDITOR])
}

export function isAccountManager(
  user: AuthenticatedUser
): user is AccountManager {
  return hasRole(user, Role.ACCOUNT_MANAGER)
}

export function isStaff(user: AuthenticatedUser): user is Staff {
  return hasRole(user, Role.STAFF)
}

export function isSuperuser(user: AuthenticatedUser): user is Superuser {
  return hasRole(user, Role.SUPERUSER)
}

export function userId(user: AuthenticatedUser): number {
  return user.userId
}

export function username(user: AuthenticatedUser): string {
  return user.username
}

export function expertId(user: AuthenticatedUser): number {
  return user.expertId
}

export function managingEditorId(user: ManagingEditor): number {
  return user.roles[Role.MANAGING_EDITOR].editorId
}

export function regionalEditorId(user: RegionalEditor): number {
  return user.roles[Role.REGIONAL_EDITOR].editorId
}

export function profileId(user: AuthenticatedUser): number {
  return user.profileId
}

export function isApprovedUser(user: AuthenticatedUser): boolean {
  return user.approved
}

export function isAcceptedTermsOfUse(user: AuthenticatedUser): boolean {
  return user.acceptedTermsOfUse
}

export function isAcceptedAttachmentConditions(
  user: AuthenticatedUser
): boolean {
  return user.acceptedAttachmentConditions
}

export function language(user: AuthenticatedUser): string {
  return user.language
}

function resolveRoles(token: Token): UserRoles {
  const userId = userIdClaim(token)

  return rolesClaim(token).reduce((roles, role) => {
    switch (role) {
      case 'regional-editor': {
        const regionalEditorId = regionalEditorIdClaim(token)

        if (regionalEditorId) {
          return {
            ...roles,
            [Role.REGIONAL_EDITOR]: { editorId: regionalEditorId },
          }
        }

        throw new Error(
          `Failed to resolve regional editor role for user #${userId}: editor id is undefined`
        )
      }

      case 'managing-editor': {
        const managingEditorId = managingEditorIdClaim(token)

        if (managingEditorId) {
          return {
            ...roles,
            [Role.MANAGING_EDITOR]: { editorId: managingEditorId },
          }
        }

        throw new Error(
          `Failed to resolve managing editor role for user #${userId}: editor id is undefined`
        )
      }

      case 'account-manager':
        return { ...roles, [Role.ACCOUNT_MANAGER]: null }

      case 'staff':
        return { ...roles, [Role.STAFF]: null }

      case 'superuser':
        return { ...roles, [Role.SUPERUSER]: null }

      case 'translator':
        return { ...roles, [Role.TRANSLATOR]: null }

      default:
        console.warn(`Unexpected user role: ${role}`)

        return roles
    }
  }, {} as UserRoles)
}

const USER_QUERY = gql(`
  query CurrentUserQuery($userId: Int!) {
    user: auth_user_by_pk(id: $userId) {
      username
      first_name
      last_name
      email
      profile {
        id
        language
        approved_attachments_conditions
      }
      expert {
        approved
        confirmed_agreement
      }
    }
  }
`)

class InvalidTokenError extends Error {}

export function useCurrentUserQuery(): () => Promise<AuthenticatedUser> {
  const [query] = useLazyQuery(USER_QUERY, {
    fetchPolicy: 'no-cache',
  })

  return useCallback(async () => {
    const token = getToken()

    if (!token) {
      throw new Error('Unable to query current user: token is missing')
    }

    const userId = userIdClaim(token)

    const expertId = expertIdClaim(token)
    const su = suClaim(token)
    const roles = resolveRoles(token)

    const { data, error } = await query({
      context: {
        hasuraRole: 'owner',
      },
      variables: { userId },
    })

    if (error) {
      const invalidTokenError = error.graphQLErrors.find(
        (e) => e.extensions.path === '$' && e.extensions.code === 'invalid-jwt'
      )

      if (invalidTokenError) {
        throw new InvalidTokenError()
      } else {
        throw error
      }
    }

    const user = data?.user
    const { profile, expert } = user!

    if (user && profile && expert) {
      const {
        username,
        email,
        first_name: firstName,
        last_name: lastName,
      } = user

      return {
        type: 'authenticated',
        userId,
        expertId,
        su: (su || []).length > 0,
        profileId: profile.id,
        roles,
        username,
        firstName,
        lastName,
        email,
        language: profile.language,
        approved: expert.approved,
        acceptedTermsOfUse: expert.confirmed_agreement,
        acceptedAttachmentConditions: profile.approved_attachments_conditions,
      }
    } else if (!profile) {
      throw new Error(`Profile for user ${userId} not found`)
    } else if (!expert) {
      throw new Error(`Expert for user ${userId} not found`)
    }

    throw new Error(`User ${userId} not found`)
  }, [query])
}

type CurrentUserContext = {
  currentUser: CurrentUser
  setCurrentUser: (currentUser: CurrentUser) => void
}

const CURRENT_USER_CONTEXT = createContext<CurrentUserContext | undefined>(
  undefined
)

export function useCurrentUserContext(): CurrentUserContext {
  const context = useContext(CURRENT_USER_CONTEXT)

  if (context) {
    return context
  }

  throw new Error('Current user context is not initialized')
}

type CurrentUserProviderProps = PropsWithChildren<{ fallback: ReactNode }>

export function CurrentUserProvider(props: CurrentUserProviderProps) {
  const { children, fallback } = props

  const query = useCurrentUserQuery()
  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>()

  const handleChange = useCallback(
    async (token: Token | undefined) => {
      try {
        const user = token ? await query() : ANONYMOUS_USER

        setCurrentUser(user)
      } catch (error) {
        if (error instanceof InvalidTokenError) {
          removeToken()
          setCurrentUser(ANONYMOUS_USER)
        } else {
          throw error
        }
      }
    },
    [query, setCurrentUser]
  )

  // Handle token change triggered from other browser tabs/windows
  useWatchToken(handleChange)

  if (currentUser) {
    return (
      <CURRENT_USER_CONTEXT.Provider value={{ currentUser, setCurrentUser }}>
        {children}
      </CURRENT_USER_CONTEXT.Provider>
    )
  }

  return <>{fallback}</>
}

export function useCurrentUser(): CurrentUser {
  const { currentUser } = useCurrentUserContext()

  return currentUser
}

export function isSubstituteUser(currentUser: AuthenticatedUser): boolean {
  return currentUser.su
}

/**
 * Use this hook to guarantee an access to the anonymous user. Throws an error if current user is not initialized or is authenticated.
 *
 * @returns {AnonymousUser} anonymous user
 */
export function useAnonymousUser(): AnonymousUser {
  const currentUser = useCurrentUser()

  if (isAnonymous(currentUser)) {
    return currentUser
  }

  throw new Error('Unexpected attempt to access authenticated user')
}

/**
 * Use this hook to guarantee an access to the authenticated user. Throws an error if current user is not initialized or is not authenticated.
 *
 * @returns {AuthenticatedUser} authenticated user
 */
export function useAuthenticatedUser(): AuthenticatedUser {
  const currentUser = useCurrentUser()

  if (isAuthenticated(currentUser)) {
    return currentUser
  }

  throw new Error('Unexpected attempt to access authenticated user')
}

export function usePollManagerUser():
  | RegionalEditor
  | ManagingEditor
  | Translator {
  const user = useAuthenticatedUser()

  if (isRegionalEditor(user) || isManagingEditor(user) || isTranslator(user)) {
    return user
  }

  throw new Error(
    'Unexpected attempt to access user that is not RegionalEditor, ManagingEditor or Translator'
  )
}

/**
 * Use this component to restrict access to specific app routes that require users to be authenticated.
 *
 * @example
 * <Route
 *  path="/path-for-members"
 *  element={<RequireAuthenticatedUser redirectTo="/login" />}
 * >
 *   // <SomeComponent /> won't be rendered unless the current user is authenticated.
 *   <SomeComponent />
 * </Route>
 */
export function RequireAuthenticatedUser({
  redirectTo,
}: {
  redirectTo?: string
}) {
  const currentUser = useCurrentUser()

  if (isAuthenticated(currentUser)) {
    return <Outlet />
  }

  return <Navigate to={redirectTo || '/login'} />
}

type InjectedCurrentUserProps = {
  user?: CurrentUser
}

export const withCurrentUser = <P extends InjectedCurrentUserProps>(
  Component: React.ComponentType<P>
) =>
  React.forwardRef(function WithCurrentUser(
    props: Subtract<P, InjectedCurrentUserProps>,
    ref
  ) {
    const user = useCurrentUser()

    return <Component {...(props as P)} user={user} ref={ref} />
  })

type InjectedAuthenticatedUserProps = {
  user?: AuthenticatedUser
}

export const withAuthenticatedUser = <P extends InjectedAuthenticatedUserProps>(
  Component: React.ComponentType<P>
) =>
  React.forwardRef(function WithAuthenticatedUser(
    props: Subtract<P, InjectedAuthenticatedUserProps>,
    ref
  ) {
    const user = useCurrentUser()

    if (isAuthenticated(user)) {
      return <Component {...(props as P)} user={user} ref={ref} />
    }

    throw new Error('Unexpected attempt to access anonymous user')
  })

/**
 * Used to force the current user reload.
 */
export function useCurrentUserReload(): () => Promise<void> {
  const query = useCurrentUserQuery()
  const { setCurrentUser } = useCurrentUserContext()

  return useCallback(async () => {
    const user = await query()

    setCurrentUser(user)
  }, [query, setCurrentUser])
}
