import { decode } from 'js-base64'
import { EntryLinkBox, Highlight } from 'apps/browse/common'

type EntryLinkProps = {
  id: string
  query: string
  children: string | null | undefined
}

export function resolveGlobalId(globalId: string): number {
  const [, id] = decode(globalId).split(':')

  if (typeof id === 'string') {
    return Number(id)
  }

  throw new Error(`Failed to resolve global id from "${globalId}"`)
}

export default function EntryLink(props: EntryLinkProps) {
  const entryId = resolveGlobalId(props.id)

  return (
    <EntryLinkBox entryId={entryId}>
      <Highlight this={props.query}>{props.children}</Highlight>
    </EntryLinkBox>
  )
}
