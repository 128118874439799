import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryContributorsBox } from 'apps/browse/common'
import ExpertLink from './expert-link'

const CONTRIBUTORS_FRAGMENT = gql(`
  fragment EntrySearch_Entry_Contributors on Entry {
    entry_source
    contributors {
      id
      ...EntrySearch_Entry_ExpertLink
    }
  }
`)

type EntryContributorsProps = {
  entryRef: FragmentType<typeof CONTRIBUTORS_FRAGMENT>
  query: string
}

export default function EntryContributors(props: EntryContributorsProps) {
  const { entry_source: source, contributors } = useFragment(
    CONTRIBUTORS_FRAGMENT,
    props.entryRef
  )

  const isPersonalExpertise = source === 0
  const isSupervisedEntry = source === 3

  return (
    <EntryContributorsBox
      contributors={contributors}
      personalExpertise={isPersonalExpertise || isSupervisedEntry}
    >
      {(contributor) => (
        <ExpertLink query={props.query} userRef={contributor} />
      )}
    </EntryContributorsBox>
  )
}
