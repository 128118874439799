import Highlighter from 'react-highlight-words'

import { tokenize } from 'apps/browse/utils'

type Props = {
  this: string
  children: string | null | undefined
}

export default function Highlight({ this: query, children: text }: Props) {
  const tokens = tokenize(query)

  return (
    <Highlighter
      highlightTag="span"
      highlightClassName="font-extrabold bg-transparent"
      searchWords={tokens}
      textToHighlight={text || ''}
    />
  )
}
