import { useCallback } from 'react'
import { useCookies } from 'react-cookie'

import {
  CreateTokenFailureCode,
  Credentials,
  removeToken,
  useCreateToken,
} from './token'

import {
  ANONYMOUS_USER,
  AuthenticatedUser,
  useCurrentUserContext,
  useCurrentUserQuery,
} from './current-user'

export enum LoginFailureCode {
  INVALID_CREDENTIALS = CreateTokenFailureCode.INVALID_CREDENTIALS,
}

function resolveFailureCode(code: CreateTokenFailureCode): LoginFailureCode {
  switch (code) {
    case CreateTokenFailureCode.INVALID_CREDENTIALS:
      return LoginFailureCode.INVALID_CREDENTIALS
  }
}

/**
 * Provides an API to log in a user using their credentials.
 */
export function useLogIn(
  onSuccess: (user: AuthenticatedUser) => Promise<void>,
  onFailure: (failure: LoginFailureCode) => void
): (credentials: Credentials) => Promise<void> {
  const { setCurrentUser } = useCurrentUserContext()
  const loadCurrentUser = useCurrentUserQuery()

  const onCreateTokenSuccess = useCallback(async () => {
    const user = await loadCurrentUser()

    setCurrentUser(user)
    await onSuccess(user)
  }, [loadCurrentUser, setCurrentUser, onSuccess])

  const onCreateTokenFailure = useCallback(
    (code: CreateTokenFailureCode) => {
      onFailure(resolveFailureCode(code))
    },
    [onFailure]
  )

  const createToken = useCreateToken(onCreateTokenSuccess, onCreateTokenFailure)

  return useCallback(
    async (credentials: Credentials) => {
      return createToken(credentials)
    },
    [createToken]
  )
}

export function useLogOut(): () => void {
  const { setCurrentUser } = useCurrentUserContext()
  const [, , removeCookie] = useCookies(['sessionid'])

  return useCallback(() => {
    removeCookie('sessionid')
    removeToken()
    setCurrentUser(ANONYMOUS_USER)
  }, [removeCookie, setCurrentUser])
}
