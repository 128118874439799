import { GetText } from 'lib/gettext'
import { Timestamp } from 'common'
import EntryProperty from './entry-property'

type Props = {
  date: string
}

export default function EntryPublicationDate({ date: dateString }: Props) {
  if (dateString.length > 0) {
    const date = new Date(Date.parse(dateString))

    return (
      <EntryProperty>
        <GetText
          components={{
            timestamp: (
              <span className="text-black-drh">
                <Timestamp date={date} />
              </span>
            ),
          }}
        >
          Published on [timestamp/]
        </GetText>
      </EntryProperty>
    )
  }

  return null
}
