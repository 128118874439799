import { ChangeEvent, useCallback, useState } from 'react'

import {
  useController,
  UseControllerProps,
  FieldValues,
  FieldPath,
} from 'react-hook-form'

import { useGetText } from 'lib/gettext'

type YearInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  label: string
  id: string
  onChange: (value: number | undefined) => void
}

export default function YearInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: YearInputProps<TFieldValues, TName>) {
  const { id, label, onChange, ...controllerProps } = props
  const { field } = useController(controllerProps)

  const absValue = field.value ? Math.abs(field.value) : undefined
  const displayValue = absValue ? absValue.toString() : ''

  const { gettext } = useGetText()
  const [era, setEra] = useState<1 | -1>(field.value < 0 ? -1 : 1)

  const changeValue = useCallback(
    (value: number | undefined) => {
      field.onChange(value)
      onChange(value)
    },
    [field, onChange]
  )

  const changeDisplayValue = useCallback(
    (value: string) => {
      const trimmedValue = value.trim()

      const nextValue =
        trimmedValue.length > 0
          ? Math.abs(parseInt(trimmedValue)) * era
          : undefined

      changeValue(nextValue)
    },
    [changeValue, era]
  )

  const changeEra = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = parseInt(event.target.value)

      if (value !== 1 && value !== -1) {
        throw new Error('Era is out of range')
      }

      setEra(value)

      if (absValue) {
        const nextValue = absValue * value

        changeValue(nextValue)
      }
    },
    [changeValue, absValue, setEra]
  )

  return (
    <div className="flex rounded border border-[#ced4da] bg-white">
      <label
        htmlFor={id}
        className="rounded-l border-r border-[#ced4da] bg-[#f2ecef] py-2 px-3 text-[#808080]"
      >
        {label}
      </label>
      <input
        id={id}
        type="number"
        autoComplete="off"
        min="0"
        className="grow rounded-none !border-0 bg-white py-2 px-3 focus:outline-none"
        {...field}
        value={displayValue}
        onChange={(event) => changeDisplayValue(event.target.value)}
      />
      {displayValue && (
        <button
          type="button"
          onClick={(event) => {
            changeValue(undefined)
          }}
          className="my-2 mr-3 text-[#808080] underline decoration-dotted hover:no-underline"
        >
          clear
        </button>
      )}
      <select
        className="shrink rounded-r border-l border-[#ced4da] bg-white px-3 py-2 focus:outline-none"
        onChange={changeEra}
        value={era}
      >
        <option value="1">{gettext('CE')}</option>
        <option value="-1">{gettext('BCE')}</option>
      </select>
    </div>
  )
}
