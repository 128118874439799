import * as yup from 'yup'

import axios from './axios'

type TokenPair = {
  access: string
  refresh: string
}

const schema = yup
  .object()
  .shape({
    access: yup.string().required(),
    refresh: yup.string().required(),
  })
  .required()

export async function suLogin(targetUserId: number): Promise<TokenPair> {
  const { data } = await axios.post('/su/login', { su: targetUserId })

  return schema.validateSync(data)
}

export async function suLogout(): Promise<TokenPair> {
  const { data } = await axios.post('/su/logout')

  return schema.validateSync(data)
}
