import { ValuesType } from 'utility-types'

import { EntrySearchQuery } from 'graphql-generated/graphql'
import { useGetText } from 'lib/gettext'

import { EmptyResult } from 'apps/browse/common'
import { PrimaryButton, Spinner } from 'common'
import Entry from './entry'

type Entries = ReadonlyArray<
  NonNullable<
    NonNullable<
      ValuesType<
        NonNullable<
          NonNullable<EntrySearchQuery['entry_search_connection']>['edges']
        >
      >
    >['node']
  >
>

type SearchResultsProps = {
  className?: string
  query: string
  pageSize: number
  entries: Entries
  count: number
  loadNextPage: () => Promise<void>
  hasNextPage: boolean
  loadingNextPage: boolean
}

export default function SearchResults(props: SearchResultsProps) {
  const { ngettext, gettext } = useGetText()

  const {
    entries,
    count,
    query,
    hasNextPage,
    loadingNextPage,
    loadNextPage,
    pageSize,
  } = props

  if (count < 1) {
    return <EmptyResult />
  }

  return (
    <div className={props.className}>
      <p className="mb-4">
        {ngettext('%(count)i entry found', '%(count)i entries found', count)}
      </p>

      <div className="mb-8 grid grid-cols-2 gap-x-6 gap-y-12">
        {entries.map(({ id, ...entry }) => (
          <Entry key={id} entryRef={entry} query={query} />
        ))}
      </div>

      {loadingNextPage ? (
        <Spinner />
      ) : (
        hasNextPage && (
          <PrimaryButton
            className="ml-[50%] -translate-x-1/2"
            onClick={loadNextPage}
          >
            <>
              {gettext('Load another %(pageSize)i (out of %(count)i)', {
                pageSize:
                  entries.length + pageSize <= count
                    ? pageSize
                    : count - entries.length,
                count,
              })}
            </>
          </PrimaryButton>
        )
      )}
    </div>
  )
}
