import { PropsWithChildren } from 'react'
import classNames from 'classnames'

type Props = PropsWithChildren<{
  onClick: () => void
  disabled?: boolean
  hidden?: boolean
}>

export default function InlineButton({
  disabled = false,
  hidden = false,
  onClick,
  children,
}: Props) {
  return (
    <button
      className={classNames(
        'whitespace-nowrap text-primary underline decoration-dotted hover:decoration-solid',
        'disabled:cursor-default disabled:text-black/10 disabled:decoration-transparent',
        {
          hidden: hidden,
          inline: !hidden,
        }
      )}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  )
}
