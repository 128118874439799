import { isValid, parseJSON as parseJSONDate } from 'date-fns'

import { FragmentType, gql, useFragment } from 'graphql-generated'
import { EntryProgressFragment } from 'graphql-generated/graphql'
import { ValuesType } from 'utility-types'

const ENTRY_FRAGMENT = gql(`
  fragment EntryProgress on polls_entity {
    id
    name {
      name
    }
    created_by_id
    date_modified
    progress(where: {expert: { user_id: { _eq: $userId } }}) {
      total
      answered
      additional_total
      additional_answered
      published
      draft
    category_progress(order_by: { category: { order: asc } }) {
        category {
          name
        }
        total
        answered
        additional_total
        additional_answered
      }
      considered_answer_sets(where: {base_answer_set: {answer_sets: {published: {_eq: true}}}}, order_by: {base_answer_set: {answer_sets_aggregate: {max: {date_published: desc}}}}, limit: 1) {
        base_answer_set {
          answer_sets {
            date_published
          }
        }
      }
    }
  }
`)

type Progress = {
  questions: number
  answers: number
  percentage: number
}

type Stats = {
  drafts: number
  published: number
}

type Category = {
  name: string
  baseProgress: Progress
  additionalProgress: Progress
}

type Unpublished = { type: 'unpublished' }
type Published = { type: 'published'; datePublished: Date }
type Status = Unpublished | Published

export type Entry = {
  id: number
  name: string
  baseProgress: Progress
  additionalProgress: Progress
  categories: ReadonlyArray<Category>
  stats: Stats
  status: Status
  dateModified: Date
  creatorId: number
}

function makeProgress(questions: number, answers: number): Progress {
  const percentage = questions > 0 ? answers / questions : 0

  return { questions, answers, percentage }
}

function makeStatus(datePublished?: Date): Status {
  if (datePublished) {
    return { type: 'published', datePublished }
  }

  return { type: 'unpublished' }
}

export function isPublished(status: Status): status is Published {
  return status.type === 'published'
}

function makeDate(dateString: unknown): Date {
  const date = parseJSONDate(dateString as string)

  if (isValid(date)) {
    return date
  }

  throw new Error(`Failed to parse a date from "${dateString}"`)
}

function makeCategory(
  data: ValuesType<
    ValuesType<EntryProgressFragment['progress']>['category_progress']
  >
): Category {
  const baseProgress = makeProgress(data.total, data.answered)
  const additionalProgress = makeProgress(
    data.additional_total,
    data.additional_answered
  )

  return {
    name: data.category.name,
    baseProgress,
    additionalProgress,
  }
}

function makeDatePublished(dateString?: unknown): Date | undefined {
  if (typeof dateString !== 'undefined') {
    return makeDate(dateString)
  }
}

function makeEntry(fragment: EntryProgressFragment): Entry {
  const [progress] = fragment.progress

  const baseProgress = makeProgress(progress.total, progress.answered)

  const additionalProgress = makeProgress(
    progress.additional_total,
    progress.additional_answered
  )

  const categories = progress.category_progress.map(makeCategory)

  const stats = { drafts: progress.draft, published: progress.published }

  const datePublished = makeDatePublished(
    progress.considered_answer_sets[0]?.base_answer_set?.answer_sets[0]
      ?.date_published
  )

  const status = makeStatus(datePublished)

  return {
    id: fragment.id,
    name: fragment.name.name,
    baseProgress,
    additionalProgress,
    categories,
    stats,
    status,
    dateModified: makeDate(fragment.date_modified),
    creatorId: fragment.created_by_id,
  }
}

export type EntryProgressRef = FragmentType<typeof ENTRY_FRAGMENT>

export function useEntryProgress(ref: EntryProgressRef): Entry {
  const data = useFragment(ENTRY_FRAGMENT, ref)

  return makeEntry(data)
}
