import * as yup from 'yup'

import { gql } from 'graphql-generated'
import { gqlRequest } from 'api'

import { EntryForUpgradeQueryQuery } from 'graphql-generated/graphql'
import { Params, useLoaderData } from 'react-router-dom'
import { StatusCodes } from 'http-status-codes'

const ENTRY_FOR_UPGRADE_QUERY = gql(`
  query EntryForUpgradeQuery($entryId: Int!) {
    polls_entity_by_pk(id: $entryId) {
      id
      name {
        name
      }
      poll {
        next {
          id
        }
      }
    }
  }
`)

type EntryForUpgrade = {
  id: number
  name: string
  nextPollId: number
}

function mapEntryFromQuery(
  data: NonNullable<EntryForUpgradeQueryQuery['polls_entity_by_pk']>
): EntryForUpgrade {
  const nextPollId = data?.poll?.next?.id

  if (!nextPollId) {
    throw new Error('Entry has no next poll')
  }

  return {
    id: data.id,
    name: data.name.name,
    nextPollId,
  }
}

async function loadEntryForUpgrade(entryId: number): Promise<EntryForUpgrade> {
  const data = await gqlRequest(ENTRY_FOR_UPGRADE_QUERY, { entryId })

  if (data && data['polls_entity_by_pk']) {
    return mapEntryFromQuery(data.polls_entity_by_pk)
  }

  throw new Response('', { status: StatusCodes.NOT_FOUND })
}

export function entryForUpgradeLoader(options: {
  params: Params<string>
}): Promise<EntryForUpgrade> {
  const entryId = Number(options.params?.entryId)

  if (isNaN(entryId)) {
    throw new Error('Invalid entryId')
  }

  return loadEntryForUpgrade(entryId)
}

const entryForUpgradeSchema = yup
  .object()
  .shape({
    id: yup.number().required(),
    name: yup.string().required(),
    nextPollId: yup.number().required(),
  })
  .required()

export function useEntryForUpgrade(): EntryForUpgrade {
  return entryForUpgradeSchema.validateSync(useLoaderData())
}
