import { gettext_noop, useGetText } from 'lib/gettext'
import SortingControl, {
  getSortingQueryFromSearchString,
  useSortingQuery,
} from './sorting-control'

type SortingFields = undefined | 'name' | 'year_from' | 'year_to'

export const ENTRY_SEARCH_QUERY_NAME = 'orderBy'

const SORTING_EXPRESSION: {
  [key in 'relevance' | 'name' | 'periodStart' | 'periodEnd']: SortingFields
} = {
  relevance: undefined,
  name: 'name',
  periodStart: 'year_from',
  periodEnd: 'year_to',
}

const DEFAULT_SORTING: keyof typeof SORTING_EXPRESSION = 'relevance'

const OPTIONS: ReadonlyArray<{
  value: keyof typeof SORTING_EXPRESSION
  label: string
}> = [
  {
    value: 'relevance',
    label: gettext_noop('relevance'),
  },
  {
    value: 'name',
    label: gettext_noop('name'),
  },
  { value: 'periodStart', label: gettext_noop('from date') },
  { value: 'periodEnd', label: gettext_noop('to date') },
]

export function EntrySearchSortingControl() {
  const { gettext } = useGetText()

  const options = OPTIONS.map(({ value, label }) => ({
    value,
    label: gettext(label),
  }))

  return (
    <SortingControl
      options={options}
      defaultValue={DEFAULT_SORTING}
      name={ENTRY_SEARCH_QUERY_NAME}
    />
  )
}

function getEntrySearchSortingQuery(
  url: URL
): keyof typeof SORTING_EXPRESSION | undefined {
  return getSortingQueryFromSearchString(
    url.search,
    ENTRY_SEARCH_QUERY_NAME,
    OPTIONS.map(({ value }) => value)
  )
}

export function useEntrySearchSortingQuery():
  | keyof typeof SORTING_EXPRESSION
  | undefined {
  return useSortingQuery(
    ENTRY_SEARCH_QUERY_NAME,
    OPTIONS.map(({ value }) => value)
  )
}

export function useEntrySearchSorting(): SortingFields {
  const queryParam = useEntrySearchSortingQuery()

  return SORTING_EXPRESSION[queryParam || 'name']
}

export function getEntrySearchSorting(url: URL): SortingFields {
  const queryParam = getEntrySearchSortingQuery(url)

  return SORTING_EXPRESSION[queryParam || 'name']
}
