import classNames from 'classnames'
import { NavLink, NavLinkProps } from 'react-router-dom'

type LinkNativeProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

type ButtonNativeProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type NavLinkNativeProps = NavLinkProps & React.RefAttributes<HTMLAnchorElement>

type ButtonCustomProps<T> = {
  color?:
    | 'default'
    | 'muted'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
  size?: 'sm' | 'md' | 'lg'
  outline?: boolean
} & T

type ButtonProps =
  | ButtonCustomProps<{ type: 'link' } & LinkNativeProps>
  | ButtonCustomProps<{ type: 'router-link' } & NavLinkNativeProps>
  | ButtonCustomProps<ButtonNativeProps>

const OUTLINE = 'ring-1 ring-inset hover:text-white'

const DEFAULT_COLOR =
  'bg-default hover:bg-default-dark active:bg-default disabled:bg-default-light/25'

const DEFAULT_OUTLINE_COLOR =
  'text-default ring-default hover:bg-default active:bg-default-dark active:ring-0 disabled:text-default-light/25 disabled:ring-default-light/25'

const MUTED_COLOR =
  'bg-muted hover:bg-muted-dark active:bg-muted disabled:bg-muted-light/25'

const MUTED_OUTLINE_COLOR =
  'text-muted ring-muted hover:bg-muted active:bg-muted-dark active:ring-0 disabled:text-muted-light/25 disabled:ring-muted-light/25'

const PRIMARY_COLOR =
  'bg-primary hover:bg-primary-dark active:bg-primary disabled:bg-primary-light/25'

const PRIMARY_OUTLINE_COLOR =
  'text-primary ring-primary hover:bg-primary active:bg-primary-dark active:ring-0 disabled:text-primary-light/25 disabled:ring-primary-light/25'

const SECONDARY_COLOR =
  'bg-secondary hover:bg-secondary-dark active:bg-secondary disabled:bg-secondary-light/25'

const SECONDARY_OUTLINE_COLOR =
  'text-secondary ring-secondary hover:bg-secondary active:bg-secondary-dark active:ring-0 disabled:text-secondary-light/25 disabled:ring-secondary-light/25'

const SUCCESS_COLOR =
  'bg-success hover:bg-success-dark active:bg-success disabled:bg-success-light/50'

const SUCCESS_OUTLINE_COLOR =
  'text-success ring-success hover:bg-success active:bg-success-dark active:ring-0 disabled:text-success-light/25 disabled:ring-success-light/25'

const WARNING_COLOR =
  'bg-warning hover:bg-warning-dark active:bg-warning disabled:bg-warning-light/25'

const WARNING_OUTLINE_COLOR =
  'text-warning ring-warning hover:bg-warning active:bg-warning-dark active:ring-0 disabled:text-warning-light/25 disabled:ring-warning-light/25'

const DANGER_COLOR =
  'bg-danger hover:bg-danger-dark active:bg-danger disabled:bg-danger-light/25'

const DANGER_OUTLINE_COLOR =
  'text-danger ring-danger hover:bg-danger active:bg-danger-dark active:ring-0 disabled:text-danger-light/25 disabled:ring-danger-light/25'

export default function Button(props: ButtonProps) {
  const { color = 'default', outline = false, size = 'md', ...rest } = props

  const className = classNames(
    'inline-block cursor-pointer whitespace-nowrap rounded-md uppercase tracking-widest text-white transition-colors disabled:pointer-events-none disabled:cursor-default',
    {
      'py-4 px-5': size === 'md',
      'py-6 px-7 text-lg': size === 'lg',
      'py-2 px-4 text-xs': size === 'sm',
      [OUTLINE]: outline,
      [DEFAULT_COLOR]: color === 'default' && !outline,
      [DEFAULT_OUTLINE_COLOR]: color === 'default' && outline,
      [MUTED_COLOR]: color === 'muted' && !outline,
      [MUTED_OUTLINE_COLOR]: color === 'muted' && outline,
      [PRIMARY_COLOR]: color === 'primary' && !outline,
      [PRIMARY_OUTLINE_COLOR]: color === 'primary' && outline,
      [SECONDARY_COLOR]: color === 'secondary' && !outline,
      [SECONDARY_OUTLINE_COLOR]: color === 'secondary' && outline,
      [SUCCESS_COLOR]: color === 'success' && !outline,
      [SUCCESS_OUTLINE_COLOR]: color === 'success' && outline,
      [WARNING_COLOR]: color === 'warning' && !outline,
      [WARNING_OUTLINE_COLOR]: color === 'warning' && outline,
      [DANGER_COLOR]: color === 'danger' && !outline,
      [DANGER_OUTLINE_COLOR]: color === 'danger' && outline,
    }
  )

  switch (rest.type) {
    case 'link': {
      const { className: linkClassName, children, ...linkProps } = rest

      return (
        <a {...linkProps} className={classNames(className, linkClassName)}>
          {children}
        </a>
      )
    }
    case 'router-link': {
      const { className: routerLinkClassName, ...routerLinkProps } = rest

      const classNameFn: NavLinkProps['className'] = (state) =>
        classNames(
          className,
          typeof routerLinkClassName === 'function'
            ? routerLinkClassName(state)
            : routerLinkClassName
        )

      return <NavLink {...routerLinkProps} className={classNameFn} />
    }
    default: {
      const { className: buttonClassNameExtend, type, ...buttonProps } = rest

      return (
        <button
          {...buttonProps}
          className={classNames(className, buttonClassNameExtend)}
          type={type || 'button'}
        />
      )
    }
  }
}

type PrimaryButtonProps =
  | ButtonCustomProps<{ type: 'link' } & LinkNativeProps>
  | ButtonCustomProps<{ type: 'router-link' } & NavLinkNativeProps>
  | ButtonCustomProps<ButtonNativeProps>

export function PrimaryButton(props: PrimaryButtonProps) {
  const { color = 'primary', ...rest } = props

  return <Button {...rest} color={color} />
}

type SubmitButtonProps = Omit<ButtonCustomProps<ButtonNativeProps>, 'type'>

export function SubmitButton(props: SubmitButtonProps) {
  const { color = 'success', ...rest } = props

  return <Button {...rest} color={color} type="submit" />
}
