import { useQuery } from '@apollo/client'

import { gql } from 'graphql-generated'
import { expertId, useAuthenticatedUser } from 'auth'
import { CommentedEntriesQuery } from 'graphql-generated/graphql'
import { useGetText } from 'lib/gettext'
import {
  Spinner,
  EntryNavLink,
  Timestamp,
  TableHeadRow,
  TableHeadCell,
  TableBodyRow,
  TableBodyCell,
} from 'common'

export default function CommentedEntries() {
  const { gettext, ngettext } = useGetText()

  const { data, loading } = useCommentedEntries()

  return (
    <section>
      <h3 className="mb-4 text-3xl font-semibold">{gettext('My Comments')}</h3>

      {loading ? (
        <Spinner className="my-6" />
      ) : data && data.length > 0 ? (
        <table className="w-full">
          <thead>
            <TableHeadRow>
              <TableHeadCell>{gettext('Entry name')}</TableHeadCell>
              <TableHeadCell>{gettext('Comments')}</TableHeadCell>
              <TableHeadCell>{gettext('Date modified')}</TableHeadCell>
            </TableHeadRow>
          </thead>
          <tbody className="text-neutral-600">
            {data.map((entry) => (
              <TableBodyRow key={entry.id}>
                <TableBodyCell>
                  <EntryNavLink
                    className="text-lg font-semibold leading-5"
                    id={entry.id}
                    name={entry.name}
                  />
                </TableBodyCell>

                <TableBodyCell>
                  <ul>
                    <li>
                      {ngettext(
                        '%(count)i Affirmation',
                        '%(count)i Affirmations',
                        entry.affirmations
                      )}
                    </li>
                    <li>
                      {ngettext(
                        '%(count)i Challenge',
                        '%(count)i Challenges',
                        entry.challenges
                      )}
                    </li>
                    <li>
                      {ngettext(
                        '%(count)i Neutral',
                        '%(count)i Neutral',
                        entry.neutral
                      )}
                    </li>
                  </ul>
                </TableBodyCell>

                <TableBodyCell>
                  <Timestamp date={entry.dateModified} />
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </tbody>
        </table>
      ) : null}
    </section>
  )
}

function useCommentedEntries() {
  const user = useAuthenticatedUser()

  const { loading, data } = useQuery(COMMENTED_ENTRIES_QUERY, {
    variables: { expertId: expertId(user) },
    fetchPolicy: 'cache-and-network',
  })

  return {
    loading,
    data: data && makeCommentedEntriesFromQueryData(data.polls_entity),
  }
}

function makeCommentedEntriesFromQueryData(
  data: CommentedEntriesQuery['polls_entity']
) {
  return data.map(
    ({
      id,
      name: { name },
      date_modified,
      affirmations,
      challenges,
      neutral,
    }) => ({
      id,
      name,
      dateModified: new Date(date_modified),
      affirmations: affirmations.aggregate?.count || 0,
      challenges: challenges.aggregate?.count || 0,
      neutral: neutral.aggregate?.count || 0,
    })
  )
}

const COMMENTED_ENTRIES_QUERY = gql(`
  query CommentedEntries($expertId: Int!) {
    polls_entity(where: {base_answer_sets: {comment: {base_answer_set: {expert_id: {_eq: $expertId}}}}}, order_by: {date_modified: desc}) {
      id
      name {
        name
      }
      date_modified
      affirmations: base_answer_sets_aggregate(where: {comment: {base_answer_set: {expert_id: {_eq: $expertId}}, comment_type: {_eq: 1}}}) {
        aggregate {
          count
        }
      }
      challenges: base_answer_sets_aggregate(where: {comment: {base_answer_set: {expert_id: {_eq: $expertId}}, comment_type: {_eq: 2}}}) {
        aggregate {
          count
        }
      }
      neutral: base_answer_sets_aggregate(where: {comment: {base_answer_set: {expert_id: {_eq: $expertId}}, comment_type: {_eq: 3}}}) {
        aggregate {
          count
        }
      }
    }
  }
`)
