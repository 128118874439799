import { ReactNode } from 'react'

import { useCurrentUser, isAuthenticated, AuthenticatedUser } from 'auth'

type AuthorizedFragmentProps = {
  authorize?: (user: AuthenticatedUser) => boolean
  fallback?: ReactNode
  children: ReactNode | ((user: AuthenticatedUser) => ReactNode)
}

export default function AuthorizedFragment(props: AuthorizedFragmentProps) {
  const user = useCurrentUser()
  const { authorize, fallback, children } = props

  if (
    isAuthenticated(user) &&
    (typeof authorize === 'undefined' || authorize(user))
  ) {
    return <>{typeof children === 'function' ? children(user) : children}</>
  }

  return <>{fallback || null}</>
}
