import { memo, useState } from 'react'
import Highlighter from 'react-highlight-words'
import classNames from 'classnames'

import { tokenize, truncateText } from 'legacy/libs/utils'

import { GetText, useGetText } from 'lib/gettext'
import { FlexSpinner, Spinner } from 'common'

import { getAnswerSetPath, getQuestionPath } from './api'
import { EntryPollSearchParams } from './params'
import useSuggestions, {
  QuestionSuggestion,
  AnswerSetSuggestion,
} from './use-suggestions'

type SuggestionsProps = {
  query: string
  params: EntryPollSearchParams
  visible: boolean
  onQuestionPathLoad: (questionPath: any) => void
  onAnswerSetPathLoad: (answerSetPath: any) => void
}

const Suggestions = memo(function Suggestions(props: SuggestionsProps) {
  const { gettext } = useGetText()

  const { query, params, visible, onQuestionPathLoad, onAnswerSetPathLoad } =
    props

  const suggestions = useSuggestions(query, params)

  return (
    <div
      className={classNames(
        'absolute top-[calc(100%_+_2px)] left-0 right-0 z-10 max-h-[26rem] space-y-4 overflow-y-scroll rounded bg-white py-4 text-sm text-default shadow-md',
        { hidden: !visible, block: visible }
      )}
    >
      {suggestions.loading ? (
        <FlexSpinner size="sm" />
      ) : suggestions.data.questions !== null ||
        suggestions.data.answerSets !== null ? (
        <>
          {suggestions.data.questions && (
            <QuestionSuggestions
              suggestions={suggestions.data.questions}
              query={query}
              params={params}
              onPathLoad={onQuestionPathLoad}
            />
          )}

          {suggestions.data.answerSets && (
            <AnswerSetSuggestions
              suggestions={suggestions.data.answerSets}
              query={query}
              params={params}
              onPathLoad={onAnswerSetPathLoad}
            />
          )}
        </>
      ) : (
        <p className="px-10 text-lg font-light text-secondary">
          {gettext('No matches found')}
        </p>
      )}
    </div>
  )
})

export default Suggestions

type QuestionSuggestionsProps = {
  suggestions: QuestionSuggestion[]
  query: string
  params: EntryPollSearchParams
  onPathLoad: (questionPath: any) => void
}

function QuestionSuggestions(props: QuestionSuggestionsProps) {
  const { gettext } = useGetText()

  const { suggestions, query, params, onPathLoad } = props

  const MAX_INITIAL_ITEM_COUNT = 8

  const [loadingPathId, setLoadingPathId] = useState<number | null>(null)

  const [allSuggestionsAreVisible, setAllSuggestionVisibility] =
    useState<boolean>(false)
  const toggleAllSuggestionsVisibility = () =>
    setAllSuggestionVisibility((prev) => !prev)

  const handleSuggestionClick = (questionId: number) => {
    setLoadingPathId(questionId)

    getQuestionPath(
      questionId,
      params.entryId,
      params.expertIds,
      params.filter
    ).then((questionPath: any) => {
      setLoadingPathId(null)
      onPathLoad(questionPath)
    })
  }

  return (
    <div className="relative">
      <p className="absolute left-4 py-1 text-xs uppercase leading-5 tracking-widest text-secondary">
        {gettext('Questions')}
      </p>

      <ul>
        {suggestions.map((suggestion, index) => {
          const pathIsLoading = loadingPathId === suggestion.id

          return (
            <li
              className={classNames({
                hidden:
                  index + 1 > MAX_INITIAL_ITEM_COUNT &&
                  !allSuggestionsAreVisible,
              })}
              key={`${suggestion.id}_${index}`}
            >
              <button
                className="relative block w-full py-1 pl-32 pr-10 text-left enabled:hover:bg-primary-lightest/20 disabled:text-secondary"
                onClick={() => handleSuggestionClick(suggestion.id)}
                type="button"
                disabled={pathIsLoading}
              >
                <Highlighter
                  highlightClassName="font-bold bg-transparent"
                  searchWords={tokenize(query)}
                  textToHighlight={suggestion.text}
                />
                {pathIsLoading && (
                  <Spinner
                    className="absolute top-1/2 right-4 -mt-2 inline-block"
                    size="xxs"
                  />
                )}
              </button>
            </li>
          )
        })}
      </ul>

      {suggestions.length > MAX_INITIAL_ITEM_COUNT && (
        <button
          className="link ml-32 py-1 decoration-primary decoration-dotted hover:decoration-solid"
          onClick={toggleAllSuggestionsVisibility}
          type="button"
        >
          {allSuggestionsAreVisible
            ? gettext('Show less questions')
            : gettext('Show all questions (%(count)s)', {
                count: suggestions.length,
              })}
        </button>
      )}
    </div>
  )
}

type AnswerSetSuggestionsProps = {
  suggestions: AnswerSetSuggestion[]
  query: string
  params: EntryPollSearchParams
  onPathLoad: (answerSetPath: any) => void
}

function AnswerSetSuggestions(props: AnswerSetSuggestionsProps) {
  const { gettext } = useGetText()

  const { suggestions, query, params, onPathLoad } = props

  const MAX_INITIAL_ITEM_COUNT = 2

  const [loadingPathId, setLoadingPathId] = useState<number | null>(null)

  const [allSuggestionsAreVisible, setAllSuggestionVisibility] =
    useState<boolean>(false)
  const toggleAllSuggestionsVisibility = () =>
    setAllSuggestionVisibility((prev) => !prev)

  const handleSuggestionClick = (answerSetId: number) => {
    setLoadingPathId(answerSetId)

    getAnswerSetPath(answerSetId, params.expertIds, params.filter).then(
      (answerSetPath: any) => {
        setLoadingPathId(null)
        onPathLoad(answerSetPath)
      }
    )
  }

  return (
    <div className="relative">
      <p className="absolute left-4 py-1 text-xs uppercase leading-5 tracking-widest text-secondary">
        {gettext('Answers')}
      </p>

      <ul>
        {suggestions.map((suggestion, index) => {
          const pathIsLoading = loadingPathId === suggestion.id

          return (
            <li
              className={classNames({
                hidden:
                  index + 1 > MAX_INITIAL_ITEM_COUNT &&
                  !allSuggestionsAreVisible,
              })}
              key={`${suggestion.question.id}_${suggestion.id}`}
            >
              <button
                className="relative block w-full py-1 pl-32 pr-10 text-left enabled:hover:bg-primary-lightest/20 disabled:text-secondary"
                onClick={() => handleSuggestionClick(suggestion.id)}
                type="button"
                disabled={pathIsLoading}
              >
                <Highlighter
                  highlightClassName="font-bold bg-transparent"
                  searchWords={tokenize(query)}
                  textToHighlight={truncateText(suggestion.notes, query)}
                />{' '}
                <span className="block text-xs text-secondary">
                  <GetText
                    components={{
                      questionText: (
                        <span className="text-default">
                          "{suggestion.question.text}"
                        </span>
                      ),
                    }}
                  >
                    from the answer to [questionText/]
                  </GetText>
                </span>
                {pathIsLoading && (
                  <Spinner
                    className="absolute top-1/2 right-4 -mt-2 inline-block"
                    size="xxs"
                  />
                )}
              </button>
            </li>
          )
        })}
      </ul>

      {suggestions.length > MAX_INITIAL_ITEM_COUNT && (
        <button
          className="link ml-32 py-1 decoration-primary decoration-dotted hover:decoration-solid"
          onClick={toggleAllSuggestionsVisibility}
          type="button"
        >
          {allSuggestionsAreVisible
            ? gettext('Show less answers')
            : gettext('Show all answers (%(count)s)', {
                count: suggestions.length,
              })}
        </button>
      )}
    </div>
  )
}
