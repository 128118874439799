import * as yup from 'yup'
import axios, { AxiosResponse } from 'axios'

import apiAxios from './axios'

const UPLOAD_URL_SCHEMA = yup
  .object()
  .shape({
    url: yup.string().required(),
    fileId: yup.string().required(),
  })
  .required()

type UploadUrl = yup.InferType<typeof UPLOAD_URL_SCHEMA>

async function makeUploadUrl(
  fileName: string,
  signal: AbortSignal
): Promise<UploadUrl> {
  const { data } = await apiAxios.post(
    '/user-files/presigned-url',
    {
      name: fileName,
    },
    { signal }
  )

  return UPLOAD_URL_SCHEMA.validateSync(data)
}

export async function uploadFile(
  file: File,
  signal: AbortSignal,
  onUploadProgress?: (progress: number) => void
): Promise<AxiosResponse<unknown, unknown>> {
  const { url } = await makeUploadUrl(file.name, signal)

  return axios.put(url, file, {
    signal,
    headers: { 'Content-Type': file.type },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = progressEvent.total
        ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
        : 0

      onUploadProgress && onUploadProgress(percentCompleted)
    },
  })
}
