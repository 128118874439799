import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntrySourceBox } from 'apps/browse/common'
import ExpertLink from './expert-link'

const SOURCE_FRAGMENT = gql(`
  fragment EntrySearch_Entry_Source on Entry {
    entry_source
    secondary_source
    expert_source {
      ...EntrySearch_Entry_ExpertLink
    }
    supervised_by {
      ...EntrySearch_Entry_ExpertLink
    }
  }
`)

type EntrySourceProps = {
  entryRef: FragmentType<typeof SOURCE_FRAGMENT>
  query: string
}

export default function EntrySource(props: EntrySourceProps) {
  const {
    expert_source: expertSource,
    supervised_by: supervisor,
    entry_source: entrySource,
    secondary_source: secondarySource,
  } = useFragment(SOURCE_FRAGMENT, props.entryRef)
  const { query } = props

  const expertSourceRenderer = () =>
    expertSource && <ExpertLink query={query} userRef={expertSource} />

  const supervisorRenderer = () =>
    supervisor && <ExpertLink query={query} userRef={supervisor} />

  return (
    <EntrySourceBox
      entrySource={entrySource}
      secondarySource={secondarySource}
      expertSourceLinkRenderer={expertSourceRenderer}
      supervisorLinkRenderer={supervisorRenderer}
    />
  )
}
