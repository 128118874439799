import axios from 'api/axios'
import { EntryPollFilter, EntryPollSearchParams } from './params'

// API

function searchQuestions(
  query: string,
  params: EntryPollSearchParams,
  abortSignal: AbortSignal
) {
  return axios
    .get('/polls/question_search', {
      params: generateSearchRequestParams(query, params),
      signal: abortSignal,
    })
    .then((response) => response.data)
}

function searchAnswerSets(
  query: string,
  params: EntryPollSearchParams,
  abortSignal: AbortSignal
) {
  return axios
    .get('/polls/answerset_search', {
      params: generateSearchRequestParams(query, params),
      signal: abortSignal,
    })
    .then((response) => response.data)
}

export default function searchEntryPoll(
  query: string,
  params: EntryPollSearchParams,
  abortSignal: AbortSignal
) {
  return Promise.all([
    searchQuestions(query, params, abortSignal),
    searchAnswerSets(query, params, abortSignal),
  ])
}

export function getQuestionPath(
  id: number,
  entryId: number,
  expertIds: number[],
  filter: EntryPollFilter
) {
  return axios
    .get(`/polls/question/${id}/path/`, {
      params: {
        __context: JSON.stringify({
          entry: entryId,
          published: generatePublishedParam(filter),
          experts: expertIds.join(','),
        }),
      },
    })
    .then((response) => response.data)
}

export function getAnswerSetPath(
  id: number,
  expertIds: number[],
  filter: EntryPollFilter
) {
  return axios
    .get(`/polls/answerset/${id}/path/`, {
      params: {
        published: generatePublishedParam(filter),
        experts: expertIds.join(','),
      },
    })
    .then((response) => response.data)
}

// HELPER FUNCTIONS

function generateSearchRequestParams(
  query: string,
  params: EntryPollSearchParams
) {
  return {
    search: query,
    __context: JSON.stringify({
      poll: params.pollId,
      entry: params.entryId,
      experts: params.expertIds,
      published: generatePublishedParam(params.filter),
    }),
  }
}

function generatePublishedParam(filter: EntryPollFilter) {
  return filter.published !== filter.draft ? filter.published : null
}
