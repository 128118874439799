import queryString, { StringifiableRecord } from 'query-string'
import { Location } from 'react-router-dom'

export default function updateQueryString(
  location: Location,
  data: StringifiableRecord,
  names: string[]
): string {
  const currentQuery = queryString.parse(
    queryString.exclude(location.search, names),
    {
      parseNumbers: true,
      arrayFormatSeparator: ',',
      arrayFormat: 'separator',
    }
  )

  return queryString.stringifyUrl(
    { url: location.pathname, query: { ...currentQuery, ...data } },
    { arrayFormatSeparator: ',', arrayFormat: 'separator' }
  )
}
