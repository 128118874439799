import { Outlet, Navigate } from 'react-router-dom'
import { ReactNode } from 'react'

import { useCurrentUser, isAuthenticated, AuthenticatedUser } from 'auth'
import { ForbiddenError } from './error-pages'

type ProtectedRouteProps = {
  authorize?: (user: AuthenticatedUser) => boolean
  fallback?: ReactNode
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { authorize, fallback } = props
  const user = useCurrentUser()

  if (isAuthenticated(user)) {
    return authorize === undefined || authorize(user) ? (
      <Outlet />
    ) : fallback ? (
      <>{fallback}</>
    ) : (
      <ForbiddenError />
    )
  }

  return <Navigate to="/login" replace />
}
