import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

import DropdownSelect from './dropdown-select'
import updateQueryString from './update-query-string'

function useSortingQueryUpdate<T extends string>(
  name: string,
  defaultValue: T
) {
  const navigate = useNavigate()
  const location = useLocation()
  const update = useCallback(
    (value: T) => {
      const query = value === defaultValue ? {} : { [name]: value }

      const url = updateQueryString(location, query, [name])

      navigate(url)
    },
    [defaultValue, name, location, navigate]
  )

  return update
}

export function getSortingQueryFromSearchString<T extends string>(
  search: string,
  name: string,
  options: ReadonlyArray<T>
): T | undefined {
  const { [name]: value } = queryString.parse(queryString.pick(search, [name]))

  return typeof value === 'string' && options.find((o) => o === value)
    ? (value as T)
    : undefined
}

export function useSortingQuery<T extends string>(
  name: string,
  options: ReadonlyArray<T>
): T | undefined {
  const location = useLocation()

  return getSortingQueryFromSearchString(location.search, name, options)
}

export function useSortingQueryString<T extends string>(params: {
  options: ReadonlyArray<T>
  name: string
  defaultValue: string
}): string | undefined {
  const { options, name, defaultValue } = params
  const value = useSortingQuery(name, options)

  if (value === defaultValue) {
    return undefined
  }

  return queryString.stringify({ [name]: value })
}

type SortingControlOption<T extends string> = {
  value: T
  label: string
}

type SortingControlProps<T extends string> = {
  name: string
  defaultValue: T
  options: ReadonlyArray<SortingControlOption<T>>
}

export default function SortingControl<T extends string>(
  props: SortingControlProps<T>
) {
  const value = useSortingQuery(
    props.name,
    props.options.map(({ value }) => value)
  )

  const updateQuery = useSortingQueryUpdate(props.name, props.defaultValue)

  return (
    <DropdownSelect
      options={props.options}
      defaultValue={value || props.defaultValue}
      onChange={updateQuery}
    />
  )
}
