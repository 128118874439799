import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

import { gql } from 'graphql-generated'
import classNames from 'classnames'
import { CSSProperties } from 'react'
import Spinner from './spinner'

const AVATAR_QUERY = gql(`
  query Avatar($userId: Int!, $size: Int) {
    avatar(userId: $userId, size: $size) {
      url
    }
  }
`)

type AvatarProps = {
  size: number
  userId: number | string
  alt?: string
  className?: string
}

function makeCssSize(size: number): CSSProperties {
  return { width: size, height: size }
}

export default function Avatar(props: AvatarProps) {
  const { userId, size } = props
  const cssSize = makeCssSize(size)

  const { loading, data } = useQuery(AVATAR_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { userId: Number(userId), size },
  })

  const url = data?.avatar?.url || undefined

  return (
    <div style={cssSize} className={props.className}>
      {loading ? (
        <Spinner style={cssSize} />
      ) : url ? (
        <img
          style={cssSize}
          className={classNames('rounded-full')}
          alt={props.alt}
          src={url}
        />
      ) : (
        <FontAwesomeIcon
          icon={faUserCircle}
          className="text-neutral-300"
          style={cssSize}
        />
      )}
    </div>
  )
}
