import { FormEventHandler, useCallback, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetText } from 'lib/gettext'
import { PrimaryButton } from 'common'
import { updateQueryString } from '../common'

function useInitialQuery(): string {
  const location = useLocation()
  const query = queryString.parse(location.search)['q']

  return (Array.isArray(query) ? query[0] : query) || ''
}

export default function SearchForm() {
  const { gettext } = useGetText()
  const initialQuery = useInitialQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const [query, setQuery] = useState(initialQuery)

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault()
      const [, searchQuery] = updateQueryString(location, { q: query }, [
        'q',
      ]).split('?')

      navigate('/browse/search?' + searchQuery, {
        state: { page: 1 },
        replace: true,
      })
    },
    [location, navigate, query]
  )

  const queryIsEmpty = query === ''

  return (
    <form className="mb-6 flex" onSubmit={onSubmit}>
      <div className="relative mr-3 grow">
        <input
          name="q"
          type="text"
          className="block w-full rounded-md border border-gray-300 py-4 pl-11 pr-5 transition-colors"
          placeholder={gettext(
            'Search entries by name, author, region, religious group…'
          )}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        <svg
          className="absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 fill-gray-drh"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M505 442.7 389.4 327.1l-6.5-6.5c21-32.4 33.1-71.1 33.1-112.6C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c41.5 0 80.2-12.1 112.6-33.1L442.7 505c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
        </svg>

        <button
          className="absolute top-1/2 right-3 z-10 -translate-y-1/2 p-1 disabled:hidden"
          onClick={() => setQuery('')}
          disabled={queryIsEmpty}
          type="button"
        >
          <span className="inline-block h-[1.5em] w-[1.5em] rounded-full bg-primary text-white">
            &#10005;
          </span>
        </button>
      </div>

      <PrimaryButton
        type="submit"
        className="uppercase"
        disabled={queryIsEmpty}
      >
        {gettext('Search')}
      </PrimaryButton>
    </form>
  )
}
