import { gql, FragmentType, useFragment } from 'graphql-generated'

import { EntryPollTypeBox } from 'apps/browse/common'

const ENTRY_POLL_TYPE_FRAGMENT = gql(`
  fragment EntryPollType on polls_entity {
    poll {
      type
    }
  }
`)

type EntryPollTypeFragment = FragmentType<typeof ENTRY_POLL_TYPE_FRAGMENT>

type EntryPollTypeProps = {
  entryRef: EntryPollTypeFragment
}

export default function EntryPollType(props: EntryPollTypeProps) {
  const {
    poll: { type },
  } = useFragment(ENTRY_POLL_TYPE_FRAGMENT, props.entryRef)

  return <EntryPollTypeBox type={type} />
}
