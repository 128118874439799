import React from 'react'
import classNames from 'classnames'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { inputSize?: 'regular' | 'small'; isInvalid?: boolean }

export const Input = React.forwardRef<HTMLInputElement | null, InputProps>(
  (props, ref) => {
    const { className, inputSize = 'regular', isInvalid, ...rest } = props

    return (
      <input
        ref={ref}
        className={classNames(
          'rounded border border-black/25 leading-5 transition focus:border-primary focus:shadow-[0_0_1px_4px] focus:shadow-primary/10 focus-visible:outline-none',
          {
            'py-3 px-4': inputSize === 'regular',
            'py-1 px-2': inputSize === 'small',
            '!z-10 border-red-600 focus:border-red-600 focus:shadow-red-600/10':
              isInvalid,
          },
          className
        )}
        {...rest}
      />
    )
  }
)

type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & { isInvalid?: boolean }

export const TextArea = React.forwardRef<
  HTMLTextAreaElement | null,
  TextAreaProps
>((props, ref) => {
  const { className, isInvalid, ...rest } = props

  return (
    <textarea
      ref={ref}
      className={classNames(
        'rounded border border-black/25 py-3 px-4 leading-5 transition focus:border-primary focus:shadow-[0_0_1px_4px] focus:shadow-primary/10 focus-visible:outline-none',
        {
          'border-red-600 focus:border-red-600 focus:shadow-red-600/10':
            isInvalid,
        },
        className
      )}
      {...rest}
    />
  )
})

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & { selectSize?: 'regular' | 'small'; isInvalid?: boolean }

export const Select = React.forwardRef<HTMLSelectElement | null, SelectProps>(
  (props, ref) => {
    const {
      className,
      isInvalid,
      selectSize = 'regular',
      children,
      ...rest
    } = props

    return (
      <select
        ref={ref}
        className={classNames(
          'appearance-none rounded border border-black/25 bg-no-repeat leading-5 transition focus:border-primary focus:shadow-[0_0_1px_4px] focus:shadow-primary/10 focus:outline-none enabled:bg-small-arrow-down',
          'disabled:border-black/25 disabled:bg-black/5 disabled:opacity-100',
          {
            'bg-[right_1rem_center] py-3 pl-4 pr-8': selectSize === 'regular',
            'bg-[right_0.5rem_center] py-1 pl-2 pr-6': selectSize === 'small',
            'border-red-600 focus:border-red-600 focus:shadow-red-600/10':
              isInvalid,
          },
          className
        )}
        {...rest}
      >
        {children}
      </select>
    )
  }
)
