import { useQuery } from '@tanstack/react-query'
import { InferType, array, number, object, string } from 'yup'

import searchEntryPoll from './api'
import { EntryPollSearchParams } from './params'

type EntryPollSearchResults = {
  questions: QuestionSuggestion[] | null
  answerSets: AnswerSetSuggestion[] | null
}

type UseSuggestion =
  | { loading: true }
  | { loading: false; data: EntryPollSearchResults }

export default function useSuggestions(
  query: string,
  params: EntryPollSearchParams
): UseSuggestion {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['suggestions', query, params],
    queryFn: ({ signal }) => searchEntryPoll(query, params, signal),
  })

  if (isError) throw error

  if (isPending) return { loading: true }

  return { loading: false, data: makeData(data) }
}

function makeData([questions, answerSets]: [any, any]): EntryPollSearchResults {
  const validQuestions = questionSuggestionsSchema.validateSync(questions)
  const validAnswerSets = answerSetSuggestionsSchema.validateSync(answerSets)

  return {
    questions:
      validQuestions.length > 0 ? validQuestions.map(makeQuestion) : null,
    answerSets:
      validAnswerSets.length > 0 ? makeAnswerSets(validAnswerSets) : null,
  }
}

// SCHEMAS

const questionSchema = object({
  pk: number().required(),
  name: string().required(),
}).required()
type Question = InferType<typeof questionSchema>

const questionSuggestionsSchema = array().of(questionSchema).required()

const answerSetSchema = object({
  pk: number().required(),
  notes: string().defined().nullable(),
  question: questionSchema,
}).required()
type AnswerSet = InferType<typeof answerSetSchema>

const answerSetSuggestionsSchema = array().of(answerSetSchema).required()

// DATA TRANSFORMATION

export type QuestionSuggestion = { id: number; text: string }

function makeQuestion(question: Question): QuestionSuggestion {
  return {
    id: question.pk,
    text: question.name,
  }
}

export type AnswerSetSuggestion = {
  id: number
  notes: string
  question: QuestionSuggestion
}

function makeAnswerSets(answerSets: AnswerSet[]): AnswerSetSuggestion[] | null {
  return answerSets.reduce<AnswerSetSuggestion[] | null>((acc, answerSet) => {
    if (!answerSet.notes) return acc

    const answerSetSuggestion: AnswerSetSuggestion = {
      id: answerSet.pk,
      notes: answerSet.notes,
      question: makeQuestion(answerSet.question),
    }

    return acc === null ? [answerSetSuggestion] : [...acc, answerSetSuggestion]
  }, null)
}
