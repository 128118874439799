import { useCallback } from 'react'
import axios from 'axios'
import * as yup from 'yup'

import { API_URL } from 'config'

import { setToken } from './token'
import {
  AuthenticatedUser,
  useCurrentUserContext,
  useCurrentUserQuery,
} from './current-user'

const api = axios.create({ baseURL: API_URL + '/api' })

const FIELD_VALIDATION_FAILURE_SCHEMA = yup
  .array()
  .of(yup.string().required())
  .required()

const EMAIL_VALIDATION_FAILURE_SCHEMA = yup
  .object()
  .shape({
    email: FIELD_VALIDATION_FAILURE_SCHEMA,
  })
  .required()

type SignUpEmailValidationSuccess = { ok: true }
type SignUpEmailValidationFailure = { ok: false; errors: ReadonlyArray<string> }
type SignUpEmailValidationResult =
  | SignUpEmailValidationSuccess
  | SignUpEmailValidationFailure

export async function signUpEmailValidation(
  email: string,
  signal: AbortSignal
): Promise<SignUpEmailValidationResult> {
  try {
    await api.post('/signup/validation/email', { email }, { signal })

    return { ok: true }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      const errors = EMAIL_VALIDATION_FAILURE_SCHEMA.validateSync(
        error.response?.data
      ).email

      return {
        ok: false,
        errors,
      }
    }

    throw error
  }
}

const PASSWORD_VALIDATION_FAILURE_SCHEMA = yup
  .object()
  .shape({
    password: FIELD_VALIDATION_FAILURE_SCHEMA,
  })
  .required()

type SignUpPasswordValidationSuccess = { ok: true }
type SignUpPasswordValidationFailure = {
  ok: false
  errors: ReadonlyArray<string>
}

type SignUpPasswordValidationResult =
  | SignUpPasswordValidationSuccess
  | SignUpPasswordValidationFailure

export async function signUpPasswordValidation(
  password: string,
  signal: AbortSignal
): Promise<SignUpPasswordValidationResult> {
  try {
    await api.post('/signup/validation/password', { password }, { signal })

    return { ok: true }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      const errors = PASSWORD_VALIDATION_FAILURE_SCHEMA.validateSync(
        error.response?.data
      ).password

      return {
        ok: false,
        errors,
      }
    }

    throw error
  }
}

type SignUpData = {
  account: {
    firstName: string
    lastName: string
    language: string
    institution: string
    department: string
    areaOfResearch: string
    position: string
    email: string
    password: string
  }
  editor: {
    type: 'regionaleditor' | 'managingeditor'
    editorId: number
  }
}

export function useSignUp(
  onSuccess: (user: AuthenticatedUser) => Promise<void>
): (data: SignUpData) => Promise<void> {
  const loadCurrentUser = useCurrentUserQuery()
  const { setCurrentUser } = useCurrentUserContext()

  return useCallback(
    async (data: SignUpData) => {
      const payload = {
        first_name: data.account.firstName,
        last_name: data.account.lastName,
        language: data.account.language,
        institution: data.account.institution,
        department: data.account.department,
        area_of_research: data.account.areaOfResearch,
        position: data.account.position,
        email: data.account.email,
        password: data.account.password,
        editor: {
          type: data.editor.type,
          id: data.editor.editorId,
        },
      }

      const res = await api.post('/signup', payload)

      setToken(res.data)

      const user = await loadCurrentUser()

      setCurrentUser(user)
      await onSuccess(user)
    },
    [loadCurrentUser, setCurrentUser, onSuccess]
  )
}
