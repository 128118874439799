import { useState, ReactNode } from 'react'
import classNames from 'classnames'

import { GetText } from 'lib/gettext'

type Props = {
  className?: string
  children: ReactNode
  onClick: () => void
  disabled?: boolean
  disabledBecause?: string
  color?: 'primary' | 'danger'
  positiveOption?: string
}

export default function DeleteButton({
  className,
  children,
  onClick,
  disabled = false,
  disabledBecause,
  color = 'danger',
  positiveOption,
}: Props) {
  const [confirmationIsVisible, setConfirmationVisibility] =
    useState<boolean>(false)

  return (
    <span className={className}>
      {confirmationIsVisible ? (
        <>
          <span className="mr-2 inline-block">
            <GetText>Are you sure?</GetText>
          </span>

          <span className="inline-block whitespace-nowrap">
            <button
              className="mr-2 align-baseline text-red-600 hover:underline"
              onClick={() => {
                setConfirmationVisibility(false)
                onClick()
              }}
              type="button"
            >
              {positiveOption || <GetText>Yes, delete</GetText>}
            </button>
            <button
              className="link align-baseline"
              onClick={() => setConfirmationVisibility(false)}
              type="button"
            >
              <GetText>No, cancel</GetText>
            </button>
          </span>
        </>
      ) : (
        <button
          className={classNames(
            'group relative whitespace-nowrap align-baseline enabled:hover:underline disabled:cursor-not-allowed disabled:text-gray-drh',
            {
              'disabled:underline disabled:decoration-dotted': disabledBecause,
              'text-red-600': color === 'danger',
              'text-primary': color === 'primary',
            }
          )}
          onClick={() => setConfirmationVisibility(true)}
          type="button"
          disabled={disabled}
        >
          {children}

          {disabledBecause && (
            <span
              className={classNames(
                'absolute bottom-[calc(100%_+_0.5rem)] left-1/2 hidden whitespace-nowrap rounded rounded-bl-none bg-black-drh py-2 px-3 text-white group-disabled:group-hover:block',
                'after:absolute after:top-full after:left-0 after:border-y-2 after:border-x-4 after:border-transparent after:border-t-black-drh after:border-l-black-drh'
              )}
            >
              {disabledBecause}
            </span>
          )}
        </button>
      )}
    </span>
  )
}
