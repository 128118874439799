import {
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link, To } from 'react-router-dom'
import classNames from 'classnames'
import { Caret } from './icons'

type DropdownTriggerCaretProps = { className?: string }

export function DropdownTriggerCaret(props: DropdownTriggerCaretProps) {
  return <Caret className={classNames('ml-1', props.className)} />
}

type DropdownLinkItemProps = {
  type: 'link'
  href: string
}

type DropdownRouterLinkItemProps = {
  type: 'router-link'
  to: To
}

type DropdownButtonItemProps<T extends string> = {
  type: 'button'
  disabled?: boolean
  value?: T
  onClick: MouseEventHandler
}

export type DropdownItemProps<T extends string = string> = PropsWithChildren<
  { className?: string } & (
    | DropdownLinkItemProps
    | DropdownButtonItemProps<T>
    | DropdownRouterLinkItemProps
  )
>

export function DropdownItem<T extends string = string>({
  children,
  ...props
}: DropdownItemProps<T>) {
  const className =
    'px-4 py-1 text-left text-black-drh hover:bg-primary hover:text-white'

  const allClassNames = classNames(className, props.className)

  switch (props.type) {
    case 'link':
      return (
        <a {...props} className={allClassNames}>
          {children}
        </a>
      )
    case 'router-link':
      return (
        <Link {...props} className={allClassNames}>
          {children}
        </Link>
      )
    case 'button':
      return (
        <button {...props} className={allClassNames}>
          {children}
        </button>
      )
  }
}

type DropdownTriggerProps = PropsWithChildren<{ className?: string }>

export function DropdownTrigger(props: DropdownTriggerProps) {
  return (
    <button
      className={classNames(
        'link group inline-flex items-center',
        props.className
      )}
      type="button"
    >
      {props.children}
    </button>
  )
}

export const DropdownItemDivider = () => <hr className="my-2 border-black/10" />

type DropdownMenuProps = PropsWithChildren<{ className?: string }>

export function DropdownMenu(props: DropdownMenuProps) {
  return (
    <div
      className={classNames(
        'absolute top-full z-50 flex flex-col whitespace-nowrap rounded bg-white py-2 text-left text-sm font-normal shadow-lg',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

type DropdownProps = PropsWithChildren<{
  trigger: ReactNode
}>

export default function Dropdown(props: DropdownProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <div
      className="relative inline-flex items-stretch"
      ref={wrapperRef}
      onClick={() => setIsExpanded((prev) => !prev)}
    >
      {props.trigger}

      {isExpanded && props.children}
    </div>
  )
}
