import { PropsWithChildren } from 'react'

export enum IconType {
  DASHBOARD = 'DASHBOARD',
  CONTRIBUTE = 'CONTRIBUTE',
  BROWSE = 'BROWSE',
  VISUALIZE = 'VISUALIZE',
  LEARN_MORE = 'LEARN_MORE',
}

const SVG = ({ children }: PropsWithChildren) => (
  <svg
    className="h-12 w-12 flex-shrink-0 fill-transparent stroke-primary stroke-2"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    strokeLinecap="round"
  >
    {children}
  </svg>
)

export default function Icon({ icon }: { icon: IconType }) {
  switch (icon) {
    case IconType.DASHBOARD:
      return (
        <SVG>
          <rect x="1" y="6" width="62" height="12" rx="4" />
          <line x1="7" y1="12" x2="29" y2="12" />
          <line x1="33" y1="12" x2="35" y2="12" />
          <line x1="39" y1="12" x2="41" y2="12" />

          <rect x="1" y="26" width="62" height="12" rx="4" />
          <line x1="7" y1="32" x2="41" y2="32" />
          <line x1="45" y1="32" x2="51" y2="32" />
          <line x1="55" y1="32" x2="57" y2="32" />

          <rect x="1" y="46" width="62" height="12" rx="4" />
          <line x1="7" y1="52" x2="25" y2="52" />
          <line x1="29" y1="52" x2="41" y2="52" />
          <line x1="45" y1="52" x2="57" y2="52" />
        </SVG>
      )
    case IconType.CONTRIBUTE:
      return (
        <SVG>
          <rect x="21" y="13" width="6" height="6" rx="2" />
          <line x1="32" y1="16" x2="56" y2="16" />
          <rect x="21" y="23" width="6" height="6" rx="2" />
          <line x1="32" y1="26" x2="56" y2="26" />
          <rect x="21" y="33" width="6" height="6" rx="2" />
          <line x1="32" y1="36" x2="56" y2="36" />
          <rect x="21" y="43" width="6" height="6" rx="2" />
          <line x1="32" y1="46" x2="56" y2="46" />
          <path d="M7 59h48a8 8 0 0 0 8-8V8a3 3 0 0 0-3-3H16a3 3 0 0 0-3 3v45a6 6 0 0 1-6 6v0a6 6 0 0 1-6-6V34a3 3 0 0 1 3-3h5" />
        </SVG>
      )
    case IconType.BROWSE:
      return (
        <SVG>
          <path d="M33.5 52H14.562C7.072 52 1 45.868 1 38.304c0-6.206 4.087-11.447 9.692-13.13C10.909 15.1 19.065 7 29.094 7c8.136 0 15.04 5.332 17.471 12.724.15-.005.3-.007.45-.007C55.845 19.717 63 26.944 63 35.86c0 8.457-6.441 15.402-14.64 16.092" />
          <circle cx="30" cy="33" r="13" />
          <path d="m34.888 45.545 7.145 12.377a2.045 2.045 0 0 0 2.793.748v0a2.045 2.045 0 0 0 .753-2.785L40.5 47" />
          <line x1="25" y1="29" x2="35" y2="29" />
          <line x1="25" y1="33" x2="35" y2="33" />
          <line x1="25" y1="37" x2="32" y2="37" />
        </SVG>
      )
    case IconType.VISUALIZE:
      return (
        <SVG>
          <path
            d="M24 52v2a7 7 0 0 1-7 7v0h30-2a5 5 0 0 1-5-5v0"
            strokeLinejoin="round"
          />
          <path d="M59 41.608A6.996 6.996 0 0 1 53 45H8a7 7 0 0 1-7-7V11a8 8 0 0 1 8-8h46a8 8 0 0 1 8 8v33a8 8 0 0 1-8 8H9a8.004 8.004 0 0 1-7.602-5.5" />
          <circle cx="32" cy="24" r="14" />
          <mask
            id="a"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="18"
            y="10"
            width="28"
            height="28"
          >
            <circle cx="32" cy="24" r="14" fill="#FFFFFF" />
          </mask>
          <g mask="url(#a)">
            <path d="M19 12c4 12 5.5 13.5 13 17.5 6 3.2 4.167 9 4.5 12m2-30C30 9.5 28 18 40 13m3.5-1c-2.768 7.75-6.905 9.942-9 11.5-1.344 1-1.7 2.5-.5 3.5 3.5 2.917 9.5-4.5 13 7" />
          </g>
        </SVG>
      )
    case IconType.LEARN_MORE:
      return (
        <SVG>
          <path d="M14 47v11.777a2 2 0 0 0 2.888 1.792l4.293-2.128A33.092 33.092 0 0 1 35.875 55v0C50.855 55 63 44.912 63 30S50.856 5 35.875 5h-7.75C13.145 5 1 15.088 1 30c0 7.41 2.998 12.121 7.853 17" />
          <circle cx="32.5" cy="17.5" r="4.5" />
          <path d="m35.5 41 1.964-11.786A2.76 2.76 0 0 0 34.742 26h-8.755c-1.937 0-2.288 2.761-.412 3.245v0a3.297 3.297 0 0 1 2.428 3.735l-1.652 9.917A4.382 4.382 0 0 0 30.672 48v0c.865 0 1.711-.256 2.431-.736L42.5 41" />
        </SVG>
      )
    default:
      return null
  }
}
