import { RefObject, useLayoutEffect, useState } from 'react'

export function useResizeEffect<T extends HTMLElement | SVGElement>(
  ref: RefObject<T>,
  callback: (dimensions: DOMRectReadOnly) => void
): void {
  useLayoutEffect(() => {
    const target = ref.current

    if (target) {
      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0]

        callback(entry.contentRect)
      })

      resizeObserver.observe(target)

      return () => {
        resizeObserver.unobserve(target)
      }
    }
  }, [ref, callback])
}

export function useResizeObserver<T extends HTMLElement | SVGElement>(
  ref: RefObject<T>
): DOMRectReadOnly | null {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null)

  useResizeEffect(ref, setDimensions)

  return dimensions
}
