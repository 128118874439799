import React from 'react'

type Token = {
  type: 'string' | 'open' | 'close' | 'selfClosing'
  value: string
}

function identifyToken(item: string): Token {
  // [/example]
  if (item.startsWith('[/')) {
    return {
      type: 'close',
      value: item.replace(/\W/g, ''),
    }
  }
  // [example /]
  if (item.endsWith('/]')) {
    return {
      type: 'selfClosing',
      value: item.replace(/\W/g, ''),
    }
  }
  // [example]
  if (item.startsWith('[')) {
    return {
      type: 'open',
      value: item.replace(/\W/g, ''),
    }
  }
  return {
    type: 'string',
    value: item,
  }
}

function tokenize(mixedString: string): Token[] {
  const tokenStrings = mixedString.split(/(\[\/?\s*\w+\s*\/?\])/g) // split to components and strings
  return tokenStrings.map(identifyToken)
}

function getCloseIndex(openIndex: number, tokens: Token[]) {
  const openToken = tokens[openIndex]
  let nestLevel = 0
  for (let i = openIndex + 1; i < tokens.length; i++) {
    const token = tokens[i]
    if (token.value === openToken.value) {
      if (token.type === 'open') {
        nestLevel++
        continue
      }
      if (token.type === 'close') {
        if (nestLevel === 0) {
          return i
        }
        nestLevel--
      }
    }
  }
  // if we get this far, there was no matching close token
  throw new Error('Missing closing component token `' + openToken.value + '`')
}

function buildChildren(
  tokens: Token[],
  components: Record<string, React.ReactElement>
): React.ReactNode {
  let children = []
  let openComponent
  let openIndex

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]

    if (token.type === 'string') {
      children.push(token.value)
      continue
    }
    // component node should at least be set
    if (components[token.value] === undefined) {
      throw new Error(
        `Invalid interpolation, missing component node: \`${token.value}\``
      )
    }
    // should be either ReactElement or null (both type "object"), all other types deprecated
    if (typeof components[token.value] !== 'object') {
      throw new Error(
        `Invalid interpolation, component node must be a ReactElement or null: \`${token.value}\``
      )
    }

    // we should never see a componentClose token in this loop
    if (token.type === 'close') {
      throw new Error(`Missing opening component token: \`${token.value}\``)
    }
    if (token.type === 'open') {
      openComponent = components[token.value]
      openIndex = i
      break
    }
    // componentSelfClosing token
    children.push(components[token.value])
    continue
  }

  if (openComponent && openIndex) {
    const closeIndex = getCloseIndex(openIndex, tokens)
    const grandChildTokens = tokens.slice(openIndex + 1, closeIndex)
    const grandChildren = buildChildren(grandChildTokens, components)
    const clonedOpenComponent = React.cloneElement(
      openComponent,
      {},
      grandChildren
    )
    children.push(clonedOpenComponent)

    if (closeIndex < tokens.length - 1) {
      const siblingTokens = tokens.slice(closeIndex + 1)
      const siblings = buildChildren(siblingTokens, components)

      children = [...children, siblings]
    }
  }

  children = children.filter(Boolean)

  if (children.length === 0) {
    return null
  }

  if (children.length === 1) {
    return children[0]
  }

  return React.createElement(React.Fragment, null, ...children)
}

export default function interpolateComponents(
  mixedString: string,
  components?: Record<string, React.ReactElement>
) {
  if (!components) {
    return mixedString
  }

  const tokens = tokenize(mixedString)

  try {
    return buildChildren(tokens, components)
  } catch (error) {
    const message =
      error instanceof Error ? error.message : JSON.stringify(error)

    const log = new Error(
      `Interpolation Error: unable to process \`${mixedString}\` because of error \`${message}\``
    )

    console.error(log)

    return mixedString
  }
}
