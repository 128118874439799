import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { Subtract } from 'utility-types'
import GoogleMapReact from 'google-map-react'

import { GOOGLE_MAPS_API_KEY } from 'config'

export const GOOGLE_MAP_DEFAULT_SHAPE_STYLE = {
  strokeColor: '#3c8cb5',
  fillColor: '#3c8cb5',
  fillOpacity: 0.3,
  strokeWeight: 3,
}

type Maps = typeof google.maps

type IGoogleMapsContext = {
  maps: Maps
}

const GoogleMapsContext = createContext<IGoogleMapsContext | undefined>(
  undefined
)

export const useGoogleMaps = (): IGoogleMapsContext => {
  const context = useContext(GoogleMapsContext)

  if (context === undefined) {
    throw new Error(
      'Cannot use useGoogleMaps() because its context is not defined.'
    )
  }

  return context
}

type GoogleMapsProviderProps = React.PropsWithChildren<{
  maps: Maps
}>

export function useGoogleMapsLoader(): Maps | undefined {
  const loader = useMemo(
    () =>
      new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['drawing', 'visualization'],
      }),
    []
  )

  const [maps, setMaps] = useState<Maps>()

  useEffect(() => {
    loader.load().then((google) => {
      setMaps(google.maps)
    })
  }, [loader])

  return maps
}

export const GoogleMapsProvider = ({
  maps,
  children,
}: GoogleMapsProviderProps) => {
  return (
    <GoogleMapsContext.Provider value={{ maps }}>
      {children}
    </GoogleMapsContext.Provider>
  )
}

type InjectedGoogleMapsProps = {
  maps: Maps
}

export const withGoogleMaps = <P extends InjectedGoogleMapsProps>(
  Component: React.ForwardRefExoticComponent<P>
) => {
  return React.forwardRef(
    (props: Subtract<P, InjectedGoogleMapsProps>, ref) => {
      const { maps } = useGoogleMaps()

      return <Component {...(props as P)} ref={ref} maps={maps} />
    }
  )
}

export type GoogleMapsApiLoaderCallback = {
  (map: google.maps.Map, maps: Maps): void
}

type GoogleMapProps = {
  zoom?: number
  defaultZoom?: number
  defaultCenter?: GoogleMapReact.Coords
  draggable?: boolean
  options?: GoogleMapReact.MapOptions
  onApiLoaded?: GoogleMapsApiLoaderCallback
}

function useApiLoadedCallback(callback: GoogleMapsApiLoaderCallback) {
  return useCallback(
    ({ map, maps }: { map: google.maps.Map; maps: Maps }) =>
      callback(map, maps),
    [callback]
  )
}

export function GoogleMap(props: GoogleMapProps) {
  const { onApiLoaded, ...rest } = props

  const handleApiLoaded = useApiLoadedCallback(
    onApiLoaded ? onApiLoaded : () => {}
  )

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: GOOGLE_MAPS_API_KEY,
        libraries: ['drawing', 'visualization'],
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleApiLoaded}
      {...rest}
    />
  )
}
