import { gql, FragmentType, useFragment } from 'graphql-generated'

import { EntrySourceBox, ExpertLink } from 'apps/browse/common'

import EntryContributors from './entry-contributors'

const ENTRY_SOURCE_FRAGMENT = gql(`
  fragment EntrySource on polls_entity {
    ...EntryContributors
    entry_source
    secondary_source {
      title
    }
    expert_source {
      user {
        ...ExpertLink
      }
    }
    supervisor {
      user {
        ...ExpertLink
      }
    }
  }
`)

type Props = {
  entryRef: FragmentType<typeof ENTRY_SOURCE_FRAGMENT>
  withContributors?: boolean
}

export default function EntrySource(props: Props) {
  const { entryRef, withContributors = false } = props
  const entry = useFragment(ENTRY_SOURCE_FRAGMENT, entryRef)

  const contributorsRenderer = () => (
    <EntryContributors label={false} entryRef={entry} inline />
  )

  const expertSourceLinkRenderer = () =>
    entry.expert_source && <ExpertLink userRef={entry.expert_source?.user} />

  const supervisorLinkRenderer = () =>
    entry.supervisor && <ExpertLink userRef={entry.supervisor?.user} />

  return (
    <EntrySourceBox
      withContributors={withContributors}
      entrySource={entry.entry_source}
      secondarySource={entry.secondary_source?.title}
      contributorsRenderer={contributorsRenderer}
      expertSourceLinkRenderer={expertSourceLinkRenderer}
      supervisorLinkRenderer={supervisorLinkRenderer}
    />
  )
}
