import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'

import { I_gettext, useGetText } from 'lib/gettext'
import { gql } from 'graphql-generated'

import { InlineButton, updateQueryString } from 'apps/browse/common'

import { useQuery } from '@apollo/client'
import { useEntryFilterQuery } from './use-entry-filter'
import YearInput from './year-input'

const POLLS_QUERY = gql(`
  query EntryFilter_Polls {
    polls_poll(
      where: {
        entries: {
          _and: [
            { progress: { published: { _gt: 0 } } }
            { region: { geom: { _is_null: false } } }
            {
              base_answer_sets: {
                _and: [
                  { region: { geom: { _is_null: false } } }
                  { answer_sets: { published: { _eq: true } } }
                ]
              }
            }
          ]
        }
      }
    ) {
      id
      name
      is_external
    }
  }
`)

type EntryFilterProps = {
  className?: string
}

const createValidationSchema = (gettext: I_gettext) =>
  yup.object().shape({
    yearFrom: yup
      .number()
      .transform((val) => (Number.isFinite(val) ? val : undefined))
      .optional()
      .integer(),
    yearTo: yup
      .number()
      .transform((val) => (Number.isFinite(val) ? val : undefined))
      .optional()
      .integer()
      .when('yearFrom', ([yearFrom], schema) => {
        if (typeof yearFrom === 'number' && Number.isFinite(yearFrom)) {
          return schema.min(
            yearFrom,
            gettext('Must be greater than or equal to "from" value')
          )
        }

        return schema
      }),
    polls: yup.array().of(yup.number().integer().required()),
  })

type FormData = yup.InferType<ReturnType<typeof createValidationSchema>>

export default function EntryFilter(props: EntryFilterProps) {
  const { gettext } = useGetText()
  const query = useEntryFilterQuery()
  const navigate = useNavigate()
  const location = useLocation()

  const form = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(createValidationSchema(gettext)),
  })

  const polls = useQuery(POLLS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const { watch, handleSubmit } = form

  const applyFilter = useCallback(
    (value: FormData) => {
      const url = updateQueryString(location, value, [
        'yearFrom',
        'yearTo',
        'polls',
      ])

      navigate(url)
    },
    [location, navigate]
  )

  useEffect(() => {
    form.reset({ ...query, polls: [...(query.polls || [])] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const subscribe = watch((values) => {
      handleSubmit(applyFilter)()
    })

    return () => subscribe.unsubscribe()
  }, [watch, handleSubmit, applyFilter])

  return (
    <form
      onSubmit={handleSubmit(applyFilter)}
      className={classNames(
        'border-b border-[#e5e5e5] bg-[#fafafa] py-6',
        props.className
      )}
    >
      <div className="mb-4 flex gap-16">
        <div className="basis-4/12 self-center pl-4 text-[#808080]">
          {gettext('By date range:')}
        </div>

        <div className="flex basis-8/12 gap-3 pr-4">
          <div className="basis-1/2">
            <YearInput
              id="entryFilter_yearFrom"
              control={form.control}
              name="yearFrom"
              label="From"
              onChange={() => form.trigger()}
            />

            <ErrorMessage
              errors={form.formState.errors}
              name="yearFrom"
              as={<p className="mt-1 text-xs text-red-600" />}
            />
          </div>

          <div className="basis-1/2">
            <YearInput
              id="entryFilter_yearTo"
              control={form.control}
              name="yearTo"
              label="To"
              onChange={() => form.trigger()}
            />

            <ErrorMessage
              errors={form.formState.errors}
              name="yearTo"
              as={<p className="mt-1 text-xs text-red-600" />}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-16">
        <div className="basis-4/12 self-center pl-4 text-[#808080]">
          {gettext('By poll:')}
        </div>
        <div className="basis-8/12 pr-4">
          {polls.loading ? (
            <span className="text-[#808080]">{gettext('Loading…')}</span>
          ) : (
            <Controller
              name="polls"
              control={form.control}
              render={({ field }) => (
                <>
                  <div className="mb-4 grid w-full grid-cols-3 gap-y-4 gap-x-8">
                    {polls.data &&
                      polls.data.polls_poll.map((poll) => (
                        <div key={poll.id}>
                          <label className="flex w-fit gap-1">
                            <input
                              type="checkbox"
                              className="self-center"
                              value={poll.id}
                              checked={field.value?.some(
                                (pollId) => pollId === poll.id
                              )}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  field.onChange([
                                    ...(field.value || []),
                                    poll.id,
                                  ])
                                } else {
                                  field.onChange(
                                    field.value?.filter(
                                      (pollId) => pollId !== poll.id
                                    )
                                  )
                                }
                              }}
                            />
                            {poll.name}
                            {poll.is_external && (
                              <span className="self-center rounded bg-[#404040] px-[2px] text-[0.5rem] uppercase leading-3 text-white">
                                ext
                              </span>
                            )}
                          </label>
                        </div>
                      ))}
                  </div>
                  <InlineButton
                    onClick={() => form.setValue('polls', [])}
                    hidden={!field.value || field.value.length === 0}
                  >
                    {gettext('deselect all')}
                  </InlineButton>
                </>
              )}
            />
          )}
        </div>
      </div>
    </form>
  )
}
