import { useRef, PropsWithChildren } from 'react'
import { Link, NavLink, To } from 'react-router-dom'
import classNames from 'classnames'

import { useGetText } from 'lib/gettext'
import { AuthorizedFragment } from 'common'

import {
  HeaderContext,
  HeaderContextProvider,
  useHeaderContext,
} from './context'
import UserDropdown from './user-dropdown'
import LanguageSelect from './language-select'

import LOGO from './logo.svg'

type HeaderProps = {
  className?: string
}

export default function Header(props: HeaderProps) {
  const { className } = props

  const { gettext } = useGetText()

  const navRef = useRef<HTMLDivElement>(null)
  const wrappedItemRef = useRef<HTMLDivElement>(null)

  return (
    <HeaderContextProvider refs={{ navRef, wrappedItemRef }}>
      <HeaderContext.Consumer>
        {({ isWrapped }) => (
          <header
            className={classNames(
              'flex flex-col items-stretch justify-between gap-x-8 gap-y-3 bg-primary-darkest px-4 text-white sm:px-8 md:flex-row',
              { 'py-4': isWrapped },
              className
            )}
          >
            <Link
              className={classNames(
                'mx-auto inline-flex shrink-0 items-center py-4 leading-[0] md:ml-0',
                { 'py-0': isWrapped }
              )}
              to="/"
            >
              <img src={LOGO} alt="UBC DRH Logo" />
            </Link>

            <nav
              className={classNames(
                'flex shrink flex-wrap items-stretch justify-center gap-x-6 gap-y-3 text-xs font-semibold md:justify-start'
              )}
              ref={navRef}
            >
              <AuthorizedFragment>
                <HeaderNavLink to="/dashboard">
                  {gettext('Dashboard')}
                </HeaderNavLink>
              </AuthorizedFragment>

              <HeaderNavLink to="/browse">{gettext('Browse')}</HeaderNavLink>
              <HeaderNavLink to="/visualize">
                {gettext('Visualize')}
              </HeaderNavLink>
              <HeaderNavLink to="/analyze">{gettext('Analyze')}</HeaderNavLink>

              <AuthorizedFragment>
                <HeaderNavLink to="/contribute">
                  {gettext('Contribute')}
                </HeaderNavLink>
              </AuthorizedFragment>

              <HeaderNavLink to="/landing/about">
                {gettext('About the DRH')}
              </HeaderNavLink>
              <HeaderNavLink to="/landing/polls">
                {gettext('About the polls')}
              </HeaderNavLink>
              <HeaderNavLink to="/landing/faq">{gettext('FAQ')}</HeaderNavLink>

              <AuthorizedFragment
                fallback={
                  <>
                    <NavLink
                      className={({ isActive }) =>
                        classNames(
                          'inline-flex items-center self-center rounded-lg border-2 p-2 mix-blend-screen transition-colors hover:border-primary',
                          { 'border-primary': isActive }
                        )
                      }
                      to="/get-involved"
                    >
                      {gettext('Get Involved')}
                    </NavLink>

                    <LanguageSelect
                      className={classNames(
                        'inline-flex cursor-pointer appearance-none items-center self-center rounded-lg border-2 bg-transparent bg-small-arrow-down bg-[right_1rem_center] bg-no-repeat p-2 mix-blend-screen outline-none transition-colors hover:border-primary'
                      )}
                    />
                  </>
                }
              >
                <></>
              </AuthorizedFragment>

              <div
                className={classNames(
                  'flex gap-x-6 before:block before:w-[2px] before:bg-white/25',
                  { 'before:my-4': !isWrapped }
                )}
                ref={wrappedItemRef}
              >
                <AuthorizedFragment
                  fallback={
                    <HeaderNavLink to="/login">
                      {gettext('Login')}
                    </HeaderNavLink>
                  }
                >
                  <UserDropdown />
                </AuthorizedFragment>
              </div>
            </nav>
          </header>
        )}
      </HeaderContext.Consumer>
    </HeaderContextProvider>
  )
}

type LinkProps = PropsWithChildren<{
  to: To
  state?: unknown
}>

function HeaderNavLink(props: LinkProps) {
  const { to, state, children } = props

  const { isWrapped } = useHeaderContext()

  return (
    <NavLink
      className={({ isActive }) =>
        classNames(
          'inline-flex items-center py-2 capitalize mix-blend-screen transition hover:text-primary md:py-0',
          {
            'text-primary': isActive,
            'hover:text-inherit hover:shadow-[inset_0_-1em] hover:shadow-primary':
              !isWrapped,
            'text-inherit shadow-[inset_0_-1em] shadow-primary':
              isActive && !isWrapped,
          }
        )
      }
      to={to}
      state={state}
    >
      {children}
    </NavLink>
  )
}
