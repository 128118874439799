import { MouseEventHandler, useCallback, useState } from 'react'

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  DropdownTriggerCaret,
} from 'common'

type Option<T extends string> = {
  value: T
  label: string
}

type DropdownSelectProps<T extends string> = {
  options: ReadonlyArray<Option<T>>
  defaultValue: T
  onChange: (value: T) => unknown
}

export default function DropdownSelect<T extends string>(
  props: DropdownSelectProps<T>
) {
  const { onChange, options } = props
  const [value, setValue] = useState<T>(props.defaultValue)
  const label = options.find((option) => option.value === value)?.label || 'N/A'

  const handleOptionClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      setValue(event.currentTarget.value as T)
      onChange(event.currentTarget.value as T)
    },
    [setValue, onChange]
  )

  return (
    <Dropdown
      trigger={
        <DropdownTrigger>
          {label} <DropdownTriggerCaret />
        </DropdownTrigger>
      }
    >
      <DropdownMenu className="right-1 mt-2">
        {options
          .filter((option) => option.value !== value)
          .map((option) => (
            <DropdownItem
              key={option.value}
              type="button"
              value={option.value}
              onClick={handleOptionClick}
            >
              {option.label}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  )
}
