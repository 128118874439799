import { useGetText } from 'lib/gettext'

export default function EmptyResult() {
  const { gettext } = useGetText()

  return (
    <p className="mt-12 grow text-center text-5xl text-neutral-500">
      {gettext('No entries found')}
    </p>
  )
}
