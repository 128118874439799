import classNames from 'classnames'
import { FlexSpinner } from './spinner'

type SplashScreenProps = { theme?: 'white' | 'blue' }

export default function SplashScreen(props: SplashScreenProps) {
  const { theme = 'white' } = props

  return (
    <div
      className={classNames('flex min-h-screen flex-grow', {
        'bg-primary-dark': theme === 'blue',
      })}
    >
      <FlexSpinner color={theme === 'blue' ? 'white' : 'blue'} size="lg" />
    </div>
  )
}
