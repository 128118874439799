import { gql } from 'graphql-generated'
import { Params, defer, useLoaderData } from 'react-router-dom'

import { gqlRequest } from 'api'

const USER_ID_BY_USERNAME_QUERY = gql(`
  query UserIdByUsernameQuery($username: String!) {
    auth_user(where: { username: { _eq: $username } }) {
      id
    }
  }
`)

type UserIdByUserNameLoaderOptions = { params: Params<string> }

async function getUserIdByUsername(username: string): Promise<number | null> {
  const res = await gqlRequest(USER_ID_BY_USERNAME_QUERY, { username })

  const [user] = res.auth_user

  return user?.id || null
}

export function userIdByUsernameLoader(
  options: UserIdByUserNameLoaderOptions
): ReturnType<typeof defer> {
  const { username } = options.params

  if (typeof username === 'string') {
    return defer({ userId: getUserIdByUsername(username) })
  }

  throw new Error('Username is required')
}

export function useUserIdLoaderData() {
  const data = useLoaderData()

  return data as unknown as { userId: Promise<number | null> }
}
