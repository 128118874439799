import { useQuery } from '@apollo/client'

import { gql } from 'graphql-generated'
import { NavLink } from 'react-router-dom'
import { useGetText } from 'lib/gettext'
import { useAuthenticatedUser, userId } from 'auth'
import {
  PrimaryButton,
  Spinner,
  EntryProgress,
  EntryProgressBar,
  EntryPublicationStatus,
  Timestamp,
  TableHeadRow,
  TableHeadCell,
  TableBodyRow,
  TableBodyCell,
  useEntryProgress as getEntryProgress,
} from 'common'

export default function MyEntries() {
  const { gettext } = useGetText()
  const user = useAuthenticatedUser()

  const { loading, data } = useMyEntries()

  return (
    <section>
      <h3 className="mb-4 text-3xl font-semibold">{gettext('My Entries')}</h3>

      {loading ? (
        <Spinner className="my-6" />
      ) : data && data.length > 0 ? (
        <>
          <table className="w-full">
            <thead>
              <TableHeadRow>
                <TableHeadCell>{gettext('Entry name')}</TableHeadCell>
                <TableHeadCell className="w-1/4 lg:w-2/6">
                  {gettext('Progress')}
                </TableHeadCell>
                <TableHeadCell>{gettext('Published')}</TableHeadCell>
                <TableHeadCell>{gettext('Date modified')}</TableHeadCell>
                <TableHeadCell className="w-0" />
              </TableHeadRow>
            </thead>
            <tbody>
              {data.map((entryRef) => {
                const entry = getEntryProgress(entryRef)

                const canBeUpgraded =
                  entry.creatorId === userId(user) && entryRef.poll.next

                return (
                  <TableBodyRow key={entryRef.id}>
                    <TableBodyCell>
                      <NavLink
                        className="link text-lg font-semibold leading-5"
                        to={`/questionnaire/${entry.id}`}
                      >
                        {entry.name}
                      </NavLink>
                    </TableBodyCell>

                    <TableBodyCell className="flex items-start">
                      {/* A workaround for baseline alignment */}
                      <span className="w-0">&nbsp;</span>
                      <EntryProgressBar className="grow !py-0" entry={entry} />

                      <span className="ml-3 w-[2.5em] shrink-0 text-4xl leading-8 text-black/40">
                        <EntryProgress entry={entry} />
                      </span>
                    </TableBodyCell>

                    <TableBodyCell className="text-gray-drh">
                      <EntryPublicationStatus entry={entry} />
                    </TableBodyCell>

                    <TableBodyCell className="whitespace-nowrap text-gray-drh">
                      <Timestamp date={entry.dateModified} />
                    </TableBodyCell>

                    <TableBodyCell>
                      {entry.creatorId === userId(user) && canBeUpgraded && (
                        <PrimaryButton
                          type="router-link"
                          to={`/upgrade/${entry.id}`}
                          size="sm"
                        >
                          {gettext('Upgrade')}
                        </PrimaryButton>
                      )}
                    </TableBodyCell>
                  </TableBodyRow>
                )
              })}
            </tbody>
          </table>

          <PrimaryButton className="mb-4" type="router-link" to="/contribute">
            {gettext('Create new entry')}
          </PrimaryButton>
        </>
      ) : (
        <div>
          <p className="mb-4 text-lg">
            {gettext('I currently have no entries linked to my account.')}
          </p>

          <PrimaryButton
            type="router-link"
            className="mx-auto block w-fit"
            to="/contribute"
          >
            {gettext('Start contributing')}
          </PrimaryButton>
        </div>
      )}
    </section>
  )
}

function useMyEntries() {
  const user = useAuthenticatedUser()

  const { loading, data, error } = useQuery(MY_ENTRIES_QUERY, {
    variables: { userId: userId(user) },
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    throw error
  }

  return {
    loading,
    data: data?.polls_entity,
  }
}

/**
 * On the dashboard users should see the list of entries they have created or answered.
 */
const MY_ENTRIES_QUERY = gql(`
  query EntriesProgress($userId: Int!) {
    polls_entity(where: {_or: [{ created_by_id: {_eq: $userId} }, {progress: {_and: [{ expert: {user_id: {_eq: $userId}}}, { answered: { _gt: 0 } } ]}}], archived: { _eq: false }}, order_by: { date_modified: desc }) {
      id
      ...EntryProgress
      poll {
        next {
          id
        }
      }
    }
  }
`)
