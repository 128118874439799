import { useLocation } from 'react-router-dom'
import * as yup from 'yup'
import queryString from 'query-string'

import { Polls_Entity_Bool_Exp } from 'graphql-generated/graphql'

const VALIDATION_SCHEMA = yup.object({
  yearFrom: yup.number(),
  yearTo: yup.number(),
  polls: yup
    .array()
    .transform((val) => {
      return val && !Array.isArray(val) ? [val] : val
    })
    .of(yup.number().integer().required()),
})

type EntryFilterQuery = {
  yearFrom: number | undefined
  yearTo: number | undefined
  polls: ReadonlyArray<number> | undefined
}

function buildExpression(query: EntryFilterQuery): Polls_Entity_Bool_Exp {
  const { yearFrom, yearTo, polls } = query

  return {
    _and: [
      {
        year_from: yearFrom ? { _lte: yearFrom } : {},
      },
      {
        year_to: yearTo ? { _gte: yearTo } : {},
      },
      {
        poll: {
          id: polls && polls.length > 0 ? { _in: [...polls] } : {},
        },
      },
    ],
  }
}

function validateYearFrom(data: unknown): number | undefined {
  try {
    return VALIDATION_SCHEMA.validateSyncAt('yearFrom', data)
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      return undefined
    }

    throw error
  }
}

function validateYearTo(data: unknown): number | undefined {
  try {
    return VALIDATION_SCHEMA.validateSyncAt('yearTo', data)
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      return undefined
    }

    throw error
  }
}

function validatePolls(data: unknown): number[] | undefined {
  try {
    return VALIDATION_SCHEMA.validateSyncAt('polls', data)
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      return undefined
    }

    throw error
  }
}

function getEntryFilterQueryFromSearchString(search: string): EntryFilterQuery {
  const data = queryString.parse(
    queryString.pick(search, ['polls', 'yearFrom', 'yearTo']),
    {
      arrayFormat: 'separator',
      arrayFormatSeparator: ',',
      parseNumbers: true,
    }
  )

  return {
    yearFrom: validateYearFrom(data),
    yearTo: validateYearTo(data),
    polls: validatePolls(data),
  }
}

export function useEntryFilterQuery(): EntryFilterQuery {
  const location = useLocation()

  return getEntryFilterQueryFromSearchString(location.search)
}

export function getEntryFilter(url: URL): Polls_Entity_Bool_Exp {
  const query = getEntryFilterQueryFromSearchString(url.search)

  return buildExpression(query)
}

export default function useEntryFilter(): Polls_Entity_Bool_Exp {
  const query = useEntryFilterQuery()

  return buildExpression(query)
}
