import { getAccessTokenId } from 'auth'
import axios from 'axios'

import { API_URL } from 'config'

const instance = axios.create({
  baseURL: API_URL + '/api',
})

instance.interceptors.request.use(async function (config) {
  const token = await getAccessTokenId()

  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`)
  }

  return config
})

export const isAxiosError = axios.isAxiosError.bind(axios)

export default instance
