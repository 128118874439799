import { PropsWithChildren, ReactNode } from 'react'

import { FlexSpinner } from 'common'

export function TabSidebar(props: PropsWithChildren) {
  return (
    <div className="shrink-0 sm:w-1/4 md:w-1/5 lg:w-1/6">{props.children}</div>
  )
}

export function TabContent(props: PropsWithChildren) {
  return <div className="sm:w-auto sm:grow-[3]">{props.children}</div>
}

type TabProps<T> = {
  loading?: boolean
  children: (data: NonNullable<T>) => ReactNode
  data: T
}

export default function Tab<T>(props: TabProps<T>) {
  const { loading, data, children } = props

  if (loading) {
    return <FlexSpinner />
  }

  if (!data) {
    throw new Error('Unexpected empty data')
  }

  return (
    <div className="flex flex-col gap-x-12 gap-y-8 pb-8 sm:flex-row">
      {children(data)}
    </div>
  )
}
