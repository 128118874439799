import { LoaderFunction, useLoaderData } from 'react-router-dom'

import { gqlRequest } from 'api'
import { gql } from 'graphql-generated'
import { Browse_EntriesByRegionQuery } from 'graphql-generated/graphql'

import { getEntryFilter, getEntrySorting } from '../common'

const ENTRIES_BY_REGION_QUERY = gql(`
  query Browse_EntriesByRegion($orderBy: polls_entity_order_by!, $filter: polls_entity_bool_exp!) {
    polls_entity(
      order_by: [$orderBy]
      where: {
        _and: [
          { archived: { _eq: false } }
          { progress: { published: { _gt: 0 } } }
          { region: { geom: { _is_null: false } } }
          {
            base_answer_sets: {
              _and: [
                { region: { geom: { _is_null: false } } }
                { answer_sets: { published: { _eq: true } } }
              ]
            }
          }
          $filter
        ]
      }
    ) {
      region {
        tags(
          where: {
            tag: {
              approved: { _eq: true }
              _or: [{ level: { _eq: 1 } }, { level: { _eq: 2 } }]
            }
          }
        ) {
          tag {
            id
            name
            parent_tag_id
            parent {
              id
              name
            }
          }
        }
      }
      id
      ...EntriesByRegion_RegionEntry
    }
  }
`)

export type RegionWithEntries = {
  id: string
  name: string
  entries: Browse_EntriesByRegionQuery['polls_entity']
}

export type Subregions = {
  [key: string]: RegionWithEntries
}

export type RootRegions = {
  [key: string]: RegionWithEntries & {
    children: Subregions
  }
}

function groupEntriesByRegion(
  entries: Browse_EntriesByRegionQuery['polls_entity']
) {
  return entries.reduce<RootRegions>((result, entry) => {
    const subregions = entry.region.tags.filter((tag) => tag.tag?.parent_tag_id)

    if (subregions.length > 0) {
      return subregions.reduce((result, subregion) => {
        if (subregion && subregion.tag?.parent) {
          const parentRegion = result[subregion.tag.parent.id] || {
            id: subregion.tag.parent.id,
            name: subregion.tag.parent.name,
            children: {},
            entries: [],
          }

          const tagId = subregion.tag.id
          const entries = parentRegion.children[tagId]?.entries || []

          return {
            ...result,
            [parentRegion.id]: {
              ...parentRegion,
              children: {
                ...parentRegion.children,
                [tagId]: {
                  id: tagId,
                  name: subregion.tag.name,
                  entries: [...entries, entry],
                },
              },
            },
          }
        }

        return result
      }, result)
    }

    return entry.region.tags
      .filter((tag) => tag.tag?.parent === null)
      .reduce((result, rootRegion) => {
        if (rootRegion.tag && rootRegion.tag.id) {
          const region = result[rootRegion.tag.id] || {
            id: rootRegion.tag.id,
            name: rootRegion.tag.name,
            children: {},
            entries: [],
          }

          return {
            ...result,
            [region.id]: {
              ...region,
              entries: [...region.entries, entry],
            },
          }
        }

        return result
      }, result)
  }, {})
}

export const entriesGroupedByRegionLoader: LoaderFunction = async ({
  request,
}) => {
  const url = new URL(request.url)

  const orderBy = getEntrySorting(url)
  const filter = getEntryFilter(url)

  return gqlRequest(ENTRIES_BY_REGION_QUERY, {
    orderBy,
    filter,
  })
}

export function useEntryGroupedByRegion(): RootRegions {
  const data = useLoaderData() as Browse_EntriesByRegionQuery

  return groupEntriesByRegion(data.polls_entity)
}
