import { PropsWithChildren, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { isSubstituteUser, setToken, useCurrentUserReload } from 'auth'
import { suLogout } from 'api'

import AuthorizedFragment from './authorized-fragment'
import UserDisplayName from './user-display-name'
import SplashScreen from './splash-screen'

type SubstituteUserWarningProps = PropsWithChildren

export default function SubstituteUserWarning(
  props: SubstituteUserWarningProps
) {
  const [loggingOut, setLoggingOut] = useState(false)
  const navigate = useNavigate()
  const reloadCurrentUser = useCurrentUserReload()

  const logOut = useCallback(async () => {
    setLoggingOut(true)
    navigate('/editor-console')

    const tokenPair = await suLogout()

    setToken(tokenPair)
    await reloadCurrentUser()

    setLoggingOut(false)
  }, [setLoggingOut, navigate, reloadCurrentUser])

  return loggingOut ? (
    <SplashScreen />
  ) : (
    <>
      <AuthorizedFragment authorize={isSubstituteUser}>
        {(user) => (
          <div className="bg-yellow-600 px-4 py-2 text-white sm:px-8">
            You are acting as{' '}
            <strong>
              <UserDisplayName {...user} />
            </strong>
            .{' '}
            <button
              className="underline decoration-white/50 transition-colors hover:decoration-white"
              onClick={logOut}
            >
              Finish and return to Editor Console
            </button>
          </div>
        )}
      </AuthorizedFragment>

      {props.children}
    </>
  )
}
