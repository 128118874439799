import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryPeriodBox } from 'apps/browse/common'

const PERIOD_FRAGMENT = gql(`
  fragment EntrySearch_Entry_Period on Entry {
    year_from
    year_to
  }
`)

type EntryPeriodProps = {
  entryRef: FragmentType<typeof PERIOD_FRAGMENT>
}

export default function EntryPeriod(props: EntryPeriodProps) {
  const { year_from: from, year_to: to } = useFragment(
    PERIOD_FRAGMENT,
    props.entryRef
  )

  return <EntryPeriodBox from={from} to={to} />
}
