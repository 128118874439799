import { defer } from 'react-router-dom'
import { ValuesType, $ElementType } from 'utility-types'

import { gql } from 'graphql-generated'
import { gqlRequest } from 'api'

import type { EntryTagGroupsWithEntryTagsQuery } from 'graphql-generated/graphql'

const ENTRY_TAG_GROUPS_QUERY = gql(`
  query EntryTagGroupsWithEntryTags {
    polls_entrytaggroup(order_by: { order: asc }) {
      id
      name
      tags(where: { approved: { _eq: true } }, order_by: { name: asc }) {
        name
        level
        parent_tag_id
        id
      }
    }
  }
`)

type QueryTags = ValuesType<
  EntryTagGroupsWithEntryTagsQuery['polls_entrytaggroup']
>['tags']
type QueryTag = $ElementType<QueryTags, 0>

export type EntryTag = {
  id: number
  name: string
  children: EntryTags
}

type EntryTags = ReadonlyArray<EntryTag>

type EntryTagGroup = {
  id: number
  name: string
  tags: EntryTags
}

export type EntryTagGroups = ReadonlyArray<EntryTagGroup>

function makeEntryTagFromData(tag: QueryTag, tags: QueryTags): EntryTag {
  return {
    id: tag.id,
    name: tag.name,
    children: tags
      .filter(({ parent_tag_id }) => parent_tag_id === tag.id)
      .map((tag) => makeEntryTagFromData(tag, tags)),
  }
}

function makeEntryTagGroupsFromData(
  data: EntryTagGroupsWithEntryTagsQuery
): EntryTagGroups {
  return data.polls_entrytaggroup
    .map(({ id, name, tags }) => ({
      id,
      name,
      tags: tags
        .filter(({ level }) => level === 2)
        .map((tag) => makeEntryTagFromData(tag, tags)),
    }))
    .filter(({ tags }) => tags.length > 0)
}

async function getEntryTagGroups() {
  const data = await gqlRequest(ENTRY_TAG_GROUPS_QUERY)

  return makeEntryTagGroupsFromData(data)
}

export default function taggingTreesLoader() {
  return defer({ entryTagGroups: getEntryTagGroups() })
}
