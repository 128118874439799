import { ReactNode } from 'react'
import { ValuesType } from 'utility-types'

import { GetText } from 'lib/gettext'
import { InlineList } from 'apps/browse/common'

import EntryProperty from './entry-property'

type Props<T> = {
  contributors:
    | ReadonlyArray<(T & { id: string | number }) | null | undefined>
    | null
    | undefined
  children: (contributor: T & { id: string | number }) => ReactNode
  personalExpertise?: boolean
  inline?: boolean
  label?: boolean
}

export default function EntryContributorsBox<T>({
  contributors,
  children,
  personalExpertise = true,
  inline = false,
  label = true,
}: Props<T>) {
  const nonEmptyContributors = (
    contributors ? contributors.filter((c) => c !== null) : []
  ) as ReadonlyArray<NonNullable<ValuesType<NonNullable<typeof contributors>>>>

  if (nonEmptyContributors.length > 0) {
    const list = (
      <InlineList items={nonEmptyContributors} keyField="id">
        {(item) => children(item)}
      </InlineList>
    )

    return (
      <EntryProperty inline={inline}>
        {label ? (
          personalExpertise ? (
            <GetText components={{ contributors: list }}>
              By [contributors/]
            </GetText>
          ) : (
            <GetText components={{ contributors: list }}>
              Entered by [contributors/]
            </GetText>
          )
        ) : (
          list
        )}
      </EntryProperty>
    )
  }

  return null
}
