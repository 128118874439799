import { ValuesType } from 'utility-types'

export const LANGUAGES = ['en', 'fr', 'zh-hans', 'zh-hant', 'ja', 'es'] as const
export type Language = ValuesType<typeof LANGUAGES>

export const TRANSLATION_LANGUAGES = [
  'fr',
  'zh-hans',
  'zh-hant',
  'ja',
  'es',
] as const
export type TranslationLanguage = ValuesType<typeof TRANSLATION_LANGUAGES>

export const TRANSLATION_LANGUAGE_KEYS = [
  'fr',
  'zh_hans',
  'zh_hant',
  'ja',
  'es',
] as const
export type TranslationLanguageKey = ValuesType<
  typeof TRANSLATION_LANGUAGE_KEYS
>

export const LANGUAGE_LABELS: { [key in Language]: string } = {
  en: 'English (EN)',
  fr: 'Français (FR)',
  'zh-hans': '简体中文 (ZH-HANS)',
  'zh-hant': '繁體中文 (ZH-HANT)',
  ja: '日本語 (JA)',
  es: 'Español (ES)',
} as const

export const LANGUAGE_FLAGS: { [key in Language]: string } = {
  en: '🇬🇧',
  fr: '🇫🇷',
  'zh-hans': '🇨🇳',
  'zh-hant': '🇨🇳',
  ja: '🇯🇵',
  es: '🇪🇸',
} as const

export const LANGUAGE_KEY_MAP: {
  [key in TranslationLanguage]: TranslationLanguageKey
} = {
  fr: 'fr',
  'zh-hans': 'zh_hans',
  'zh-hant': 'zh_hant',
  ja: 'ja',
  es: 'es',
}

export const LANGUAGE_KEY_REVERSE_MAP: {
  [key in TranslationLanguageKey]: TranslationLanguage
} = Object.fromEntries(
  Object.entries(LANGUAGE_KEY_MAP).map((keyValue) => keyValue.reverse())
)

export function isValidLocale(locale: string): locale is Language {
  return LANGUAGES.includes(locale as unknown as Language)
}

export function getLabel(locale: string): string {
  if (isValidLocale(locale)) {
    return LANGUAGE_LABELS[locale]
  }

  throw new Error(`Unknown locale ${locale}`)
}
