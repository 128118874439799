export type UserName = {
  firstName?: string
  lastName?: string
  username: string
}

export function mapUserNameToString(
  name: UserName,
  short: boolean = false
): string {
  const { firstName, lastName, username } = name

  const fragments = short ? [firstName] : [firstName, lastName]

  return fragments.filter((fragment) => fragment).join(' ') || username
}
