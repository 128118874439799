import { useCallback, useState } from 'react'

import { useGetText } from 'lib/gettext'

import { InlineList, InlineButton, Highlight } from 'apps/browse/common'

import EntryDetailsItem from './entry-details-item'

import ENTRY_TAGS_ICON from './assets/tags.svg'
import REGION_TAGS_ICON from './assets/region-tags.svg'

const ICONS = {
  'entry-tags': ENTRY_TAGS_ICON,
  'region-tags': REGION_TAGS_ICON,
}

type EntryTagsProps<T> = {
  icon: keyof typeof ICONS
  tags: ReadonlyArray<T & { id: string | number; name: string }>
  highlight?: string
}

export default function EntryTagsBox<T>({
  icon,
  tags,
  highlight,
}: EntryTagsProps<T>) {
  const { gettext } = useGetText()
  const [displayTags, setDisplayTags] = useState<typeof tags>(
    [...tags].slice(0, 2)
  )

  const countHidden = tags.length - displayTags.length
  const iconPath = ICONS[icon]

  const showHidden = useCallback(() => {
    setDisplayTags(tags)
  }, [setDisplayTags, tags])

  if (displayTags.length > 0) {
    return (
      <EntryDetailsItem icon={iconPath}>
        <InlineList items={displayTags} keyField="id">
          {({ name }) =>
            highlight ? <Highlight this={highlight}>{name}</Highlight> : name
          }
        </InlineList>
        {countHidden > 0 && (
          <>
            {' '}
            <InlineButton onClick={showHidden}>
              {gettext('and %(count)i more', { count: countHidden })}
            </InlineButton>
          </>
        )}
      </EntryDetailsItem>
    )
  }

  return null
}
