import { PropsWithChildren } from 'react'

export default function EntryDetailsItem({
  children,
  icon,
}: PropsWithChildren<{
  icon: string
}>) {
  return (
    <div className="mt-1 flex items-start gap-x-2 first:mt-0">
      <img className="max-h-5 w-5" src={icon} alt={'Icon'} />
      <div>{children}</div>
    </div>
  )
}
