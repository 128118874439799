import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryPeriodBox } from 'apps/browse/common'

const ENTRY_PERIOD_FRAGMENT = gql(`
  fragment EntryPeriod on polls_entity {
    year_from
    year_to
  }
`)

type EntryPeriodProps = {
  entryRef: FragmentType<typeof ENTRY_PERIOD_FRAGMENT>
}

export default function EntryPeriod(props: EntryPeriodProps) {
  const { year_from: from, year_to: to } = useFragment(
    ENTRY_PERIOD_FRAGMENT,
    props.entryRef
  )

  return <EntryPeriodBox from={from} to={to} />
}
