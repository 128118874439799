import { defer } from 'react-router-dom'
import { API_URL } from 'config'

export type Statistics = {
  entries: number
  answers: number
  experts: number
}

async function getStatistics(): Promise<Statistics> {
  const results = await fetch(API_URL + '/api/polls/stats')

  if (!results.ok) {
    throw new Error('Failed to fetch stats')
  }

  const statistics = (await results.json()) as Statistics

  return statistics
}

export default function statisticsLoader() {
  return defer({ statistics: getStatistics() })
}
