import { ReactNode } from 'react'

type InlineListProps<T> = {
  items: ReadonlyArray<T>
  children: (item: T) => ReactNode
  keyField: keyof T | ((item: T) => string)
}

export default function InlineList<T extends { [key: string]: any }>({
  items,
  children,
  keyField,
}: InlineListProps<T>) {
  return (
    <>
      {items.map((item, index, { length }) => (
        <span
          key={typeof keyField === 'function' ? keyField(item) : item[keyField]}
        >
          {children(item)}
          {index < length - 1 && <>, </>}
        </span>
      ))}
    </>
  )
}
