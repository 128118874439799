import { gql, FragmentType, useFragment } from 'graphql-generated'

import { EntryTagsBox } from 'apps/browse/common'

const ENTRY_TAGS_FRAGMENT = gql(`
  fragment EntryTags on polls_entity {
    tags(
      where: { tag: { level: { _lte: 2 }, approved: { _eq: true } } }
      order_by: { tag: { level: asc } }
    ) {
      tag {
        id
        name
        level
      }
    }
  }
`)

type Props = {
  entryRef: FragmentType<typeof ENTRY_TAGS_FRAGMENT>
}

export default function EntryTags(props: Props) {
  const data = useFragment(ENTRY_TAGS_FRAGMENT, props.entryRef)
  const tags = data.tags.map(({ tag }) => tag)

  return <EntryTagsBox icon="entry-tags" tags={tags} />
}
