import { useGetText } from 'lib/gettext'
import { ChangeEvent, useCallback, useState } from 'react'

import { LANGUAGES, LANGUAGE_LABELS } from 'common'

type LanguageSelectProps = {
  className?: string
}

export default function LanguageSelect(props: LanguageSelectProps) {
  const { locale, setLocale } = useGetText()
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      setLoading(true)

      await setLocale(event.target.value)

      setLoading(false)
    },
    [setLocale]
  )

  return (
    <select
      className={props.className}
      disabled={loading}
      value={locale}
      onChange={handleChange}
    >
      {LANGUAGES.map((locale) => (
        <option key={locale} value={locale}>
          {LANGUAGE_LABELS[locale]}
        </option>
      ))}
    </select>
  )
}
