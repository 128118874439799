import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { FlexSpinner } from 'common'

import { ToolbarProvider } from 'apps/browse/common'
import SearchForm from './search-form'
import Navigation from './navigation'

export default function Layout() {
  return (
    <ToolbarProvider>
      <div className="w-full py-8 px-4 sm:px-8">
        <SearchForm />
        <Navigation />

        <Suspense fallback={<FlexSpinner />}>
          <Outlet />
        </Suspense>
      </div>
    </ToolbarProvider>
  )
}
