import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryAlternativeNamesBox } from 'apps/browse/common'

import EntryLink from './entry-link'

const ALTERNATIVE_NAMES_FRAGMENT = gql(`
  fragment EntrySearch_Entry_AlternativeNames on Entry {
    entryId: id
    alternative_names {
      id
      name
    }
  }
`)

type AlternativeNamesProps = {
  query: string
  entryRef: FragmentType<typeof ALTERNATIVE_NAMES_FRAGMENT>
}

export default function EntryAlternativeNames(props: AlternativeNamesProps) {
  const { entryId, alternative_names: names } = useFragment(
    ALTERNATIVE_NAMES_FRAGMENT,
    props.entryRef
  )

  return (
    <EntryAlternativeNamesBox names={names}>
      {({ name }) => (
        <EntryLink query={props.query} id={entryId}>
          {name}
        </EntryLink>
      )}
    </EntryAlternativeNamesBox>
  )
}
