import { PropsWithChildren } from 'react'
import classNames from 'classnames'

type Props = PropsWithChildren<{
  tooltipText: string | null | undefined
}>

export default function EntryTooltip({ tooltipText, children }: Props) {
  if (tooltipText && tooltipText.trim() !== '') {
    return (
      <span className="group relative cursor-help underline decoration-dotted">
        {children}
        <span
          className={classNames(
            'absolute bottom-[calc(100%_+_0.5rem)] -left-7 hidden w-[max-content] max-w-[15rem] rounded bg-black-drh py-2 px-3 text-white backdrop-blur-sm group-hover:block sm:left-1/2 sm:-translate-x-1/2',
            'after:absolute after:top-full after:left-8 after:border-x-[6px] after:border-y-4 after:border-transparent after:border-t-black-drh sm:after:left-1/2'
          )}
        >
          {tooltipText}
        </span>
      </span>
    )
  }

  return <>{children}</>
}
