import {
  useState,
  useEffect,
  createContext,
  useContext,
  PropsWithChildren,
  RefObject,
} from 'react'

type HeaderContextValue = {
  isWrapped: boolean
}

const defaultValue: HeaderContextValue = {
  isWrapped: false,
}

export const HeaderContext = createContext<HeaderContextValue>(defaultValue)

type HeaderContextProviderProps = PropsWithChildren<{
  refs: {
    navRef: RefObject<HTMLDivElement>
    wrappedItemRef: RefObject<HTMLDivElement>
  }
}>

export function HeaderContextProvider({
  refs: { navRef, wrappedItemRef },
  children,
}: HeaderContextProviderProps) {
  const [isWrapped, setIsWrapped] = useState(defaultValue.isWrapped)

  useEffect(() => {
    if (navRef.current && wrappedItemRef.current) {
      setIsWrapped(
        navRef.current.offsetTop !== wrappedItemRef.current.offsetTop
      )
    }
  }, [navRef, wrappedItemRef])

  useEffect(() => {
    function handleResize() {
      if (navRef.current && wrappedItemRef.current) {
        setIsWrapped(
          navRef.current.offsetTop !== wrappedItemRef.current.offsetTop
        )
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [navRef, wrappedItemRef])

  return (
    <HeaderContext.Provider value={{ isWrapped }}>
      {children}
    </HeaderContext.Provider>
  )
}

export function useHeaderContext(): HeaderContextValue {
  return useContext(HeaderContext)
}
