import { I_gettext, gettext_noop, useGetText } from 'lib/gettext'

const CE_MESSAGE = gettext_noop('%(year)i CE')
const BCE_MESSAGE = gettext_noop('%(year)i BCE')

export function createFormatYear(
  gettext: I_gettext
): (value: number) => string {
  return (value) =>
    gettext(value >= 0 ? CE_MESSAGE : BCE_MESSAGE, { year: Math.abs(value) })
}

export function useYearFormatter(): (value: number) => string {
  const { gettext } = useGetText()

  return createFormatYear(gettext)
}
