import LegacyProgressBar from 'legacy/apps/progress/components/progress'

import { Entry } from './entry'

type EntryProgressBarProps = {
  className?: string
  entry: Entry
}

export default function EntryProgressBar(props: EntryProgressBarProps) {
  const {
    className,
    entry: { baseProgress, additionalProgress, categories },
  } = props

  return (
    <LegacyProgressBar
      className={className}
      colorTheme="light"
      total={baseProgress.questions}
      answered={baseProgress.answers}
      additionalTotal={additionalProgress.questions}
      additionalAnswered={additionalProgress.answers}
      categories={categories.map(
        ({ name, baseProgress, additionalProgress }) => ({
          progress: {
            total: baseProgress.questions,
            answered: baseProgress.answers,
            additionalTotal: additionalProgress.questions,
            additionalAnswered: additionalProgress.answers,
          },
          name,
        })
      )}
    />
  )
}
