import { AuthenticatedUser, hasAnyRole, hasRole, Role } from './current-user'

export function canAccessEditorConsole(user: AuthenticatedUser): boolean {
  return hasAnyRole(user, [
    Role.REGIONAL_EDITOR,
    Role.MANAGING_EDITOR,
    Role.ACCOUNT_MANAGER,
    Role.SUPERUSER,
  ])
}

export function canAccessPollManager(user: AuthenticatedUser): boolean {
  return hasAnyRole(user, [Role.MANAGING_EDITOR, Role.TRANSLATOR])
}

export function canManagePolls(user: AuthenticatedUser): boolean {
  return hasRole(user, Role.MANAGING_EDITOR)
}

export function canManageTags(user: AuthenticatedUser): boolean {
  return hasAnyRole(user, [Role.MANAGING_EDITOR, Role.STAFF])
}

export function canAccessAdminConsole(user: AuthenticatedUser): boolean {
  return hasRole(user, Role.STAFF)
}

export function canAccessWagtailConsole(user: AuthenticatedUser): boolean {
  return hasRole(user, Role.STAFF)
}

export function hasExtendedAccessToEntries(user: AuthenticatedUser): boolean {
  return hasAnyRole(user, [
    Role.REGIONAL_EDITOR,
    Role.MANAGING_EDITOR,
    Role.SUPERUSER,
  ])
}

export function hasExtendedAccessToVisualize(user: AuthenticatedUser): boolean {
  return hasAnyRole(user, [
    Role.SUPERUSER,
    Role.STAFF,
    Role.REGIONAL_EDITOR,
    Role.MANAGING_EDITOR,
  ])
}

export const canEditAttachment =
  (ownerId: number) => (user: AuthenticatedUser) =>
    user.userId === ownerId
