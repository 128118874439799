import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import classNames from 'classnames'

import 'react-toastify/dist/ReactToastify.css'

import { FlexSpinner } from './spinner'
import Header from './header'
import Footer from './footer'

export default function Layout() {
  return (
    <>
      <div className="flex min-h-screen flex-col">
        <Header />

        <main className="flex flex-grow">
          <Suspense fallback={<FlexSpinner />}>
            <Outlet />
          </Suspense>
        </main>

        <Footer />
      </div>

      <ToastContainer
        className="max-w-prose"
        toastClassName="!rounded-lg"
        bodyClassName="text-base p-3"
        progressClassName={(data) =>
          classNames(data?.defaultClassName, {
            'bg-primary': data?.type === 'default',
          })
        }
        position="bottom-right"
        theme="colored"
        autoClose={5000}
        closeButton={false}
      />
    </>
  )
}
