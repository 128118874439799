import { defer } from 'react-router-dom'
import axios from 'axios'

import { API_URL } from 'config'

type ResponseData = {
  pk: number
  title: string
  two_column_option: boolean
  personnel: {
    pk: number
    username: string
    first_name: string
    last_name: string
    thumbnail: string | null
    occupation: string | null
    department: string | null
    institution: string | null
  }[]
}[]

export type TeamMember = {
  id: number
  username: string
  firstName: string
  lastName: string
  title: string | undefined
  department: string | undefined
  institution: string | undefined
  image: string | undefined
}

type TeamCategory = {
  id: number
  title: string
  members: ReadonlyArray<TeamMember>
  columns: number
}

export type Team = ReadonlyArray<TeamCategory>

function makeTeamFromData(data: ResponseData): Team {
  return data.map(({ pk, title, two_column_option, personnel }) => ({
    id: pk,
    title,
    members: personnel.map(
      ({
        pk,
        username,
        first_name,
        last_name,
        thumbnail,
        occupation,
        department,
        institution,
      }) => ({
        id: pk,
        username,
        firstName: first_name,
        lastName: last_name,
        title: occupation || undefined,
        department: department || undefined,
        institution: institution || undefined,
        image: thumbnail || undefined,
      })
    ),
    columns: two_column_option ? 1 : 2,
  }))
}

async function getTeam() {
  const { data } = await axios.get<ResponseData>(
    `${API_URL}/api/accounts/personnel_category`
  )

  // Filter personnel categories where pk (personnelcategories_id) is 12
  const filteredData = data.filter((category) => category.pk === 12)

  return makeTeamFromData(filteredData)
}

export default function coverageLoader() {
  return defer({ team: getTeam() })
}
