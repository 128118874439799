import { ReactNode } from 'react'
import classNames from 'classnames'

import { FragmentType, gql, useFragment } from 'graphql-generated'
import { UserName, UserNavLink } from 'common'

const EXPERT_LINK_FRAGMENT = gql(`
  fragment ExpertLink on auth_user {
    first_name
    last_name
    username
  }
`)

type ExpertLinkProps = {
  userRef: FragmentType<typeof EXPERT_LINK_FRAGMENT>
  className?: string
  children?: (name: UserName) => ReactNode
}

function ExpertLink(props: ExpertLinkProps) {
  const {
    first_name: firstName,
    last_name: lastName,
    username,
  } = useFragment(EXPERT_LINK_FRAGMENT, props.userRef)

  return (
    <UserNavLink
      className={classNames('link', props.className)}
      firstName={firstName}
      lastName={lastName}
      username={username}
    >
      {props.children}
    </UserNavLink>
  )
}

export default ExpertLink
