import { PropsWithChildren } from 'react'

type GroupOfEntriesTitleProps = PropsWithChildren<{
  sm?: boolean
}>

export function GroupOfEntriesTitle(props: GroupOfEntriesTitleProps) {
  if (props.sm) {
    return (
      <h3 className="mb-8 border-b border-black/10 pb-2 text-2xl font-medium">
        {props.children}
      </h3>
    )
  }

  return (
    <h2 className="mb-8 border-b border-black/10 pb-2 text-3xl font-medium">
      {props.children}
    </h2>
  )
}

type GroupOfEntriesProps = PropsWithChildren<{ id: string }>

export default function GroupOfEntries(props: GroupOfEntriesProps) {
  const { id, children } = props
  const anchor = id.replaceAll(' ', '-').toLowerCase()

  return (
    <div className="-mt-4 py-4" id={anchor}>
      {children}
    </div>
  )
}
