import { PropsWithChildren } from 'react'
import { NavLink, type NavLinkProps } from 'react-router-dom'
import classNames from 'classnames'

type TabNavProps = PropsWithChildren<{ className?: string }>

export default function TabNav({ className, children }: TabNavProps) {
  return (
    <nav
      className={classNames(
        'mb-8 flex flex-wrap gap-x-6 pb-1 text-sm shadow-[inset_0_-1px] shadow-black/10 md:pb-0',
        className
      )}
    >
      {children}
    </nav>
  )
}

type NavLinkNativeProps = NavLinkProps & React.RefAttributes<HTMLAnchorElement>
type LinkNativeProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>
type ButtonNativeProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type TabLinkProps =
  | ({ type: 'link' } & LinkNativeProps)
  | ({ type: 'router-link'; className?: string } & Omit<
      NavLinkNativeProps,
      'className'
    >)
  | ({ type: 'button'; isActive?: boolean } & Omit<ButtonNativeProps, 'type'>)

export const tabClassName =
  'pb-2 uppercase underline decoration-transparent transition hover:decoration-primary md:pb-3 md:hover:decoration-transparent md:hover:shadow-[inset_0_-1px] md:hover:shadow-primary'
export const activeTabClassName =
  'cursor-default hover:decoration-transparent md:shadow-[inset_0_-1px] md:shadow-black-drh md:hover:shadow-black-drh'

export function Tab(props: TabLinkProps) {
  switch (props.type) {
    case 'router-link': {
      const { className: customClassName, children, ...rest } = props

      return (
        <NavLink
          className={({ isActive }) =>
            classNames(tabClassName, customClassName, {
              [activeTabClassName]: isActive,
              'text-primary': !isActive,
            })
          }
          {...rest}
        >
          {children}
        </NavLink>
      )
    }

    case 'link': {
      const { className: customClassName, children, ...rest } = props

      return (
        <a
          className={classNames('text-primary', tabClassName, customClassName)}
          {...rest}
        >
          {children}
        </a>
      )
    }

    case 'button': {
      const { isActive, className: customClassName, children, ...rest } = props

      return (
        <button
          className={classNames(tabClassName, customClassName, {
            [activeTabClassName]: isActive,
            'text-primary': !isActive,
          })}
          {...rest}
          type="button"
        >
          {children}
        </button>
      )
    }
  }
}
