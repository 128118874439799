import { lazy } from 'react'

export const AnalyzePageLayout = lazy(() => import('./layout'))
export const PlotPageLayout = lazy(() => import('./plot-page-layout'))
export const ChartSelect = lazy(() => import('./chart-select'))
export const SavedQueryList = lazy(
  () => import('./saved-queries/saved-query-list')
)
export const SavedQuery = lazy(() => import('./saved-queries/saved-query-page'))
export const BarChart = lazy(() => import('./bar-chart'))
export const Timeline = lazy(() => import('./timeline'))
export const ScatterPlot = lazy(() => import('./scatter-plot'))
export const QuestionAnswersComparison = lazy(
  () => import('./question-answers-comparison')
)
export const EntryAnswersComparison = lazy(
  () => import('./entry-answers-comparison')
)
