import { NavLink } from 'react-router-dom'

import { useGetText } from 'lib/gettext'
import {
  Spinner,
  TableHeadRow,
  TableHeadCell,
  TableBodyRow,
  TableBodyCell,
  Timestamp,
  UserNavLink,
} from 'common'
import useSuggestedEntries from './use-suggested-entries'

export default function SuggestedPolls() {
  const { gettext } = useGetText()

  const { data, loading } = useSuggestedEntries()

  return (
    <section>
      <h3 className="mb-4 text-3xl font-semibold">
        {gettext('Suggested Entries')}
      </h3>

      {loading ? (
        <Spinner className="my-6" />
      ) : data && data.length > 0 ? (
        <table className="w-full">
          <thead>
            <TableHeadRow>
              <TableHeadCell>{gettext('Entry name')}</TableHeadCell>
              <TableHeadCell>{gettext('Date modified')}</TableHeadCell>
              <TableHeadCell>{gettext('Contributor(s)')}</TableHeadCell>
            </TableHeadRow>
          </thead>

          <tbody className="text-neutral-600">
            {data.map((entry) => (
              <TableBodyRow key={entry.id}>
                <TableBodyCell>
                  <NavLink
                    className="link text-lg font-semibold leading-5"
                    to={`/questionnaire/${entry.id}`}
                  >
                    {entry.name}
                  </NavLink>
                </TableBodyCell>

                <TableBodyCell>
                  <Timestamp date={entry.dateModified} />
                </TableBodyCell>

                <TableBodyCell>
                  {entry.contributors.map((contributor) => (
                    <UserNavLink
                      key={contributor.id}
                      {...contributor}
                      className="link [&:not(:last-child)]:after:content-[',_']"
                    />
                  ))}
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </tbody>
        </table>
      ) : null}
    </section>
  )
}
