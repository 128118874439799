import { useEntrySearchSorting, useToolbar } from '../common'

import { useSearchQuery } from './search-query'
import SearchResults from './search-results'
import { useEntrySearch, useEntrySearchLoaderData } from './loader'

type EntrySearchProps = {
  pageSize: number
}

export default function EntrySearch(props: EntrySearchProps) {
  const { pageSize } = props

  const firstPage = useEntrySearchLoaderData()

  const query = useSearchQuery()
  const sort = useEntrySearchSorting()

  useToolbar({ sorting: false, filter: false, searchSorting: true })

  const { entries, count, hasNextPage, loadNextPage, loadingNextPage } =
    useEntrySearch({
      query,
      pageSize,
      sort,
      initialEndCursor: firstPage.endCursor,
      initialEntries: firstPage.entries,
      initialCount: firstPage.count,
      initialHasNextPage: firstPage.hasNextPage,
    })

  return (
    <SearchResults
      className="mb-16"
      query={query}
      pageSize={pageSize}
      entries={entries}
      count={count}
      hasNextPage={hasNextPage}
      loadingNextPage={loadingNextPage}
      loadNextPage={loadNextPage}
    />
  )
}
