import { CSSProperties } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  color?: 'blue' | 'white'
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
  style?: CSSProperties
}

export default function Spinner({
  className,
  color = 'blue',
  size = 'md',
  style,
}: Props) {
  return (
    <div
      style={style}
      className={classNames(
        'mx-auto aspect-square animate-spinner rounded-full',
        {
          'border-[6px]': size === 'md' || size === 'lg',
          'w-4 border-2': size === 'xxs',
          'w-6 border-[3px]': size === 'xs',
          'w-12 border-4': size === 'sm',
          'w-24': size === 'md',
          'w-40': size === 'lg',
          'border-primary': color === 'blue',
          'border-white': color === 'white',
        },
        className
      )}
    />
  )
}

export function FlexSpinner(props: Props) {
  return (
    <div className="flex flex-grow items-center">
      <Spinner {...props} />
    </div>
  )
}

type InlineSpinnerProps = {
  color?: 'blue' | 'white'
}

export function InlineSpinner({ color = 'blue' }: InlineSpinnerProps) {
  return (
    <span className="relative inline-block h-0 w-[1em]">
      <span
        className={classNames(
          'absolute -top-[0.875em] block aspect-square w-[1em] animate-spinner rounded-full border-[0.15em]',
          {
            'border-primary': color === 'blue',
            'border-white': color === 'white',
          }
        )}
      />
    </span>
  )
}
