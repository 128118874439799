import { gqlRequest } from 'api'
import { gql } from 'graphql-generated'
import { Browse_EntriesByPublicationDateQuery } from 'graphql-generated/graphql'
import { useLoaderData } from 'react-router-dom'

const QUERY = gql(`
  query Browse_EntriesByPublicationDate {
    ...EntryPublicationDates
    ...ListEntriesByPublicationDate
  }
`)

export async function entriesByPublicationDateLoader(): Promise<Browse_EntriesByPublicationDateQuery> {
  return gqlRequest(QUERY)
}

export function useEntriesByPublicationDateLoaderData(): Browse_EntriesByPublicationDateQuery {
  return useLoaderData() as Browse_EntriesByPublicationDateQuery
}
