import React from 'react'
import _ from 'lodash'
import $ from 'jquery'
import flatten from 'flat'
import isUrl from 'is-url'

import config from 'legacy/common/config'
import moment from './localized-moment'

export function setDirtyState(form, errors) {
  // TODO: Refactor this method when react-form-tools #12 will be implemented
  _.forEach(_.keys(flattenErrors(errors)), (fieldPath) =>
    form.setDirtyState(fieldPath)
  )
}

export function flattenErrors(errors) {
  return _.mapKeys(flatten(errors), (value, key) =>
    key.split('.').slice(0, -1).join('.')
  )
}

function getInputElement($element) {
  return $element.find('input, select, textarea')
}

function hightlightElements(elements) {
  _.forEach(elements, ($element) => {
    const $inputElem = getInputElement($element)
    if ($inputElem && $inputElem.attr('type') !== 'file') {
      $inputElem.effect('highlight', { color: '#F00' })
    }
  })
}

function findParentModalOrBody($element) {
  let $curElement = $element.parent()

  while ($curElement.length) {
    if (
      $curElement.prop('tagName') === 'BODY' ||
      $curElement.prop('className') === 'modal-body'
    ) {
      return $curElement
    }

    $curElement = $curElement.parent()
  }

  return $curElement
}

function processErrorsHandler(errors) {
  // TODO: Element finding should be only in current form
  // TODO: Implement common behaviour via form class instead of modal
  const $modal = $('.modal-body')
  const isModal = !!$modal.length

  const elements = _.compact(
    _.map(_.keys(flattenErrors(errors)), (fieldPath) => {
      const fieldSelector = `[data-field-path="${fieldPath}"]`
      const $element = isModal ? $modal.find(fieldSelector) : $(fieldSelector)

      if (!$element.length) {
        return null
      }

      return $element
    })
  )

  if (!elements.length) {
    return
  }

  const $topElement = _.minBy(elements, ($element) => $element.offset().top)
  findParentModalOrBody($topElement).scrollTo($topElement, 'fast', {
    onAfter: () => {
      hightlightElements(elements)
      getInputElement($topElement).focus()
    },
  })
}

export function processErrors(errors) {
  setTimeout(() => processErrorsHandler(errors), 100)
}

export function parseDate(date) {
  return moment(date, config.serverDateFormat)
}

export function formatPublishedDate(date) {
  const momentDate = parseDate(date)

  return defaultFormatDate(momentDate)
}

export function defaultFormatDate(parsedDate) {
  const format = `MMM D${parsedDate.year() === moment().year() ? '' : ' YYYY'}`

  return parsedDate.format(format)
}

export function toModifier(name) {
  return `_${name}`
}

export function makeKey(...args) {
  return _.map(args, (arg) => arg || 0).join('.')
}

export function asyncEach(array, iterator) {
  /*
    Iterates over array with Promise-like objects
    which returned by iterator function

    return stopIteration() for next stop iteration.
  */

  function iterate(index) {
    if (index === array.length) {
      return Promise.resolve()
    }

    const promise = iterator(array[index], index)

    if (!promise) {
      return iterate(index + 1)
    }

    return promise.then(() => iterate(index + 1))
  }

  return iterate(0)
}

export function stopIteration() {
  return Promise.reject('StopIteration')
}

export function waitForLoaded(cursor, timeout = 10000) {
  let timeoutId

  return new Promise((resolve, reject) => {
    const updateHandler = ({ data }) => {
      if (!data.currentData.isLoaded) {
        return
      }

      clearTimeout(timeoutId)
      cursor.off('update', updateHandler)
      resolve(data.currentData.data)
    }

    if (cursor.get('isLoaded')) {
      resolve(cursor.get('data'))
    } else {
      timeoutId = setTimeout(() => {
        cursor.off('update', updateHandler)
        reject()
      }, timeout)
      cursor.on('update', updateHandler)
    }
  })
}

export function buildQuery(params) {
  return _.chain(params)
    .map(
      (param, key) => `${encodeURIComponent(key)}=${encodeURIComponent(param)}`
    )
    .join('&')
    .value()
}

export function getCursorByPath(cursor, path) {
  if (!path.length) {
    return cursor
  }

  const { type, pk } = _.head(path)
  const typeCursor = cursor.select(type)
  const itemCursor = typeCursor.select('data', { pk })

  return getCursorByPath(itemCursor, _.tail(path))
}

export function getParentCursor(cursor, type) {
  let parentCursor = cursor.up()

  while (parentCursor) {
    const parentCursorValue = parentCursor.get()
    if (!parentCursorValue) {
      return null
    }

    if (_.isArray(parentCursorValue)) {
      parentCursor = parentCursor.up()
      continue
    }

    if (parentCursorValue.type) {
      if (type) {
        if (parentCursorValue.type === type) {
          return parentCursor
        }
      } else {
        return parentCursor
      }
    }

    parentCursor = parentCursor.up()
  }

  return null
}

export function collapseAccordion(cursor) {
  cursor.set('collapsed', true)
}

export function expandAccordion(cursor) {
  cursor.set('collapsed', false)
}

export function preparePath(path) {
  /*
   * Changes answerSets to existingAnswerSets and changes questions
   * to subQuestions if questions already iterated
   */
  let isFirstQuestion = true

  return _.map(path, ({ type, pk }) => {
    if (type === 'answerSets') {
      return { type: 'existingAnswerSets', pk }
    }

    if (type === 'questions') {
      if (isFirstQuestion) {
        isFirstQuestion = false
      } else {
        return { type: 'subQuestions', pk }
      }
    }

    return { type, pk }
  })
}

export function getQuestionPath(answerSetCursor, answerSetPks) {
  /*
   * Returns full path to question including
   * branching questions, categories, groups, answer sets, sub questions.
   *
   * `answerSetPks` are used when answer set is created just now
   */
  let path = []
  let curAnswerSetIndex = 0
  let cursor = answerSetCursor

  while (cursor) {
    const type = cursor.get('type')

    if (type === 'polls') {
      break
    }

    const pk =
      type === 'answerSets' && curAnswerSetIndex < answerSetPks.length
        ? answerSetPks[curAnswerSetIndex++]
        : cursor.get('pk')

    path.unshift({ type, pk })

    cursor = getParentCursor(cursor)
  }

  return _.initial(path)
}

export function isQuestionPathContains(questionPath, subQuestionPath) {
  return _.isEqual(
    _.slice(questionPath, 0, subQuestionPath.length),
    subQuestionPath
  )
}

export function renderList(array, fn) {
  fn = fn || _.identity

  return _.map(array, (item, index) => (
    <span key={index}>
      {fn(item)}
      {array.length - 1 !== index ? ', ' : null}
    </span>
  ))
}

export function isSetEqual(a, b, sortIteratees) {
  return _.isEqual(_.sortBy(a, sortIteratees), _.sortBy(b, sortIteratees))
}

export function tokenize(str) {
  // Returns tokens from str. It works like standard tokenizer in elasticsearch
  return _.compact(_.split(_.toLower(str), /[ .,:;?!~#$%\\/|&*+=\-`^"'<>(){}\[\]_]/));  // eslint-disable-line
}

export function truncateTextAroundPos(text, pos, wordsCount) {
  /*
   Truncates `text` by start and end according to initial `pos`.
   Remains `wordsCount` words from both sides.
   */
  let startPos = pos
  let remainedWords = wordsCount + 1

  while (remainedWords) {
    if (startPos - 1 < 0) {
      break
    }
    startPos -= 1

    if (text.charAt(startPos) === ' ') {
      remainedWords -= 1
    }
  }

  let endPos = pos
  remainedWords = wordsCount + 1

  while (remainedWords) {
    if (endPos + 1 === text.length) {
      break
    }

    endPos += 1

    if (text.charAt(endPos) === ' ') {
      remainedWords -= 1
    }
  }

  let textPart = text.substring(startPos, endPos + 1).trim()
  if (startPos > 0) {
    textPart = `…${textPart}`
  }

  if (endPos < text.length - 1) {
    textPart = `${textPart}…`
  }

  return textPart
}

export function truncateText(text, query, wordsCount = 4) {
  /*
   Truncates `text` by start and end according to `query`.
   If there is no `query` inside text, function will return
   part of text from beginning.
   Remains `wordsCount` words from both sides.
   */
  const regExpQuery = new RegExp(_.join(tokenize(query), '|'), 'i')
  const pos = text.search(regExpQuery)

  if (pos === -1) {
    return truncateTextAroundPos(text, 0, wordsCount)
  }

  return truncateTextAroundPos(text, pos, wordsCount)
}

export function calcPercent(x, y, points = 2) {
  return y ? _.round((x / y) * 100, points) : 0
}

export function nl2br(str) {
  const newlineRegex = /(\r\n|\r|\n)/g

  if (typeof str === 'number') {
    return str
  } else if (typeof str !== 'string') {
    return ''
  }

  return str.split(newlineRegex).map((line, index) => {
    if (line.match(newlineRegex)) {
      return <br key={index} />
    }

    return <span key={index}>{line}</span>
  })
}

export function getBoolOrNullFromTwoValues(trueValue, falseValue) {
  /*
   This method is useful when you have two boolean fields which represents
   one boolen value.

   Returns true or false depending on `trueValue`
   if both `trueValue` and `falseValue` are different.
   Otherwise returns null
  */
  if (trueValue ^ falseValue) {
    return trueValue
  }

  return null
}

export function getBoolStrOrNullFromTwoValues(trueValue, falseValue) {
  // This function behaves like getBoolOrNullFromTwoValues but only
  // returns python str representation for boolean
  if (trueValue ^ falseValue) {
    return trueValue ? 'True' : 'False'
  }

  return null
}

export function isQueryFoundInText(query, text) {
  const lowerCaseText = _.toLower(text)
  const queryWords = tokenize(query)

  const matchResults = _.map(
    queryWords,
    (word) => lowerCaseText.indexOf(word) !== -1
  )

  return _.every(matchResults)
}

function isCharSymbol(char) {
  const pattern = ' .,:;?!~#$%\\/|&*+=-`^"\'<>(){}[]_'

  return pattern.indexOf(char) === -1
}

function addSpaceSymbol(text) {
  const spaceSymbol = '\u00A0'
  const words = _.split(text, ' ')
  const lastWordIndex = words.length - 1
  const joinedText = _.join(_.take(words, lastWordIndex), ' ')

  return `${joinedText}${spaceSymbol}${words[lastWordIndex]}...`
}

export function getShortText(text, limit = 80) {
  let currentLength = limit + 1
  let nextSymbol = text.charAt(currentLength)

  if (text.length <= limit) {
    return text
  }

  while (isCharSymbol(nextSymbol) && currentLength < text.length) {
    currentLength += 1
    nextSymbol = text.charAt(currentLength)
  }

  // checks if truncation stopped at last word or before the last symbol in the text
  if (currentLength === text.length || currentLength + 1 === text.length) {
    return text
  }

  return addSpaceSymbol(text.substr(0, currentLength))
}

export function truncateTextInTheMiddle(text, maxLength = 44) {
  const middle = maxLength / 2
  const start = text.slice(0, middle + 1)
  const end = text.slice(text.length - middle, text.length + 1)

  return `${start}...${end}`
}

export function isSubSet(subSet, superSet) {
  return _.difference(subSet, superSet).length === 0
}

export function saveFromUrl(url) {
  const link = document.createElement('a')
  link.href = url
  link.download = url.split('/').pop()
  link.dispatchEvent(new MouseEvent('click'))
}

export function isValidUrl(url) {
  return (
    (_.startsWith(url, 'http://') || _.startsWith(url, 'https://')) &&
    isUrl(url)
  )
}

export function humanFileSize(size) {
  const i = size ? Math.floor(Math.log(size) / Math.log(1024)) : 0
  const preparedSize = (size / Math.pow(1024, i)).toFixed(2) * 1
  const preparedSuffix = ['B', 'kB', 'MB', 'GB', 'TB'][i]

  return `${preparedSize} ${preparedSuffix}`
}
