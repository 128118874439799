import { useEffect, cloneElement, PropsWithChildren, ReactElement } from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'

export type ModalProps = PropsWithChildren<{
  title: string | ReactElement
  size?: 'medium' | 'large' | 'fullscreen'
  onClose: () => void
  stretchVertically?: boolean
  as?: JSX.Element
}>

export default function Modal(props: ModalProps) {
  useEffect(() => {
    const initialBodyOverflow = document.body.style.overflow

    document.body.style.overflow = 'hidden'

    return function cleanup() {
      document.body.style.overflow = initialBodyOverflow
    }
  }, [])

  const {
    title,
    size = 'fullscreen',
    onClose,
    stretchVertically = false,
    children,
    as,
  } = props

  return createPortal(
    <div className="fixed top-0 left-0 z-50 flex h-screen w-full items-center justify-center bg-gray-drh">
      <ModalContainer
        as={as}
        className={classNames(
          'flex flex-col bg-white',
          { 'm-8 max-h-[80vh] w-[40rem] max-w-full': size === 'medium' },
          { 'm-8 max-h-[80vh] w-[64rem] max-w-full': size === 'large' },
          { 'h-[80vh]': stretchVertically },
          { '!h-full w-full': size === 'fullscreen' }
        )}
      >
        <header className="flex py-6 pr-6 pl-8 shadow-[inset_0_-1px_0_0] shadow-black/10">
          <h1 className="flex-grow text-2xl font-bold leading-8">{title}</h1>
          <button
            type="button"
            className="ml-8 h-8 w-8 cursor-pointer bg-close-icon bg-center bg-no-repeat transition-transform hover:scale-105"
            onClick={onClose}
          />
        </header>

        {children}
      </ModalContainer>
    </div>,
    document.body
  )
}

function ModalContainer({
  as,
  className,
  children,
}: PropsWithChildren<{ as?: JSX.Element; className: string }>) {
  return cloneElement(as || <div />, { className }, children)
}

export function ModalContent({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-grow flex-col overflow-y-scroll p-8">
      {children}
    </div>
  )
}

type FooterProps = PropsWithChildren<{ className?: string }>

export function ModalFooter(props: FooterProps) {
  return (
    <footer
      className={classNames(
        'mt-auto flex items-center justify-end py-4 px-8 shadow-[inset_0_1px_0_0] shadow-black/10',
        props.className
      )}
    >
      {props.children}
    </footer>
  )
}
