import { useCallback, useEffect } from 'react'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { decodeJwt } from 'jose'
import * as yup from 'yup'
import useLocalStorageState from 'use-local-storage-state'
import { differenceInSeconds, fromUnixTime } from 'date-fns'
import { decode, encode } from 'js-base64'

export type Credentials = { username: string; password: string }

type Claims = {
  userId: number
  expertId: number
  regionalEditorId: number | undefined
  managingEditorId: number | undefined
  exp: Date
  roles: ReadonlyArray<string>
  su: ReadonlyArray<unknown> | undefined
}

export type Token = { accessId: string; refreshId: string; claims: Claims }

const PAYLOAD_SCHEMA = yup
  .object()
  .shape({
    sub: yup.number().integer().required(),
    exp: yup.number().integer().required(),
    'https://religiondatabase.org': yup
      .object()
      .shape({
        'x-religiondatabase-regional-editor-id': yup.number().integer(),
        'x-religiondatabase-managing-editor-id': yup.number().integer(),
        'x-religiondatabase-expert-id': yup.number().integer().required(),
        'x-religiondatabase-roles': yup
          .array()
          .of(yup.string().required())
          .required(),
        'x-religiondatabase-su': yup.array(),
      })
      .required(),
  })
  .required()

function decodeTokenPayload(token: string): Claims {
  const {
    sub: userId,
    exp,
    'https://religiondatabase.org': customClaims,
  } = PAYLOAD_SCHEMA.validateSync(decodeJwt(token))

  return {
    userId,
    expertId: customClaims['x-religiondatabase-expert-id'],
    regionalEditorId: customClaims['x-religiondatabase-regional-editor-id'],
    managingEditorId: customClaims['x-religiondatabase-managing-editor-id'],
    exp: fromUnixTime(exp),
    roles: customClaims['x-religiondatabase-roles'],
    su: customClaims['x-religiondatabase-su'],
  }
}

export function makeToken(data: { access: string; refresh: string }): Token {
  const { access: accessId, refresh: refreshId } = data

  return {
    accessId,
    refreshId,
    claims: decodeTokenPayload(accessId),
  }
}

export function isExpired(token: Token): boolean {
  return differenceInSeconds(token.claims.exp, new Date()) < 20
}

export function userIdClaim(token: Token): number {
  return token.claims.userId
}

export function expertIdClaim(token: Token): number {
  return token.claims.expertId
}

export function rolesClaim(token: Token): ReadonlyArray<string> {
  return token.claims.roles
}

export function regionalEditorIdClaim(token: Token): number | undefined {
  return token.claims.regionalEditorId
}

export function managingEditorIdClaim(token: Token): number | undefined {
  return token.claims.managingEditorId
}

export function suClaim(token: Token): ReadonlyArray<unknown> | undefined {
  return token.claims.su
}

function validateTokenData(data: unknown): {
  access: string
  refresh: string
} {
  return yup
    .object()
    .shape({
      access: yup.string().required(),
      refresh: yup.string().required(),
    })
    .required()
    .validateSync(data)
}

function deserializeFromStorage(value: unknown): Token {
  const data = validateTokenData(
    JSON.parse(decode(yup.string().required().validateSync(value)))
  )

  return makeToken(data)
}

function serializeForStorage(token: Token): string {
  return encode(
    JSON.stringify({ access: token.accessId, refresh: token.refreshId })
  )
}

export function useWatchToken(
  onChange: (token: Token | undefined) => Promise<void>
) {
  const [value] = useLocalStorageState('token', {
    serializer: {
      parse: (value) => value,
      stringify: (value) => JSON.stringify(value),
    },
  })

  useEffect(() => {
    const handleChange = async () => {
      const token = value ? deserializeFromStorage(value) : undefined

      onChange(token)
    }

    handleChange()
  }, [value, onChange])
}

export function getToken(): Token | undefined {
  const value = localStorage.getItem('token')

  if (value) {
    return deserializeFromStorage(value)
  }

  return undefined
}

export function setToken(maybeData: unknown): Token {
  const token = makeToken(validateTokenData(maybeData))

  localStorage.setItem('token', serializeForStorage(token))

  return token
}

export function removeToken(): void {
  localStorage.removeItem('token')
}

export enum CreateTokenFailureCode {
  INVALID_CREDENTIALS = 'invalid-credentials',
}

export function useCreateToken(
  onSuccess: (token: Token) => void,
  onFailure: (failure: CreateTokenFailureCode) => void
): (credentials: Credentials) => Promise<void> {
  const createToken = useCallback(
    async (credentials: Credentials) => {
      try {
        const { data } = await axios.post('/api/token', credentials)

        const token = setToken(data)

        return onSuccess(token)
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.status === StatusCodes.UNAUTHORIZED
        ) {
          return onFailure(CreateTokenFailureCode.INVALID_CREDENTIALS)
        }

        throw error
      }
    },
    [onSuccess, onFailure]
  )

  return createToken
}

const REFRESH_RESPONSE_SCHEMA = yup
  .object()
  .shape({
    access: yup.string().required(),
  })
  .required()

const refreshToken = (function () {
  let refresher: Promise<Token> | undefined

  return async function (token: Token): Promise<Token> {
    if (refresher) return refresher

    refresher = axios
      .post('/api/token/refresh', { refresh: token.refreshId })
      .then(({ data }) => {
        const { access } = REFRESH_RESPONSE_SCHEMA.validateSync(data)

        // Set a new token using up-to-date access Id token, but keep the original refresh Id token.
        return setToken({ access, refresh: token.refreshId })
      })
      .catch((reason) => {
        if (
          axios.isAxiosError(reason) &&
          reason.response?.status === StatusCodes.UNAUTHORIZED
        ) {
          removeToken()
        }

        return reason
      })
      .finally(() => (refresher = undefined))

    return refresher
  }
})()

export async function getAccessTokenId(): Promise<string | undefined> {
  const token = getToken()

  if (token) {
    return isExpired(token)
      ? refreshToken(token).then(({ accessId }) => accessId)
      : token.accessId
  }

  return undefined
}

/**
 * Used to refresh current token. Throws an exception when there is no token in the local storage.
 */
export async function forceTokenRefresh(): Promise<void> {
  const token = getToken()

  if (token) {
    await refreshToken(token)

    return
  }

  throw new Error('Unexpected attempt to refresh undefined current token')
}
