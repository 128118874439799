import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryTagsBox } from 'apps/browse/common'

const TAGS_FRAGMENT = gql(`
  fragment EntrySearch_Entry_Tags on Entry {
    tags {
      id
      name
    }
  }
`)

type EntryTagsProps = {
  entryRef: FragmentType<typeof TAGS_FRAGMENT>
  query: string
}

export default function EntryTags(props: EntryTagsProps) {
  const tags =
    useFragment(TAGS_FRAGMENT, props.entryRef).tags?.filter(
      (tag): tag is { id: number; name: string } => tag !== null
    ) || []

  return <EntryTagsBox icon="entry-tags" tags={tags} highlight={props.query} />
}
