import classNames from 'classnames'

type ChevronProps = {
  className?: string
  size?: 'small' | 'medium' | 'large'
  rotateWhen?: boolean
  isDisabled?: boolean
}

export function Chevron(props: ChevronProps) {
  const {
    className,
    rotateWhen: rotate,
    size = 'medium',
    isDisabled = false,
  } = props

  return (
    <svg
      className={classNames(
        'fill-transparent stroke-black-drh transition-transform',
        {
          'rotate-90': rotate,
          'h-3 w-3 stroke-1': size === 'small',
          'h-4 w-4 stroke-2': size === 'medium',
          'h-6 w-6 stroke-2': size === 'large',
          'stroke-black/25': isDisabled,
        },
        className
      )}
      viewBox={
        size === 'small'
          ? '0 0 12 12'
          : size === 'medium'
          ? '0 0 16 16'
          : '0 0 24 24'
      }
      strokeLinecap="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          size === 'small'
            ? 'M3.75 10.5L8.25 6L3.75 1.5'
            : size === 'medium'
            ? 'M5 14L11 8L5 2'
            : 'M8 22L18 12L8 2'
        }
      />
    </svg>
  )
}

type CheckMarkProps = { className?: string }

export function CheckMark({ className }: CheckMarkProps) {
  return (
    <svg
      className={classNames(
        'h-3 w-3 fill-transparent stroke-black-drh stroke-1',
        className
      )}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      strokeLinecap="round"
    >
      <path d="M1 7L4.5 11L11 3" />
    </svg>
  )
}

type CaretProps = {
  className?: string
  rotateWhen?: boolean
}

export function Caret({ className, rotateWhen = false }: CaretProps) {
  return (
    <svg
      className={classNames(
        'inline-block h-[5px] w-2 fill-primary transition',
        {
          'rotate-180': rotateWhen,
        },
        className
      )}
      viewBox="0 0 8 5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 .5c0-.273-.227-.5-.5-.5h-7C.227 0 0 .227 0 .5a.5.5 0 0 0 .148.352l3.5 3.5A.497.497 0 0 0 4 4.5a.497.497 0 0 0 .352-.148l3.5-3.5A.497.497 0 0 0 8 .5Z" />
    </svg>
  )
}
