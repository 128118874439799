import { Link } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme'

import { GetText, useGetText } from 'lib/gettext'
import { CONTACT_EMAIL } from 'config'
import FacebookIcon from './FacebookIcon'
import TwitterIcon from './TwitterIcon'

import LOGO_LEFT from './logo-left.svg'
import LOGO_CENTER from './logo-center.svg'

export default function LandingFooter() {
  const { gettext } = useGetText()
  const lgBreakpoint = defaultTheme.screens.lg

  const menu = [
    { url: '/landing/about/about-the-drh', text: gettext('About the DRH') },
    { url: '/landing/about/drh-news', text: gettext('DRH News') },
    {
      url: '/landing/about/impact',
      text: gettext('Impact'),
    },
    { url: '/landing/about/people', text: gettext('People') },
    { url: '/landing/about/funding', text: gettext('Funding') },
    {
      url: '/landing/polls/overview-of-polls',
      text: gettext('Overview of Polls'),
    },
    { url: '/landing/polls/tagging-trees', text: gettext('Tagging Trees') },
    { url: '/landing/faq', text: gettext('FAQ') },
    { url: '/landing/terms-of-use', text: gettext('Terms of Use') },
  ]

  return (
    <footer className="bg-black/5 py-12 text-gray-drh shadow-[inset_0_1px_0_0] shadow-black/10">
      <div className="container mx-auto grid grid-cols-4 gap-4 px-4">
        <div className="col-span-4 mb-4 text-center lg:col-span-1 lg:mb-0 lg:text-left">
          <Link className="mb-4 inline-block" to="./">
            <picture>
              <source
                media={`(min-width: ${lgBreakpoint})`}
                srcSet={LOGO_LEFT}
              />
              <source
                media={`(max-width: ${lgBreakpoint})`}
                srcSet={LOGO_CENTER}
              />
              <img src={LOGO_LEFT} alt="UBC DRH logo" />
            </picture>
          </Link>

          <p className="flex items-end justify-center lg:justify-start">
            <span>{gettext('Follow DRH:')}</span>{' '}
            <a href="https://www.facebook.com/religiondatabase?fref=ts">
              <FacebookIcon className="ml-2 inline-block fill-primary-darkest transition-colors hover:fill-primary" />
            </a>
            <a href="https://twitter.com/ReligionHistory">
              <TwitterIcon className="ml-1 inline-block fill-primary-darkest transition-colors hover:fill-primary" />
            </a>
          </p>
        </div>

        <div className="col-span-4 lg:col-span-3">
          <div className="mb-6 gap-x-4 lg:grid lg:grid-cols-3">
            <ul className="mb-6 grid gap-x-4 gap-y-2 sm:auto-cols-fr sm:grid-flow-col sm:grid-rows-5 lg:col-span-2 lg:mb-0">
              {menu.map(({ url, text }) => (
                <li key={url}>
                  <Link
                    className="link block rounded-md border border-primary px-4 py-2 text-center uppercase lg:inline-block lg:border-none lg:px-0 lg:py-0 lg:text-left lg:normal-case"
                    to={url}
                  >
                    {text}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="col-span-2 grid gap-4 sm:grid-cols-2 lg:col-auto lg:grid-cols-none">
              <p>
                <GetText components={{ br: <br /> }}>
                  607 1871 West Mall [br/]Vancouver, BC [br/]Canada V6T 1Z2
                </GetText>
              </p>

              <p>
                <GetText
                  components={{
                    contactEmail: (
                      <a className="link" href={`mailto:${CONTACT_EMAIL}`}>
                        {CONTACT_EMAIL}
                      </a>
                    ),
                  }}
                >
                  For any questions contact [contactEmail/]
                </GetText>
              </p>
            </div>
          </div>

          <p className="col-span-3">
            <GetText components={{ year: <>{new Date().getFullYear()}</> }}>
              © [year/] Database of Religious History. The University of British
              Columbia.
            </GetText>
          </p>
        </div>
      </div>
    </footer>
  )
}
