import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'

import {
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
} from './config'
import App from './App'

import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/staging.religiondatabase\.org\/api/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)
}

/**
 * We've temporary disabled React Strict Mode to make the legacy work using React v18.
 * The new React's concurrent renderer is not compatible with deprecated life cycle methods like UNSAFE_componentWillReceiveProps.
 * Most of the legacy code is based on the SchemaBranchMixin that is being used for the state management in a very counterintuitive way.
 */
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

const SendAnalytics = () => {
  if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    })
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics)
