import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'

import { GetText } from 'lib/gettext'

import { formatPublishedDate } from 'legacy/libs/utils'
import s from './published-state.module.scss'

export default createReactClass({
  displayName: 'PublishedState',

  propTypes: {
    className: PropTypes.string,
    draft: PropTypes.number,
    published: PropTypes.number,
    datePublished: PropTypes.string,
  },

  render() {
    const { draft, published, datePublished, className } = this.props

    if (published) {
      return (
        <div className={className}>
          <div className={s.date}>{formatPublishedDate(datePublished)}</div>
          <div className={s.text}>
            {draft ? (
              <GetText
                message="%(count)i new question answered but not published"
                messagePlural="%(count)i new questions answered but not published"
                count={draft}
              />
            ) : (
              <GetText>All answered questions are published</GetText>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className={s.text}>
        <GetText>This entry has not been published</GetText>
      </div>
    )
  },
})
