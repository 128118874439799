import { PropsWithChildren } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { DEBUG, SUPPORT_EMAIL } from 'config'
import { PrimaryButton } from 'common'

function DebugError({ error }: { error: unknown }) {
  const name = error instanceof Error ? error.name : null
  const message = error instanceof Error ? error.message : 'Unknown reason'
  const stack = error instanceof Error ? error.stack : null

  return (
    <div className="p-6">
      <h1 className="mb-3 text-xl md:text-2xl lg:text-3xl">
        ❌ {name ? `Uncaught ${name}` : 'Unknown error'}: {message}
      </h1>
      {stack && (
        <pre className="w-full overflow-x-scroll font-mono text-xs text-neutral-600 md:text-base">
          {stack}
        </pre>
      )}
    </div>
  )
}

type UncaughtErrorProps = {
  className?: string
}

function UncaughtError(props: UncaughtErrorProps) {
  return (
    <div className={props.className}>
      <h1 className="mb-5 text-3xl">❌ Something went wrong</h1>

      <p className="mb-5 text-base text-neutral-600 md:text-lg">
        We are sorry, the system has encountered an error and cannot complete
        your request.
        <br />
        If the issue persists and you require support please contact us at{' '}
        <a
          className="link"
          href={`mailto:${SUPPORT_EMAIL}`}
          target="_blank"
          rel="noreferrer"
        >
          {SUPPORT_EMAIL}
        </a>
        .
      </p>

      <div className="flex flex-col sm:flex-row">
        <PrimaryButton onClick={() => window.location.reload()}>
          ↻ Reload
        </PrimaryButton>
        <a
          className="ml-auto inline-flex self-center text-neutral-500 underline hover:no-underline"
          href="/"
        >
          Return to the home page
        </a>
      </div>
    </div>
  )
}

type RootErrorFallbackProps = {
  error: Error
}

function RootErrorFallback(props: RootErrorFallbackProps) {
  if (DEBUG) {
    return <DebugError error={props.error} />
  }

  return (
    <main className="flex min-h-screen flex-col items-center justify-center">
      <UncaughtError className="w-3/4 lg:w-1/2 xl:w-2/5" />
    </main>
  )
}

type RootErrorBoundaryProps = PropsWithChildren

export default function RootErrorBoundary(props: RootErrorBoundaryProps) {
  return (
    <ErrorBoundary FallbackComponent={RootErrorFallback}>
      {props.children}
    </ErrorBoundary>
  )
}
