import classNames from 'classnames'
import { PropsWithChildren } from 'react'

export function TableHeadRow({ children }: PropsWithChildren) {
  return (
    <tr className="border-b border-black/10 text-left align-bottom text-xs text-gray-drh">
      {children}
    </tr>
  )
}

export function TableBodyRow({ children }: PropsWithChildren) {
  return (
    <tr className="border-b border-black/5 align-baseline last:border-b-0">
      {children}
    </tr>
  )
}

type TableHeadCellProps = PropsWithChildren<{ className?: string }>

export function TableHeadCell(props: TableHeadCellProps) {
  return (
    <th
      className={classNames(
        'px-2 pb-2 font-normal first:pl-0 last:pr-0',
        props.className
      )}
    >
      {props.children}
    </th>
  )
}

type TableBodyCellProps = PropsWithChildren<{ className?: string }>

export function TableBodyCell(props: TableBodyCellProps) {
  return (
    <td
      className={classNames(
        'px-2 py-5 font-normal first:pl-0 last:pr-0',
        props.className
      )}
    >
      {props.children}
    </td>
  )
}
