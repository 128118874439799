import { Order_By, Polls_Entity_Order_By } from 'graphql-generated/graphql'
import { gettext_noop, useGetText } from 'lib/gettext'
import SortingControl, {
  getSortingQueryFromSearchString,
  useSortingQuery,
} from './sorting-control'

export const SEARCH_QUERY_NAME = 'orderBy'

const SORTING_EXPRESSION: {
  [key in 'name' | 'periodStart' | 'periodEnd']: Polls_Entity_Order_By
} = {
  name: { name: { name: Order_By.Asc } },
  periodStart: { year_from: Order_By.Asc },
  periodEnd: { year_to: Order_By.Asc },
}

const DEFAULT_SORTING: keyof typeof SORTING_EXPRESSION = 'name'

const OPTIONS: ReadonlyArray<{
  value: keyof typeof SORTING_EXPRESSION
  label: string
}> = [
  {
    value: 'name',
    label: gettext_noop('name'),
  },
  { value: 'periodStart', label: gettext_noop('from date') },
  { value: 'periodEnd', label: gettext_noop('to date') },
]

export function EntrySortingControl() {
  const { gettext } = useGetText()

  const options = OPTIONS.map(({ value, label }) => ({
    value,
    label: gettext(label),
  }))

  return (
    <SortingControl
      options={options}
      defaultValue={DEFAULT_SORTING}
      name={SEARCH_QUERY_NAME}
    />
  )
}

function getEntrySortingQuery(
  url: URL
): keyof typeof SORTING_EXPRESSION | undefined {
  return getSortingQueryFromSearchString(
    url.search,
    SEARCH_QUERY_NAME,
    OPTIONS.map(({ value }) => value)
  )
}

export function useEntrySortingQuery():
  | keyof typeof SORTING_EXPRESSION
  | undefined {
  return useSortingQuery(
    SEARCH_QUERY_NAME,
    OPTIONS.map(({ value }) => value)
  )
}

export function useEntrySorting(): Polls_Entity_Order_By {
  const queryParam = useEntrySortingQuery()

  return SORTING_EXPRESSION[queryParam || 'name']
}

export function getEntrySorting(url: URL): Polls_Entity_Order_By {
  const queryParam = getEntrySortingQuery(url)

  return SORTING_EXPRESSION[queryParam || 'name']
}
