import { lazy } from 'react'

export const EntriesByPublicationDate = lazy(
  () => import('./entries-by-publication-date')
)

export const EntriesByAuthor = lazy(() => import('./entries-by-author'))
export const EntriesByRegion = lazy(() => import('./entries-by-region'))
export const EntriesByTagsGroup = lazy(() => import('./entries-by-tags-group'))
export const EntrySearch = lazy(() => import('./entry-search'))

export { default as Layout } from './layout'
export * from './layout'
export * from './entry-search'

export { entriesByPublicationDateLoader } from './entries-by-publication-date/loader'
export { entriesByAuthorLoader } from './entries-by-author/loader'
export { entriesGroupedByRegionLoader } from './entries-by-region/loader'
export { entriesByTagsGroupLoader } from './entries-by-tags-group/loader'
export { createEntrySearchLoader } from './entry-search/loader'
