import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryDataSourceBox } from 'apps/browse/common'

const EXPERT_ENTRY_DATA_SOURCE_FRAGMENT = gql(`
  fragment EntryDataSource on polls_entity {
    external_url
    data_source {
      is_external
      name
      icon_small
      tooltip
    }
  }
`)

type EntryDataSourceProps = {
  entryRef: FragmentType<typeof EXPERT_ENTRY_DATA_SOURCE_FRAGMENT>
}

export default function EntryDataSource(props: EntryDataSourceProps) {
  const entry = useFragment(EXPERT_ENTRY_DATA_SOURCE_FRAGMENT, props.entryRef)

  const icon = entry['data_source']['icon_small']?.trim()
  const tooltip = entry['data_source']['tooltip']?.trim()
  const externalUrl = entry['external_url']
  const dataSourceName = entry['data_source'].name

  if (!entry['data_source']['is_external']) {
    return null
  }

  return (
    <EntryDataSourceBox icon={icon} tooltip={tooltip} externalUrl={externalUrl}>
      {dataSourceName}
    </EntryDataSourceBox>
  )
}
