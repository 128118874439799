import { LoaderFunction, useLoaderData } from 'react-router-dom'
import * as yup from 'yup'
import { getYear } from 'date-fns'

import { gql } from 'graphql-generated'
import { gqlRequest } from 'api'

const YEAR_FROM = gql(`
  query YearFromQuery {
    polls_baseanswerset_aggregate {
      aggregate {
        min {
          year_from
        }
      }
    }
  }
`)

const YEAR_FROM_AND_SEARCH_QUERY_ID_QUERY = gql(`
  query YearFromAndSearchQueryIdQuery($searchQueryId: Int!) {
    polls_baseanswerset_aggregate {
      aggregate {
        min {
          year_from
        }
      }
    }
    polls_searchrequest_by_pk(id: $searchQueryId) {
      id
    }
  }
`)

async function loadYearFrom(): Promise<number> {
  const { polls_baseanswerset_aggregate } = await gqlRequest(YEAR_FROM)

  return polls_baseanswerset_aggregate.aggregate?.min?.year_from || 0
}

async function loadYearFromAndEnsureSearchQueryAvailability(
  searchQueryId: number
): Promise<number> {
  const { polls_baseanswerset_aggregate, polls_searchrequest_by_pk } =
    await gqlRequest(YEAR_FROM_AND_SEARCH_QUERY_ID_QUERY, {
      searchQueryId,
    })

  if (polls_searchrequest_by_pk?.id) {
    return polls_baseanswerset_aggregate.aggregate?.min?.year_from || 0
  }

  throw new Response('', {
    status: 404,
  })
}

type VisualizeLoaderData = {
  yearFrom: number
  yearTo: number
  searchQueryId?: number
}

export const visualizeLoader: LoaderFunction = async ({
  params,
}): Promise<VisualizeLoaderData> => {
  const yearTo = getYear(new Date())

  if (params['searchQueryId']) {
    const searchQueryId = Number(params['searchQueryId'])

    if (isNaN(searchQueryId)) {
      throw new Error('Invalid Search Query ID')
    }

    return {
      yearFrom: await loadYearFromAndEnsureSearchQueryAvailability(
        searchQueryId
      ),
      yearTo,
      searchQueryId,
    }
  } else {
    return {
      yearFrom: await loadYearFrom(),
      yearTo,
    }
  }
}

const schema = yup
  .object({
    yearFrom: yup.number().required(),
    yearTo: yup.number().required(),
    searchQueryId: yup.number(),
  })
  .required()

export function useVisualizeLoaderData(): VisualizeLoaderData {
  return schema.validateSync(useLoaderData())
}
