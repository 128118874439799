/**
 * Returns tokens from the string. It works like a standard tokenizer in Elasticsearch
 *
 * @param str Input string
 * @returns An array of tokens
 */
export function tokenize(str: string): string[] {
  return str
    .toLocaleLowerCase()
    .split(/[ .,:;?!~#$%\\/|&*+=\-`^"'<>(){}[\]_]/)
    .filter((token) => token)
}

/**
 * Truncates input text around the given index
 *
 * @param input Input text
 * @param index Target word start index
 * @param words Amount of words to keep before and after the target word
 * @returns Truncated text
 */
export function truncateTextAround(
  input: string,
  index: number = 0,
  words: number = 4
): string {
  const wordsBefore = input.substring(0, index).trim().split(/\s+/)
  const wordsAfter = input.substring(index).trim().split(/\s+/)

  const textBefore =
    (wordsBefore.length > 3 ? '…' : '') +
    wordsBefore.splice(-3, words).join(' ')

  const textAfter =
    wordsAfter.splice(0, words + 1).join(' ') +
    (wordsAfter.length > 3 ? '…' : '')

  return [textBefore, textAfter].join(' ')
}

/**
 * Truncates text around the given query. It will truncate the input from the beginning
 * if the input text doesn't includes the query.
 *
 * @param input Input text
 * @param query Query string
 * @param words Amount of words to keep before and after the word matched the query
 * @returns Truncated text
 */
export function truncateTextByQuery(
  input: string,
  query: string,
  words: number = 4
): string {
  const regExpQuery = new RegExp(tokenize(query).join('|'), 'i')
  const index = input.search(regExpQuery) || 0

  return truncateTextAround(input, index, words)
}
