import { useCallback, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import * as yup from 'yup'
import { ValuesType } from 'utility-types'

import { gql } from 'graphql-generated'
import { SuggestedPollsQuery } from 'graphql-generated/graphql'
import { useAuthenticatedUser, userId } from 'auth'

const PROFILE_INTERESTS = gql(`
  query ProfileInterests($userId: Int!) {
    user: auth_user_by_pk(id: $userId) {
      profile {
        interests
      }
    }
  }
`)

const SUGGESTED_ENTRIES_QUERY = gql(`
  query SuggestedPolls($tagsQuery: polls_entity_tags_bool_exp!) {
    polls_entity(where: { tags: $tagsQuery }, limit: 10, order_by: { date_modified: desc }) {
      id
      name {
        name
      }
      date_modified
      created_by_id
      progress(where: { published: { _gt: 0 } }) {
        expert {
          user {
            id
            first_name,
            last_name,
            username,
          }
        }
      }
    }
  }
`)

const INTERESTS_SCHEMA = yup.array().of(yup.string().required()).notRequired()

type InterestsQuery =
  | {
      loading: true
      data: ReadonlyArray<string> | undefined
    }
  | { loading: false; data: ReadonlyArray<string> }

function useInterestsQuery(): InterestsQuery {
  const user = useAuthenticatedUser()

  const { loading, data } = useQuery(PROFILE_INTERESTS, {
    variables: { userId: userId(user) },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) {
    return { loading, data: undefined }
  }

  if (!data?.user) {
    throw new Error(`Failed to load user #${userId(user)}`)
  }

  const profile = data.user.profile

  if (profile) {
    const interests = INTERESTS_SCHEMA.validateSync(profile.interests) || []

    return {
      loading,
      data: interests,
    }
  }

  throw new Error(`Profile not found for user ID ${userId(user)}`)
}

function useSuggestedEntriesLazyQuery() {
  const [load, { loading, data, called }] = useLazyQuery(
    SUGGESTED_ENTRIES_QUERY
  )

  const query = useCallback(
    async (tags: ReadonlyArray<string>) => {
      return load({
        variables: {
          tagsQuery: {
            _or: tags.map((tag) => ({ tag: { name: { _ilike: `%${tag}%` } } })),
          },
        },
      })
    },
    [load]
  )

  return [
    query,
    {
      loading,
      called,
      data: data ? mapSuggestedEntryFromQuery(data) : undefined,
    },
  ] as const
}

export default function useSuggestedPolls() {
  const { loading: interestsLoading, data: interests } = useInterestsQuery()

  const [loadSuggestedEntries, { loading: pollsLoading, called, data }] =
    useSuggestedEntriesLazyQuery()

  useEffect(() => {
    if (interests && !called) {
      loadSuggestedEntries(interests)
    }
  }, [interests, called, loadSuggestedEntries])

  return { loading: interestsLoading || pollsLoading, data }
}

function contributorFromProgress(
  progress: ValuesType<
    ValuesType<SuggestedPollsQuery['polls_entity']>['progress']
  >
) {
  const {
    id,
    first_name: firstName,
    last_name: lastName,
    username,
  } = progress.expert.user

  return { id, firstName, lastName, username }
}

export function mapSuggestedEntryFromQuery(data: SuggestedPollsQuery) {
  return data.polls_entity.map(
    ({ id, name: { name }, date_modified: dateModified, progress }) => ({
      id,
      name,
      dateModified: new Date(dateModified),
      contributors: progress.map(contributorFromProgress),
    })
  )
}
