import { useCallback, useState } from 'react'

import { useGetText } from 'lib/gettext'
import { TabNav, Tab } from 'common'
import {
  Toolbar,
  EntryFilter,
  useToolbarContext,
  useToolbarQueryString,
} from 'apps/browse/common'
import { useEntryTagGroupLoaderData } from './entry-tag-group-loader'

export default function Navigation() {
  const { gettext } = useGetText()

  const entryGroupTags = useEntryTagGroupLoaderData()
  const [filterIsVisible, setFilterIsVisible] = useState(false)
  const toolbar = useToolbarContext()
  const toolbarQueryString = useToolbarQueryString()

  const toggleFilter = useCallback(() => {
    setFilterIsVisible(!filterIsVisible)
  }, [filterIsVisible, setFilterIsVisible])

  return (
    <div className="flex flex-col">
      <div className="mb-8 flex flex-wrap items-start">
        <TabNav className="!mb-0 grow-[100] !gap-x-4 pt-3 pr-4">
          <span className="uppercase text-gray-drh">
            {gettext('Browse entries by')}
          </span>

          <Tab type="router-link" to="/browse" end>
            {gettext('Publication date')}
          </Tab>

          <Tab type="router-link" to="./authors" end>
            {gettext('Author')}
          </Tab>

          <Tab
            type="router-link"
            to={['./regions', toolbarQueryString].join('?')}
            end
          >
            {gettext('Region')}
          </Tab>

          {entryGroupTags.map(({ id, name }) => (
            <Tab
              type="router-link"
              to={[`./tags-group/${id}`, toolbarQueryString].join('?')}
              end
              key={id}
            >
              {name}
            </Tab>
          ))}
        </TabNav>

        <Toolbar toggleFilter={toggleFilter} />
      </div>

      {toolbar.config.filter && filterIsVisible && (
        <EntryFilter className="-mt-8 mb-8" />
      )}
    </div>
  )
}
