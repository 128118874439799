import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
  useRef,
} from 'react'

import GT from 'node-gettext'
import { sprintf } from 'sprintf-js'
import { Subtract } from 'utility-types'
import useLocalStorageState from 'use-local-storage-state'

import interpolateComponents from './interpolate-components'

export type I_gettext = {
  (msgid: string, data?: Record<string, string | number>): string
}

type I_ngettext = {
  (
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ): string
}

type I_pgettext = {
  (
    msgctxt: string,
    msgid: string,
    data?: Record<string, string | number>
  ): string
}

type I_npgettext = {
  (
    msgctxt: string,
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ): string
}

type I_dgettext = {
  (
    domain: string,
    msgid: string,
    data?: Record<string, string | number>
  ): string
}

type I_dngettext = {
  (
    domain: string,
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ): string
}

type I_dpgettext = {
  (
    domain: string,
    msgctxt: string,
    msgid: string,
    data?: Record<string, string | number>
  ): string
}

type I_dnpgettext = {
  (
    domain: string,
    msgctxt: string,
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ): string
}

type IGetTextContext = {
  defaultDomain: string
  locale: string
  setLocale: (locale: string) => Promise<void>
  gettext: I_gettext
  ngettext: I_ngettext
  pgettext: I_pgettext
  npgettext: I_npgettext
  dgettext: I_dgettext
  dngettext: I_dngettext
  dpgettext: I_dpgettext
  dnpgettext: I_dnpgettext
}

const GetTextContext = createContext<IGetTextContext | undefined>(undefined)

export function useGetText(): IGetTextContext {
  const context = useContext(GetTextContext)

  if (context === undefined) {
    throw new Error('Cannot use useGetText() because context is not defined.')
  }

  return context
}

function create_gettext(gt: GT): I_gettext {
  return (msgid: string, data?: Record<string, string | number>) =>
    sprintf(gt.gettext(msgid), { ...data })
}

function create_ngettext(gt: GT): I_ngettext {
  return (
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ) => sprintf(gt.ngettext(msgid, msgidPlural, count), { ...data, count })
}

function create_pgettext(gt: GT): I_pgettext {
  return (
    msgctxt: string,
    msgid: string,
    data?: Record<string, string | number>
  ) => sprintf(gt.pgettext(msgctxt, msgid), { ...data })
}

function create_npgettext(gt: GT): I_npgettext {
  return (
    msgctxt: string,
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ) =>
    sprintf(gt.npgettext(msgctxt, msgid, msgidPlural, count), {
      ...data,
      count,
    })
}

function create_dgettext(gt: GT): I_dgettext {
  return (
    domain: string,
    msgid: string,
    data?: Record<string, string | number>
  ) => sprintf(gt.dgettext(domain, msgid), { ...data })
}

function create_dngettext(gt: GT): I_dngettext {
  return (
    domain: string,
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ) =>
    sprintf(gt.dngettext(domain, msgid, msgidPlural, count), {
      ...data,
      count,
    })
}

function create_dpgettext(gt: GT): I_dpgettext {
  return (
    domain: string,
    msgctxt: string,
    msgid: string,
    data?: Record<string, string | number>
  ) => sprintf(gt.dpgettext(domain, msgctxt, msgid), { ...data })
}

function create_dnpgettext(gt: GT): I_dnpgettext {
  return (
    domain: string,
    msgctxt: string,
    msgid: string,
    msgidPlural: string,
    count: number,
    data?: Record<string, string | number>
  ) =>
    sprintf(gt.dnpgettext(domain, msgctxt, msgid, msgidPlural, count), {
      ...data,
      count,
    })
}

type GetTextProviderProps = React.PropsWithChildren<{
  sourceLocale: string
  fallback: ReactNode
  translations: Record<string, () => Promise<object>>
}>

export function GetTextProvider(props: GetTextProviderProps) {
  const { sourceLocale, children, translations, fallback } = props

  const loadedTranslations = useRef<Set<string>>(new Set())
  const instance = useRef<GT>(new GT({ sourceLocale, debug: false }))
  const [error, setError] = useState<unknown>()
  const [locale, setLocale] = useState<string>()

  const [storageValue, setStorageValue] = useLocalStorageState('locale', {
    defaultValue: sourceLocale,
    serializer: {
      parse: (value) => String(value),
      stringify: (value) => String(value),
    },
  })

  const handleSetLocale = useCallback(
    async (locale: string) => {
      if (locale !== sourceLocale && !loadedTranslations.current.has(locale)) {
        if (locale in translations) {
          const messages = await translations[locale]()

          instance.current.addTranslations(locale, 'messages', messages)
          loadedTranslations.current.add(locale)
        } else {
          setError(
            new Error(`Failed to get translations loader for ${locale} locale`)
          )
        }
      }

      instance.current.setLocale(locale)
      setStorageValue(locale)
      setLocale(locale)
    },
    [setLocale, sourceLocale, translations, setStorageValue]
  )

  // Sync locale in the current window with the storage
  useEffect(() => {
    if (typeof storageValue === 'string') {
      handleSetLocale(storageValue)
    } else {
      setError(
        new Error(
          `Failed to sync locale from storage: expected string, but got ${storageValue}`
        )
      )
    }
  }, [handleSetLocale, storageValue])

  if (error) {
    throw error
  }

  if (locale) {
    const value: IGetTextContext = {
      defaultDomain: instance.current.domain,
      locale,
      setLocale: handleSetLocale,
      gettext: create_gettext(instance.current),
      ngettext: create_ngettext(instance.current),
      pgettext: create_pgettext(instance.current),
      npgettext: create_npgettext(instance.current),
      dgettext: create_dgettext(instance.current),
      dngettext: create_dngettext(instance.current),
      dpgettext: create_dpgettext(instance.current),
      dnpgettext: create_dnpgettext(instance.current),
    }

    return (
      <GetTextContext.Provider value={value}>
        {children}
      </GetTextContext.Provider>
    )
  }

  return <>{fallback}</>
}

type GetTextProps = { domain?: string; context?: string; comment?: string } & (
  | ((
      | { message: string; children?: undefined }
      | { message?: undefined; children: string | string[] }
    ) & {
      messagePlural?: undefined
      count?: undefined
    })
  | ((
      | { message: string; children?: undefined }
      | { message?: undefined; children: string | string[] }
    ) & { message: string; messagePlural: string; count: number })
) & {
    data?: Record<string, string | number>
    components?: Record<string, React.ReactElement>
  }

function createMsgid(message?: string, children?: string | string[]): string {
  return (
    message || (Array.isArray(children) ? children.join('') : children) || ''
  )
}

export function GetText(props: GetTextProps) {
  const { defaultDomain, dnpgettext, dpgettext } = useGetText()

  const domain = props.domain || defaultDomain
  const msgctxt = props.context || ''
  const msgid = createMsgid(props.message, props.children)

  const message = props.messagePlural
    ? dnpgettext(
        domain,
        msgctxt,
        msgid,
        props.messagePlural,
        props.count,
        props.data
      )
    : dpgettext(domain, msgctxt, msgid, props.data)

  return <>{interpolateComponents(message, props.components)}</>
}

export function gettext_noop(msgid: string): string {
  return msgid
}

type InjectedGettextProps = {
  gettext?: I_gettext
  ngettext?: I_ngettext
}

export const withGettext = <P extends InjectedGettextProps>(
  Component: React.ForwardRefExoticComponent<P>
) => {
  return React.forwardRef((props: Subtract<P, InjectedGettextProps>, ref) => {
    const { gettext, ngettext } = useGetText()

    return (
      <Component
        {...(props as P)}
        ref={ref}
        gettext={gettext}
        ngettext={ngettext}
      />
    )
  })
}
