import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  concat,
  split,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'

import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

import { GRAPHQL_URL, GRAPHQL_WEBSOCKET_URL } from 'config'
import { getAccessTokenId } from 'auth'

// Note: GraphQL server should be located at the same domain as the web app. Otherwise, the authentication system
// will not work properly (we need to send/receive cookies). Currently, browsers are not allowed to send cookies if
// the origin and remote domains are not the same.
const httpLink = createHttpLink({
  credentials: 'include',
  uri: GRAPHQL_URL,
  fetch,
})

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessTokenId()

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }
  }
})

const hasuraRoleLink = setContext((_, { headers, hasuraRole }) => {
  if (hasuraRole) {
    return {
      headers: {
        ...headers,
        'x-hasura-role': hasuraRole,
      },
    }
  }

  return {}
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_WEBSOCKET_URL,
    connectionParams: async () => {
      const token = await getAccessTokenId()

      if (token) {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      }
    },
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    const isSubscription =
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'

    return isSubscription
  },
  wsLink,
  concat(concat(authLink, hasuraRoleLink), httpLink)
)

export default new ApolloClient({
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  cache: new InMemoryCache({
    resultCaching: false,
    typePolicies: {
      Query: {
        fields: {
          entry_search_connection: relayStylePagination(['query', 'sort']),
        },
      },
    },
  }),
  link: splitLink,
})
