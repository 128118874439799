import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryDetailsItem, Highlight } from 'apps/browse/common'
import { truncateTextByQuery } from 'apps/browse/utils'

import ICON from './assets/text-icon.svg'

const DESCRIPTION_FRAGMENT = gql(`
  fragment EntrySearch_Entry_Description on Entry {
    description
  }
`)

type EntryDescriptionProps = {
  entryRef: FragmentType<typeof DESCRIPTION_FRAGMENT>
  query: string
}

export default function EntryDescription(props: EntryDescriptionProps) {
  const description = useFragment(
    DESCRIPTION_FRAGMENT,
    props.entryRef
  ).description?.trim()

  const { query } = props

  if (description) {
    const text = truncateTextByQuery(description, query, 4)

    return (
      <EntryDetailsItem icon={ICON}>
        <Highlight this={query}>{text}</Highlight>
      </EntryDetailsItem>
    )
  }

  return null
}
