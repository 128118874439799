import { gql, FragmentType, useFragment } from 'graphql-generated'

import { EntryPollTypeBox } from 'apps/browse/common'

const POLL_TYPE_FRAGMENT = gql(`
  fragment EntrySearch_Entry_PollType on Entry {
    poll_type
  }
`)

type EntryPollTypeProps = {
  entryRef: FragmentType<typeof POLL_TYPE_FRAGMENT>
}

export default function EntryPollType(props: EntryPollTypeProps) {
  const { poll_type: type } = useFragment(POLL_TYPE_FRAGMENT, props.entryRef)

  return <EntryPollTypeBox type={type} />
}
