import { PropsWithChildren } from 'react'

export default function SidebarNav({ children }: PropsWithChildren) {
  return (
    <ul className="sm:sticky sm:top-0 sm:max-h-screen sm:overflow-y-scroll">
      {children}
    </ul>
  )
}

export function SidebarNavItem({
  title,
  children,
}: PropsWithChildren<{ title: string }>) {
  const anchor = title.replaceAll(/\s/g, '-').toLowerCase()

  return (
    <li className="inline-block pr-1 after:content-[','] sm:block sm:border-b sm:border-b-black/5 sm:pr-0 sm:after:content-none sm:last:border-b-0 [&_ul]:hidden sm:[&_ul]:block sm:[&_ul]:border-t sm:[&_ul]:border-black/5 sm:[&_ul]:pl-4">
      <a className="link sm:block sm:py-2 sm:px-0" href={`#${anchor}`}>
        {title}
      </a>
      {children && <SidebarNav>{children}</SidebarNav>}
    </li>
  )
}
