import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import createReactClass from 'create-react-class'

import s from './hover-over.module.scss'

export default createReactClass({
  displayName: 'HoverOver',

  propTypes: {
    tip: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    width: PropTypes.number,
    position: PropTypes.string,
    className: PropTypes.string,
    tipClassName: PropTypes.string,
    tipTitle: PropTypes.string,
  },

  render() {
    const {
      position,
      width,
      tipTitle,
      tip,
      className,
      tipClassName,
      children,
    } = this.props

    return (
      <span className={classNames(s.wrapper, className)}>
        {tip ? (
          <span
            className={classNames(s.tip, tipClassName, {
              [s._under]: position === 'under',
            })}
            style={{ width }}
          >
            {tipTitle ? (
              <span className={classNames(s.tipTitle, className)}>
                {tipTitle}
                <br />
              </span>
            ) : null}
            {tip}
          </span>
        ) : null}
        {children}
      </span>
    )
  },
})
