import classNames from 'classnames'

type Props = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'type'
>

export default function ClearInputButton(props: Props) {
  const { className, ...rest } = props

  return (
    <button
      className={classNames(
        'group flex items-center justify-center px-4',
        className
      )}
      {...rest}
      type="button"
    >
      <svg
        className="z-10 h-4 w-4"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="8"
          r="8"
          className="fill-primary/25 transition-colors group-hover:fill-primary"
        />
        <g
          className="stroke-primary transition-colors group-hover:stroke-white"
          strokeWidth="1.5"
          strokeLinecap="round"
        >
          <path d="M5,5 L11,11" />
          <path d="M5,11 L11,5" />
        </g>
      </svg>
    </button>
  )
}
