import { FragmentType, gql, useFragment } from 'graphql-generated'

import { EntryLinkBox } from 'apps/browse/common'

const ENTRY_LINK_FRAGMENT = gql(`
  fragment EntryNameLink on polls_entityname {
    name
  }
`)

type EntryNameLinkFragment = FragmentType<typeof ENTRY_LINK_FRAGMENT>

type EntryNameLinkProps = {
  entryId: number
  nameRef: EntryNameLinkFragment
}

export default function EntryNameLink(props: EntryNameLinkProps) {
  const data = useFragment(ENTRY_LINK_FRAGMENT, props.nameRef)

  return <EntryLinkBox entryId={props.entryId}>{data.name}</EntryLinkBox>
}
