export function background(
  index: number,
  includeHover: boolean = false
): string {
  const colors = [
    'bg-category-first',
    'bg-category-second',
    'bg-category-third',
    'bg-category-fourth',
    'bg-category-fifth',
  ]

  const hoverColors = [
    'hover:bg-category-first-darker',
    'hover:bg-category-second-darker',
    'hover:bg-category-third-darker',
    'hover:bg-category-fourth-darker',
    'hover:bg-category-fifth-darker',
  ]

  return includeHover
    ? `${colors[(index + colors.length) % colors.length]} ${
        hoverColors[(index + hoverColors.length) % hoverColors.length]
      }`
    : colors[(index + colors.length) % colors.length]
}

export function containerLabelBackground(index: number): string {
  const hoverColors = [
    'peer-hover:bg-category-first-darker',
    'peer-hover:bg-category-second-darker',
    'peer-hover:bg-category-third-darker',
    'peer-hover:bg-category-fourth-darker',
    'peer-hover:bg-category-fifth-darker',
  ]

  return hoverColors[(index + hoverColors.length) % hoverColors.length]
}

export function questionLabelBackground(index: number): string {
  const hoverColors = [
    'hover:bg-category-first-darker peer-hover:bg-category-first-darker',
    'hover:bg-category-second-darker peer-hover:bg-category-second-darker',
    'hover:bg-category-third-darker peer-hover:bg-category-third-darker',
    'hover:bg-category-fourth-darker peer-hover:bg-category-fourth-darker',
    'hover:bg-category-fifth-darker peer-hover:bg-category-fifth-darker',
  ]

  return hoverColors[(index + hoverColors.length) % hoverColors.length]
}

export function border(index: number): string {
  const borderColors = [
    'border-category-first',
    'border-category-second',
    'border-category-third',
    'border-category-fourth',
    'border-category-fifth',
  ]

  return borderColors[(index + borderColors.length) % borderColors.length]
}

export function backgroundLiteral(index: number): string {
  const colors = ['#ededff', '#d9f3ff', '#dff7d2', '#f5f3c9', '#ffeee6']

  return colors[(index + colors.length) % colors.length]
}
