import { gql, FragmentType, useFragment } from 'graphql-generated'
import { EntryDataSourceBox } from 'apps/browse/common'

const DATA_SOURCE_FRAGMENT = gql(`
  fragment EntrySearch_Entry_DataSource on Entry {
    external_url
    data_source {
      is_external
      name
      icon_small
      tooltip
    }
  }
`)

type EntryDataSourceProps = {
  entryRef: FragmentType<typeof DATA_SOURCE_FRAGMENT>
  query: string
}

export default function EntryDataSource(props: EntryDataSourceProps) {
  const { external_url: externalUrl, data_source: dataSource } = useFragment(
    DATA_SOURCE_FRAGMENT,
    props.entryRef
  )

  if (dataSource?.is_external) {
    return null
  }

  const { icon_small: icon, tooltip, name } = dataSource || {}

  return (
    <EntryDataSourceBox
      absoluteIconPath
      icon={icon}
      tooltip={tooltip}
      externalUrl={externalUrl}
    >
      {name}
    </EntryDataSourceBox>
  )
}
