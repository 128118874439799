import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Header, Spinner } from 'common'
import Footer from './Footer'

function SuspenseFallback() {
  return (
    <div className="m-auto py-8">
      <Spinner />
    </div>
  )
}

export default function LandingLayout() {
  return (
    <div className="flex min-h-screen flex-col">
      <Header />

      <main className="flex flex-grow flex-col">
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet />
        </Suspense>
      </main>

      <Footer />
    </div>
  )
}
