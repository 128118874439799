import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

type EntryLinkBoxProps = PropsWithChildren<{
  entryId: string | number
}>

export default function EntryLinkBox(props: EntryLinkBoxProps) {
  const { entryId, children } = props

  return (
    <NavLink className="link" to={`/browse/${entryId}`}>
      {children}
    </NavLink>
  )
}
