import LegacyPublicationStatus from 'legacy/apps/progress/components/published-state'

import { Entry, isPublished } from './entry'

type EntryPublicationStatusProps = {
  entry: Entry
}

export default function EntryPublicationStatus(
  props: EntryPublicationStatusProps
) {
  const { stats, status } = props.entry

  return (
    <LegacyPublicationStatus
      draft={stats.drafts}
      published={stats.published}
      datePublished={
        isPublished(status) ? status.datePublished.toISOString() : ''
      }
    />
  )
}
