import { NavLink } from 'react-router-dom'

import { gettext_noop, useGetText, GetText } from 'lib/gettext'
import { useAuthenticatedUser, userId } from 'auth'
import { Avatar, UserDisplayName, Spinner } from 'common'
import useAssignedEditor from './use-assigned-editor'
import MyEntries from './my-entries'
import SuggestedEntries from './suggested-entries'
import CommentedEntries from './commented-entries'

import Icon, { IconType } from './icons'

export default function Dashboard() {
  const { gettext } = useGetText()

  const user = useAuthenticatedUser()
  const { assignedEditor, isLoading: assignedEditorIsLoading } =
    useAssignedEditor()

  return (
    <div className="w-full md:flex">
      <div className="flex w-full flex-col gap-y-8 px-4 pt-8 sm:px-8 md:order-2 md:basis-3/5 md:gap-y-12 md:py-12 lg:basis-2/3 xl:basis-3/4">
        <MyEntries />
        <SuggestedEntries />
        <CommentedEntries />
      </div>

      <div className="flex w-full flex-col gap-y-8 self-start px-4 pt-4 pb-8 sm:px-8 md:sticky md:top-0 md:order-1 md:w-2/5 md:pt-8 lg:w-1/3 xl:w-1/4">
        <section>
          <div className="mb-4 flex items-center gap-x-5">
            <Avatar
              className="-ml-1 shrink-0"
              size={72}
              userId={userId(user)}
            />

            <h3 className="text-xl font-bold">
              <UserDisplayName {...user} />
            </h3>
          </div>

          {assignedEditorIsLoading ? (
            <Spinner className="mx-auto" size="sm" />
          ) : (
            <p>
              {assignedEditor ? (
                <>
                  {gettext('Editor assigned to me is ')}
                  {assignedEditor.name && (
                    <strong>{assignedEditor.name}</strong>
                  )}

                  {assignedEditor.email && (
                    <>
                      <br />
                      <a
                        href={`mailto:${assignedEditor.email}`}
                        className="link inline"
                      >
                        {assignedEditor.email}
                      </a>
                    </>
                  )}
                </>
              ) : (
                gettext('No editor assigned yet.')
              )}
            </p>
          )}
        </section>

        <nav className="-mt-2">
          {MENU_ITEMS.map(({ title, description, icon, to }, index) => (
            <div
              className="mb-4 flex items-start gap-4 last:mb-0 sm:mb-6"
              key={index}
            >
              <NavLink to={to}>
                <Icon icon={icon} />
              </NavLink>

              <div className="pt-3">
                <h2 className="mb-1 text-xl font-medium tracking-tight">
                  <NavLink className="link inline" to={to}>
                    {gettext(title)}
                  </NavLink>
                </h2>

                <p>{gettext(description)}</p>
              </div>
            </div>
          ))}
        </nav>

        <section className="text-gray-drh">
          <p>
            <GetText
              components={{
                link: (
                  <NavLink to="/landing/faq" className="link font-semibold" />
                ),
              }}
            >
              If you have any questions regarding the database, feel free to
              visit our extensive [link]FAQ section[/link].
            </GetText>
          </p>
        </section>
      </div>
    </div>
  )
}

const MENU_ITEMS = [
  {
    title: gettext_noop('Browse the Database'),
    description: gettext_noop(
      'Browse existing data by entry name, region or time period.'
    ),
    icon: IconType.BROWSE,
    to: '/browse',
  },
  {
    title: gettext_noop('Visualize Existing Data'),
    description: gettext_noop(
      'Create visualizations of current entries or variable distributions over time and space.'
    ),
    icon: IconType.VISUALIZE,
    to: '/visualize',
  },
  {
    title: gettext_noop('Contribute Data'),
    description: gettext_noop('Fill out a new or existing poll.'),
    icon: IconType.CONTRIBUTE,
    to: '/contribute',
  },
  {
    title: gettext_noop('Learn more about the DRH'),
    description: gettext_noop(
      'Find information on the project background and strategy, potential uses, current contributors and personnel.'
    ),
    icon: IconType.LEARN_MORE,
    to: '/landing/about',
  },
]
