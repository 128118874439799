import { gql, FragmentType, useFragment } from 'graphql-generated'

import { EntryContributorsBox, ExpertLink } from 'apps/browse/common'

const ENTRY_CONTRIBUTORS_FRAGMENT = gql(`
  fragment EntryContributors on polls_entity {
    entry_source
    user {
      id
      ...ExpertLink
    }
    progress(where: { published: { _gt: 0 }, expert_id: { _is_null: false } }) {
      expert {
        user {
          id
          ...ExpertLink
        }
      }
    }
  }
`)

type EntryContributorsFragment = FragmentType<
  typeof ENTRY_CONTRIBUTORS_FRAGMENT
>

type EntryContributorsProps = {
  entryRef: EntryContributorsFragment
  className?: string
  inline?: boolean
  label?: boolean
}

function useContributors(
  ref: FragmentType<typeof ENTRY_CONTRIBUTORS_FRAGMENT>
) {
  const { user, progress } = useFragment(ENTRY_CONTRIBUTORS_FRAGMENT, ref)

  const allContributors = [
    user,
    ...progress.map(({ expert }) => expert?.user).filter((user) => user),
  ]

  return allContributors.reduce<typeof allContributors>(
    (res, contributor) =>
      res.find((c) => c.id === contributor.id) ? res : [...res, contributor],
    []
  )
}

function useEntrySource(ref: FragmentType<typeof ENTRY_CONTRIBUTORS_FRAGMENT>) {
  const { entry_source } = useFragment(ENTRY_CONTRIBUTORS_FRAGMENT, ref)

  return entry_source
}

export default function EntryContributors(props: EntryContributorsProps) {
  const { entryRef, inline, label = true } = props
  const contributors = useContributors(entryRef)
  const entrySource = useEntrySource(entryRef)

  const isPersonalExpertise = entrySource === 0
  const isSupervisedEntry = entrySource === 3

  return (
    <EntryContributorsBox
      label={label}
      personalExpertise={isPersonalExpertise || isSupervisedEntry}
      contributors={contributors}
      inline={inline}
    >
      {(contributor) => <ExpertLink userRef={contributor} />}
    </EntryContributorsBox>
  )
}
