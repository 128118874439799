import { GetText, useGetText } from 'lib/gettext'

import EntryDetailsItem from './entry-details-item'
import EntryTooltip from './entry-tooltip'

import secondarySourceIcon from './assets/secondary-source.svg'
import expertSourceIcon from './assets/expert-source.svg'
import supervisedSourceIcon from './assets/supervised-source.svg'

type Props = {
  withContributors?: boolean
  entrySource: number | null | undefined
  secondarySource?: string | null | undefined
  contributorsRenderer?: () => JSX.Element
  expertSourceLinkRenderer: () => JSX.Element | null | undefined
  supervisorLinkRenderer: () => JSX.Element | null | undefined
}

export default function EntrySource({
  withContributors = false,
  entrySource,
  secondarySource,
  contributorsRenderer,
  expertSourceLinkRenderer,
  supervisorLinkRenderer,
}: Props) {
  const { gettext } = useGetText()

  switch (entrySource) {
    case 1:
      return (
        <EntryDetailsItem icon={secondarySourceIcon}>
          <GetText
            components={{
              em: (
                <EntryTooltip
                  tooltipText={gettext(
                    'Secondary Source entry, prepared from a literature review by a Ph.D. RA'
                  )}
                />
              ),
              source: <>{secondarySource}</>,
            }}
          >
            [em]Secondary source[/em]: [source/]
          </GetText>
        </EntryDetailsItem>
      )
    case 2: {
      const tooltip = (
        <EntryTooltip
          tooltipText={gettext(
            "Expert Source entry, prepared by a Ph.D. RA or DRH Editor from an expert's published work(s), and then personally edited and approved by the expert."
          )}
        />
      )

      return (
        <EntryDetailsItem icon={expertSourceIcon}>
          {withContributors && contributorsRenderer ? (
            <GetText
              components={{
                em: tooltip,
                contributors: <>{contributorsRenderer()}</>,
              }}
            >
              as [em]Expert source[/em]. Entered by: [contributors/]
            </GetText>
          ) : (
            <GetText
              components={{
                em: tooltip,
                sources: <>{expertSourceLinkRenderer()}</>,
              }}
            >
              [em]Expert source[/em]: [sources/]
            </GetText>
          )}
        </EntryDetailsItem>
      )
    }
    case 3:
      return (
        <EntryDetailsItem icon={supervisedSourceIcon}>
          <>
            <GetText
              components={{
                em: <EntryTooltip tooltipText={gettext('Supervised entry')} />,
                supervisors: <>{supervisorLinkRenderer()}</>,
              }}
            >
              [em]Supervised by[/em]: [supervisors/]
            </GetText>
          </>
        </EntryDetailsItem>
      )
    default:
      return null
  }
}
