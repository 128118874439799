import { gql, FragmentType, useFragment } from 'graphql-generated'

import {
  EntryBox,
  EntryDetails,
  EntryHeader,
  EntryTitle,
  EntryTitleRow,
} from 'apps/browse/common'

import EntryAlternativeNames from './entry-alternative-names'
import EntryContributors from './entry-contributors'
import EntryDataSource from './entry-data-source'
import EntryDescription from './entry-description'

import EntryPollType from './entry-poll-type'
import EntryLink from './entry-link'
import EntryPeriod from './entry-period'
import EntryRegion from './entry-region'
import EntrySource from './entry-source'
import EntryTags from './entry-tags'

const ENTRY_FRAGMENT = gql(`
  fragment EntrySearch_Entry on Entry {
    __typename
    entryId: id
    name
    ...EntrySearch_Entry_PollType
    ...EntrySearch_Entry_AlternativeNames
    ...EntrySearch_Entry_Contributors
    ...EntrySearch_Entry_DataSource
    ...EntrySearch_Entry_Description
    ...EntrySearch_Entry_Period
    ...EntrySearch_Entry_Region
    ...EntrySearch_Entry_Source
    ...EntrySearch_Entry_Tags
  }
`)

type EntryProps = {
  entryRef: FragmentType<typeof ENTRY_FRAGMENT>
  query: string
}

export default function Entry(props: EntryProps) {
  const entry = useFragment(ENTRY_FRAGMENT, props.entryRef)
  const { query } = props

  return (
    <EntryBox className="flex gap-4">
      <div className="basis-5/12">
        <EntryRegion entryRef={entry} />
      </div>

      <div className="basis-7/12">
        <EntryHeader>
          <EntryTitleRow>
            <EntryPollType entryRef={entry} />
            <EntryTitle>
              <EntryLink id={entry.entryId} query={props.query}>
                {entry.name}
              </EntryLink>
            </EntryTitle>
          </EntryTitleRow>

          <EntryAlternativeNames query={query} entryRef={entry} />
          <EntryContributors query={query} entryRef={entry} />
        </EntryHeader>

        <EntryDetails>
          <EntrySource query={query} entryRef={entry} />
          <EntryDataSource query={query} entryRef={entry} />
          <EntryPeriod entryRef={entry} />
          <EntryTags query={query} entryRef={entry} />
          <EntryDescription query={query} entryRef={entry} />
        </EntryDetails>
      </div>
    </EntryBox>
  )
}
