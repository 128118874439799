import { Link, useNavigate } from 'react-router-dom'

import { PrimaryButton } from 'common'
import { PropsWithChildren } from 'react'

type ErrorPageProps = PropsWithChildren<{
  title: string
}>

export default function ErrorPage(props: ErrorPageProps) {
  const navigate = useNavigate()

  return (
    <main className="flex min-w-full flex-col items-center">
      <div className="mt-24 w-3/4 sm:mt-32 lg:w-1/2 xl:w-2/5">
        <h1 className="mb-3 text-3xl capitalize">{props.title}</h1>
        <p className="mb-5 text-lg text-neutral-600">{props.children}</p>
        <div className="flex flex-col justify-between gap-8 md:flex-row">
          <PrimaryButton onClick={() => navigate(-1)}>← Go Back</PrimaryButton>

          <Link className="link self-center" to="/">
            Return to the home page
          </Link>
        </div>
      </div>
    </main>
  )
}
