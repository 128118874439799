import yn from 'yn'

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000'
export const API_WEBSOCKET_URL =
  process.env.REACT_APP_API_WEBSOCKET_URL || 'wss://localhost'

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

export const GOOGLE_ANALYTICS_MEASUREMENT_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''

export const CONTACT_EMAIL =
  process.env.REACT_APP_CONTACT_EMAIL || 'project.manager@religiondatabase.org'

export const SUPPORT_EMAIL =
  process.env.REACT_APP_SUPPORT_EMAIL || 'support@religiondatabase.org'

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''

export const SENTRY_ENVIRONMENT =
  process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development'
export const DEBUG = process.env.NODE_ENV === 'development'

export const STAGING_WARNING = yn(process.env.REACT_APP_STAGING_WARNING)

const AWS_S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET || 'drh-media-staging'

export const MEDIA_URL = `https://${AWS_S3_BUCKET}.s3.amazonaws.com`

export const GRAPHQL_URL = API_URL + '/v1/graphql'
export const GRAPHQL_WEBSOCKET_URL = API_WEBSOCKET_URL + '/v1/graphql'
