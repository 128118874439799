import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import createReactClass from 'create-react-class'
import { GetText } from 'lib/gettext'

import s from './additional-questions.module.scss'

export default createReactClass({
  displayName: 'AdditionalQuestions',

  propTypes: {
    children: PropTypes.node.isRequired,
    colorTheme: PropTypes.string,
  },

  render() {
    return (
      <span
        className={classNames(
          s.additional,
          {
            [s._dark]: this.props.colorTheme === 'dark',
          },
          'cursor-help'
        )}
      >
        <span className={s.tip}>
          <GetText>Additional questions</GetText>
        </span>
        {this.props.children}
      </span>
    )
  },
})
