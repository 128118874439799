import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Optional } from 'utility-types'
import queryString from 'query-string'

import { DropdownTrigger, DropdownTriggerCaret } from 'common'

import {
  EntrySortingControl,
  useEntrySortingQuery,
  SEARCH_QUERY_NAME as ENTRY_SORTING_SEARCH_QUERY_NAME,
} from './entry-sorting'

import {
  EntrySearchSortingControl,
  useEntrySearchSortingQuery,
  ENTRY_SEARCH_QUERY_NAME,
} from './entry-search-sorting'

import { useEntryFilterQuery } from './entry-filter'

type ToolbarConfig = {
  sorting: boolean
  searchSorting: boolean
  filter: boolean
}

type ToolbarContext = {
  config: ToolbarConfig
  setConfig: (config: ToolbarConfig) => void
}

const TOOLBAR_CONTEXT = createContext<ToolbarContext | undefined>(undefined)

type ToolbarProviderProps = PropsWithChildren<{}>

export function ToolbarProvider(props: ToolbarProviderProps) {
  const [config, setConfig] = useState<ToolbarConfig>({
    sorting: false,
    searchSorting: false,
    filter: false,
  })

  return (
    <TOOLBAR_CONTEXT.Provider value={{ config, setConfig }}>
      {props.children}
    </TOOLBAR_CONTEXT.Provider>
  )
}

export function useToolbarContext(): ToolbarContext {
  const context = useContext(TOOLBAR_CONTEXT)

  if (context) {
    return context
  }

  throw new Error('Toolbar context is undefined')
}

export function useToolbar(config: Optional<ToolbarConfig>): void {
  const { setConfig } = useToolbarContext()

  useEffect(() => {
    setConfig({
      sorting: false,
      searchSorting: false,
      filter: false,
      ...config,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function useNoToolbar(): void {
  useToolbar({ sorting: false, searchSorting: false, filter: false })
}

export function useToolbarQueryString(): string {
  const entrySorting = useEntrySortingQuery()
  const entrySearchSorting = useEntrySearchSortingQuery()
  const entryFilter = useEntryFilterQuery()

  const toolbarQuery = {
    [ENTRY_SORTING_SEARCH_QUERY_NAME]: entrySorting,
    [ENTRY_SEARCH_QUERY_NAME]: entrySearchSorting,
    ...entryFilter,
  }

  return queryString.stringify(toolbarQuery)
}

type ToolbarProps = {
  toggleFilter: () => void
}

export default function Toolbar(props: ToolbarProps) {
  const {
    config: { sorting, searchSorting, filter },
  } = useToolbarContext()

  if (!sorting && !searchSorting && !filter) {
    return null
  }

  return (
    <div className="flex shrink-0 grow gap-x-4 py-3 shadow-[inset_0_-1px_0] shadow-black/10">
      {sorting && (
        <div className="inline-block text-gray-drh">
          sort by: <EntrySortingControl />
        </div>
      )}

      {searchSorting && (
        <div className="inline-block text-gray-drh">
          sort by: <EntrySearchSortingControl />
        </div>
      )}

      {filter && (
        <div className="inline-block text-gray-drh">
          <div onClick={props.toggleFilter}>
            <DropdownTrigger>
              Filter <DropdownTriggerCaret />
            </DropdownTrigger>
          </div>
        </div>
      )}
    </div>
  )
}
