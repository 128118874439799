import { FragmentType, gql, useFragment } from 'graphql-generated'

import { EntryAlternativeNamesBox } from 'apps/browse/common'
import EntryNameLink from './entry-name-link'

const ENTRY_ALTERNATIVE_NAMES_FRAGMENT = gql(`
  fragment EntryAlternativeNames on polls_entity {
    id
    alt_names {
      name {
        id
        ...EntryNameLink
      }
    }
  }
`)

type EntryAlternativeNamesProps = {
  entryRef: FragmentType<typeof ENTRY_ALTERNATIVE_NAMES_FRAGMENT>
}

export default function EntryAlternativeNames(
  props: EntryAlternativeNamesProps
) {
  const data = useFragment(ENTRY_ALTERNATIVE_NAMES_FRAGMENT, props.entryRef)

  return (
    <EntryAlternativeNamesBox names={data.alt_names.map(({ name }) => name)}>
      {(name) => <EntryNameLink entryId={data.id} nameRef={name} />}
    </EntryAlternativeNamesBox>
  )
}
