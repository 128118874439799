import { useYearFormatter } from 'common'

type Props = {
  value: number | null | undefined
}

export default function EntryYear({ value }: Props) {
  const yearFormat = useYearFormatter()

  return typeof value === 'number' ? <>{yearFormat(value)}</> : null
}
