import queryString from 'query-string'
import { LoaderFunction, useLocation } from 'react-router-dom'
import * as yup from 'yup'

const QUERY_SCHEMA = yup
  .object({ q: yup.string().required().min(1) })
  .required()

export const validateSearchQuery: LoaderFunction = async ({ request }) => {
  const data = queryString.parseUrl(request.url).query

  try {
    QUERY_SCHEMA.validateSync(data)
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      throw new Response('', {
        status: 404,
      })
    }
  }

  return null
}

export function useSearchQuery() {
  const location = useLocation()
  const { q: query } = QUERY_SCHEMA.validateSync(
    queryString.parse(location.search)
  )

  return query
}
