import { gql, FragmentType, useFragment } from 'graphql-generated'
import { Highlight } from 'apps/browse/common'
import { NavLink } from 'react-router-dom'

const EXPERT_LINK_FRAGMENT = gql(`
  fragment EntrySearch_Entry_ExpertLink on User {
    username
    name
  }
`)

type ExpertLinkProps = {
  userRef: FragmentType<typeof EXPERT_LINK_FRAGMENT>
  query: string
}

export default function ExpertLink(props: ExpertLinkProps) {
  const { username, name } = useFragment(EXPERT_LINK_FRAGMENT, props.userRef)

  return (
    <NavLink to={`/accounts/${username}`} className="link">
      <Highlight this={props.query}>{name || username}</Highlight>
    </NavLink>
  )
}
