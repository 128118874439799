import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import { faRemove, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useGetText } from 'lib/gettext'

import Suggestions from './suggestions'
import { EntryPollSearchParams } from './params'

type EntryPollSearchProps = EntryPollSearchParams & {
  onQuestionPathLoad: (questionPath: any) => void
  onAnswerSetPathLoad: (answerSetPath: any) => void
}

export default function EntryPollSearch(props: EntryPollSearchProps) {
  const { gettext } = useGetText()

  const { onQuestionPathLoad, onAnswerSetPathLoad, ...params } = props

  const containerRef = useRef<HTMLDivElement>(null)

  const [query, setQuery] = useState<string | null>(null)
  const deferredQuery = useDeferredValue(query)

  const [suggestionsAreVisible, setSuggestionVisibility] =
    useState<boolean>(false)
  const showSuggestions = () => setSuggestionVisibility(true)
  const hideSuggestions = () => setSuggestionVisibility(false)

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value === '' ? null : event.target.value)
    },
    [setQuery]
  )

  const handleInputClick = () =>
    deferredQuery === null
      ? showSuggestions()
      : suggestionsAreVisible
      ? hideSuggestions()
      : showSuggestions()

  const handleClearButtonClick = () => {
    setQuery(null)
    hideSuggestions()
  }

  const handleQuestionPathLoad = (questionPath: any) => {
    hideSuggestions()
    onQuestionPathLoad(questionPath)
  }

  const handleAnswerSetPathLoad = (answerSetPath: any) => {
    hideSuggestions()
    onAnswerSetPathLoad(answerSetPath)
  }

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      )
        hideSuggestions()
    })

    return () => {
      document.removeEventListener('mousedown', () => {})
    }
  }, [])

  return (
    <div className="relative" ref={containerRef}>
      <input
        className="w-full rounded !border !border-transparent bg-[#134163] py-3 px-10 text-white transition-colors placeholder:text-white/75 focus:!border-primary focus:outline-none"
        type="text"
        placeholder={gettext('Search questions and answers…')}
        value={query || ''}
        onChange={handleInputChange}
        onClick={handleInputClick}
      />

      <FontAwesomeIcon
        className="absolute top-1/2 left-4 -translate-y-1/2 text-white"
        icon={faSearch}
        size="sm"
      />

      {deferredQuery && (
        <>
          <button
            className="absolute top-1/2 right-2 -translate-y-1/2 p-2 text-white"
            onClick={handleClearButtonClick}
            type="button"
          >
            <FontAwesomeIcon icon={faRemove} size="sm" />
          </button>

          <Suggestions
            query={deferredQuery}
            params={params}
            visible={suggestionsAreVisible}
            onQuestionPathLoad={handleQuestionPathLoad}
            onAnswerSetPathLoad={handleAnswerSetPathLoad}
          />
        </>
      )}
    </div>
  )
}
