import { gql, FragmentType, useFragment } from 'graphql-generated'

import { EntryPublicationDateBox } from 'apps/browse/common'

const ENTRY_PUBLICATION_DATE_FRAGMENT_SPEC = gql(`
  fragment EntryPublicationDate on polls_entity {
    date_modified
  }
`)

type EntryPublicationDateProps = {
  entryRef: FragmentType<typeof ENTRY_PUBLICATION_DATE_FRAGMENT_SPEC>
}

export default function EntryPublicationDate(props: EntryPublicationDateProps) {
  const { date_modified } = useFragment(
    ENTRY_PUBLICATION_DATE_FRAGMENT_SPEC,
    props.entryRef
  )

  return <EntryPublicationDateBox date={date_modified} />
}
