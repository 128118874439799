import * as yup from 'yup'

import { gqlRequest } from 'api'
import { gql } from 'graphql-generated'
import { EntrySearchQuery } from 'graphql-generated/graphql'
import { useCallback, useEffect, useState } from 'react'
import { LoaderFunction, useLoaderData, useLocation } from 'react-router-dom'
import { ValuesType } from 'utility-types'
import { getEntrySearchSorting } from '../common'

const QUERY_SCHEMA = yup.string().required().min(1)
const PAGE_SCHEMA = yup.number().notRequired().min(1)

const SEARCH_QUERY = gql(`
  query EntrySearch(
    $query: String!
    $size: Int!
    $after: String
    $sort: String
  ) {
    entry_search_connection(
      query: $query
      first: $size
      after: $after
      sort: $sort
    ) {
      count
      edges {
        node {
          id
          ...EntrySearch_Entry
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)

type Entry = NonNullable<
  NonNullable<
    ValuesType<
      NonNullable<
        NonNullable<EntrySearchQuery['entry_search_connection']>['edges']
      >
    >
  >['node']
>

type QueryResult = {
  entries: Entry[]
  count: number
  hasNextPage: boolean
  endCursor: string | null
}

function mapResultFromData(data: EntrySearchQuery | undefined): QueryResult {
  const entries =
    data?.entry_search_connection?.edges
      ?.map((edge) => edge?.node)
      .filter(
        (entry): entry is Entry =>
          entry !== null && typeof entry !== 'undefined'
      ) || []

  const count = data?.entry_search_connection?.count || 0
  const pageInfo = data?.entry_search_connection?.pageInfo
  const hasNextPage = pageInfo?.hasNextPage || false
  const endCursor = pageInfo?.endCursor ?? null

  return {
    entries,
    count,
    hasNextPage,
    endCursor,
  }
}

export const createEntrySearchLoader: (pageSize: number) => LoaderFunction =
  (pageSize) =>
  ({ request }) => {
    const url = new URL(request.url)
    const sort = getEntrySearchSorting(url)
    const page =
      PAGE_SCHEMA.validateSync(
        window.history.state['usr'] && window.history.state['usr']['page']
      ) || 1

    const size = pageSize * page

    try {
      const query = QUERY_SCHEMA.validateSync(url.searchParams.get('q'))

      return gqlRequest(SEARCH_QUERY, {
        query,
        size,
        sort,
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        throw new Response('', {
          status: 404,
        })
      }

      throw error
    }
  }

export function useEntrySearchLoaderData(): QueryResult {
  const data = useLoaderData() as EntrySearchQuery

  return mapResultFromData(data)
}

type EntrySearchProps = {
  query: string
  pageSize: number
  initialHasNextPage: boolean
  initialEntries: Entry[]
  initialEndCursor: string | null
  initialCount: number
  sort: 'name' | 'year_from' | 'year_to' | undefined
}

export function useEntrySearch(props: EntrySearchProps) {
  const {
    query,
    pageSize,
    sort,
    initialHasNextPage,
    initialEndCursor,
    initialEntries,
    initialCount,
  } = props

  const location = useLocation()
  const [currentQuery, setCurrentQuery] = useState<string>(query)
  const [entries, setEntries] = useState<Entry[]>(initialEntries)
  const [hasNextPage, setHasNextPage] = useState<boolean>(initialHasNextPage)
  const [endCursor, setEndCursor] = useState<string | null>(initialEndCursor)
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [count, setCount] = useState(initialCount)

  const loadNextPage = useCallback(async () => {
    if (!loadingNextPage && endCursor) {
      setLoadingNextPage(true)

      const data = await gqlRequest(SEARCH_QUERY, {
        query: currentQuery,
        size: pageSize,
        sort,
        after: endCursor,
      })

      const {
        endCursor: nextEndCursor,
        hasNextPage: nextHasNextPage,
        entries: nextEntries,
        count: nextCount,
      } = mapResultFromData(data)

      const page = Math.ceil(nextCount / pageSize)

      window.history.replaceState(
        {
          ...window.history.state,
          usr: { ...window.history.state!['usr'], page },
        },
        '',
        location.pathname + location.search
      )

      setEndCursor(nextEndCursor)
      setHasNextPage(nextHasNextPage)
      setEntries((entries) => [...entries, ...nextEntries])
      setCount(nextCount)
      setLoadingNextPage(false)
    }
  }, [
    location,
    currentQuery,
    pageSize,
    sort,
    endCursor,
    loadingNextPage,
    setLoadingNextPage,
    setEndCursor,
    setHasNextPage,
    setEntries,
  ])

  useEffect(() => {
    if (query !== currentQuery) {
      setCurrentQuery(query)
      setEntries(initialEntries)
      setEndCursor(initialEndCursor)
      setHasNextPage(initialHasNextPage)
      setCount(initialCount)
    }
  }, [
    query,
    currentQuery,
    initialEntries,
    initialEndCursor,
    initialHasNextPage,
    initialCount,
  ])

  return {
    entries,
    count,
    loadNextPage,
    hasNextPage,
    loadingNextPage,
  }
}
