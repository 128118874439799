import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { gql } from 'graphql-generated'
import { expertId, useAuthenticatedUser } from 'auth'

const ASSIGNED_EDITOR_QUERY = gql(`
  query AssignedEditor($expertId: Int!) {
    accounts_expert_by_pk(id: $expertId) {
      regionaleditor {
        editor_email
        user {
          first_name
          last_name
        }
      }
      managingeditor {
        editor_email
        user {
          first_name
          last_name
        }
      }
    }
  }
`)

export default function useAssignedEditor() {
  const user = useAuthenticatedUser()

  const [assignedEditor, setAssignedEditor] = useState<{
    name?: string
    email?: string
  }>()

  const { loading, data, error } = useQuery(ASSIGNED_EDITOR_QUERY, {
    variables: { expertId: expertId(user) },
    fetchPolicy: 'cache-and-network',
  })
  if (error) {
    console.error('Failed to fetch assigned editor:', error)
  }

  useEffect(() => {
    if (data?.accounts_expert_by_pk) {
      const { regionaleditor, managingeditor } = data.accounts_expert_by_pk

      if (!regionaleditor && !managingeditor) {
        setAssignedEditor(undefined)
      }

      const firstName =
        regionaleditor?.user.first_name || managingeditor?.user.first_name
      const lastName =
        regionaleditor?.user.last_name || managingeditor?.user.last_name
      const email =
        regionaleditor?.editor_email ||
        managingeditor?.editor_email ||
        undefined

      const name = `${firstName ?? ''} ${lastName ?? ''}`.trim() || undefined

      setAssignedEditor({
        name,
        email,
      })
    }
  }, [data])

  return {
    isLoading: loading,
    assignedEditor,
  }
}
