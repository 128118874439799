import { Entry } from './entry'

type EntryProgressProps = {
  entry: Entry
}

export default function EntryProgress(props: EntryProgressProps) {
  const { entry } = props

  return (
    <>
      {new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
        style: 'percent',
      }).format(entry.baseProgress.percentage)}
    </>
  )
}
