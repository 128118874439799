import { defer } from 'react-router-dom'
import axios from 'axios'

import { API_URL } from 'config'

type ManagingEditorsResponseData = {
  pk: number
  username: string
  first_name: string
  last_name: string
  thumbnail: string | null
  occupation: string | null
  department: string | null
  institution: string | null
  interests: ReadonlyArray<string>
}[]

type RegionalEditorCategoriesResponseData = {
  pk: number
  title: string
  editors: {
    pk: number
    username: string
    first_name: string
    last_name: string
    thumbnail: string | null
    title: string
    interests: ReadonlyArray<string>
    is_external: boolean
  }[]
}[]

type Editor = {
  id: number
  username: string
  firstName: string
  lastName: string
  title: string | undefined
  image: string | undefined
  interests: ReadonlyArray<string>
}

type ManagingEditor = Editor & {
  department: string | undefined
  institution: string | undefined
}

export type ManagingEditors = ReadonlyArray<ManagingEditor>

type RegionalEditor = Editor & {
  isExternal: boolean
}

type RegionalEditorCategory = {
  id: number
  title: string
  members: ReadonlyArray<RegionalEditor>
}

export type RegionalEditorCategories = ReadonlyArray<RegionalEditorCategory>

function makeManagingEditorsFromData(
  data: ManagingEditorsResponseData
): ManagingEditors {
  return data.map(
    ({
      pk,
      username,
      first_name,
      last_name,
      thumbnail,
      occupation,
      department,
      institution,
      interests,
    }) => ({
      id: pk,
      username,
      firstName: first_name,
      lastName: last_name,
      title: occupation || undefined,
      department: department || undefined,
      institution: institution || undefined,
      image: thumbnail || undefined,
      interests: interests || [],
    })
  )
}

function makeRegionalEditorCategoriesFromData(
  data: RegionalEditorCategoriesResponseData
): RegionalEditorCategories {
  return data.map(({ pk, title, editors }) => ({
    id: pk,
    title,
    members: editors.map(
      ({
        pk,
        username,
        first_name,
        last_name,
        thumbnail,
        title,
        interests,
        is_external,
      }) => ({
        id: pk,
        username,
        firstName: first_name,
        lastName: last_name,
        title,
        image: thumbnail || undefined,
        interests: interests || [],
        isExternal: is_external,
      })
    ),
  }))
}

async function getManagingEditors() {
  const { data } = await axios.get<ManagingEditorsResponseData>(
    API_URL + '/api/accounts/managing_editor/brief'
  )

  return makeManagingEditorsFromData(data)
}

async function getRegionalEditorCategories() {
  const { data } = await axios.get<RegionalEditorCategoriesResponseData>(
    API_URL + '/api/accounts/regional_editor_category'
  )

  return makeRegionalEditorCategoriesFromData(data)
}

export default function editorsLoader() {
  return defer({
    managingEditors: getManagingEditors(),
    regionalEditorCategories: getRegionalEditorCategories(),
  })
}
