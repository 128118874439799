import { HTMLAttributeAnchorTarget, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

import { UserName } from './user-name'
import UserDisplayName from './user-display-name'

type UserNavLinkProps = UserName & {
  className?: string
  target?: HTMLAttributeAnchorTarget | undefined
  rel?: string | undefined
} & (
    | { short?: true }
    | {
        children?: (name: UserName) => ReactNode
      }
  )

export default function UserNavLink(props: UserNavLinkProps) {
  const { username, className, ...rest } = props

  const name =
    'children' in props && props['children'] ? (
      props.children({
        firstName: props.firstName,
        lastName: props.lastName,
        username,
      })
    ) : (
      <UserDisplayName {...rest} username={username} />
    )

  return (
    <NavLink
      className={className}
      to={`/accounts/${username}`}
      target={props.target}
      rel={props.rel}
    >
      {name}
    </NavLink>
  )
}
