import { gql, FragmentType, useFragment } from 'graphql-generated'

import { EntryTagsBox } from 'apps/browse/common'

const ENTRY_TAGS_FRAGMENT = gql(`
  fragment EntryRegionTags on polls_entity {
    region {
      tags(
        where: { tag: { level: { _eq: 2 }, approved: { _eq: true } } }
        order_by: { tag: { name: asc } }
      ) {
        tag {
          id
          name
        }
      }
    }
  }
`)

type EntryRegionTagsProps = {
  entryRef: FragmentType<typeof ENTRY_TAGS_FRAGMENT>
}

export default function EntryRegionTags(props: EntryRegionTagsProps) {
  const { region } = useFragment(ENTRY_TAGS_FRAGMENT, props.entryRef)
  const tags = region.tags.map(({ tag }) => tag)

  return <EntryTagsBox icon="region-tags" tags={tags} />
}
