import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

type EntryNavLinkProps = {
  id: number
  name: string
  className?: string
}

export default function EntryNavLink(props: EntryNavLinkProps) {
  const { id, name, className } = props

  return (
    <NavLink to={`/browse/${id}`} className={classNames('link', className)}>
      {name}
    </NavLink>
  )
}
